import { Loader, Table } from '~/components';
import { CardContentFooter, LoaderOverlay, ShowListButton } from './styles';
import { useEffect, useState } from 'react';
import Pagination from 'rc-pagination';
import { NoAnalisysSelected } from '~/pages/General/styles';
import { EmptyDocumentIcon } from '~/icons/EmptyDocumentIcon';
import { t } from 'i18next';
import { useStateVar } from '~/helpers/useStateVar';
import { ColumnTable } from '~/metadata/ColumnTable.model';
import { apiCall } from '~/providers';
import { EnergyCardProps } from '..';
import { ColumnsTableConfig } from './TableConfig';
import { handleGetDatesParams } from '~/helpers/getRangeParamsChart';

interface EnergyCardFooterProps extends EnergyCardProps{
  cardYear: string;
  cardMonth: string;
  chartMode: string;
  isComparing: boolean;
}

export const EnergyCardFooter: React.FC<EnergyCardFooterProps> = ({
  cardYear, cardMonth, chartMode, energyCardFilters, isComparing,
}) => {
  const [showTable, setShowTable] = useState(false);
  const [tableData, setTableData] = useState<any[]>([]);

  const [state, render] = useStateVar({
    isLoading: false,
    filtersData: {} as any,
    selectedFilters: {
      clients: {
        label: t('cliente'),
        values: [],
      },
      states: {
        label: t('estado'),
        values: [],
      },
      cities: {
        label: t('cidade'),
        values: [],
      },
      units: {
        label: t('unidade'),
        values: [],
      },
    } as any,
    filterAll: {
      clients: false,
      states: false,
      cities: false,
      units: false,
    },
    columnSort: {
      clientName: {
        column: 'clientName',
        desc: false,
      },
      stateName: {
        column: 'stateName',
        desc: false,
      },
      cityName: {
        column: 'cityName',
        desc: false,
      },
      unitName: {
        column: 'unitName',
        desc: false,
      },
      consumption: {
        column: 'consumption',
        desc: false,
      },
      totalCharged: {
        column: 'totalCharged',
        desc: false,
      },
      refrigerationConsumption: {
        column: 'refrigerationConsumption',
        desc: false,
      },
      refrigerationConsumptionPercentage: {
        column: 'refrigerationConsumptionPercentage',
        desc: false,
      },
      consumptionByArea: {
        column: 'consumptionByArea',
        desc: false,
      },
      refCapacity: {
        column: 'refCapacity',
        desc: false,
      },
      refrigerationConsumptionByArea: {
        column: 'refrigerationConsumptionByArea',
        desc: false,
      },
    },
    currentSort: {
      field: '',
      type: '',
    },
    pagination: {
      itemsPerPage: 6,
      totalItems: 0,
      currentPage: 1,
    },
  });

  const handleSortData = (column) => {
    Object.keys(state.columnSort).forEach((columnKey) => {
      if (columnKey === column) {
        state.columnSort[columnKey] = {
          column: state.columnSort[columnKey].column,
          desc: !state.columnSort[columnKey].desc,
        };
      } else {
        state.columnSort[columnKey] = {
          column: state.columnSort[columnKey].column,
          desc: false,
        };
      }
    });

    state.currentSort = {
      field: state.columnSort[column].column,
      type: state.columnSort[column].desc ? 'Asc' : 'Desc',
    };

    handleGetTableData();
    render();
  };

  const [columnsData] = useState<(boolean | ColumnTable)[]>(ColumnsTableConfig(state, handleSortData));

  const handleGetTableData = async () => {
    try {
      state.isLoading = true;
      render();
      const sortParams = {} as {orderByField: string, orderByType: string };

      if (state.currentSort.field && state.currentSort.type) {
        sortParams.orderByField = state.currentSort.field;
        sortParams.orderByType = state.currentSort.type;
      }

      const datesParams = handleGetDatesParams(`${cardMonth} ${cardYear}`, 'MMMM YYYY', chartMode, isComparing);

      const items = await apiCall('/energy/get-analysis-list', {
        startDate: datesParams.startDate.toISOString().substring(0, 19),
        endDate: datesParams.endDate.toISOString().substring(0, 19),
        page: state.pagination.currentPage,
        pageSize: state.pagination.itemsPerPage,
        checkEnergyEfficiency: false,
        ...sortParams,
        ...energyCardFilters,
      });

      if (items.unitsList) {
        setTableData(items.unitsList);
      }

      state.isLoading = false;
      state.pagination.totalItems = items.totalItems;
      render();
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (energyCardFilters.startObject) return;

    handleGetTableData();
  }, [energyCardFilters, cardYear, cardMonth, chartMode]);

  return (
    <div style={{
      display: 'flex', flexDirection: 'column', gap: '12px', padding: '14px 24px 14px 24px',
    }}
    >
      <CardContentFooter>
        {/* <span>Considerado apenas unidades com medição de energia</span> */}
        <div />
        <ShowListButton onClick={() => setShowTable((showTablePrevState) => !showTablePrevState)}>{t('verLista')}</ShowListButton>
      </CardContentFooter>
      {showTable && (
        <div style={{
          position: 'relative', display: 'flex', flexDirection: 'column', alignItems: 'end', gap: '15px',
        }}
        >
          <div style={{ width: '100%' }}>
            <Table
              columns={columnsData.filter(Boolean)}
              data={tableData}
              noDataComponent={NoDataInTable}
              noBorderBottom
              filtersSelected={state.selectedFilters}
              style={{
                boxShadow: 'none',
                overflowX: tableData.length ? 'auto' : 'hidden',
                overflowY: tableData.length > 7 ? 'auto' : 'hidden',
                height: '300px',
              }}
            />
          </div>
          <Pagination
            className="ant-pagination"
            current={state.pagination.currentPage}
            total={state.pagination.totalItems}
            pageSize={state.pagination.itemsPerPage}
            onChange={(current) => {
              state.pagination.currentPage = current;
              handleGetTableData();
              render();
            }}
            disabled={state.isLoading}
          />
          {state.isLoading && (
            <LoaderOverlay>
              <Loader variant="primary" size="large" />
            </LoaderOverlay>
          )}
        </div>
      )}
    </div>
  );
};

const NoDataInTable = () => (
  <NoAnalisysSelected>
    <EmptyDocumentIcon />
    <span>{t('resultadoDaAnalise')}</span>
    <p>
      {t('tabelaSemDados')}
    </p>
  </NoAnalisysSelected>
);
