import { useEffect } from 'react';

import { Helmet } from 'react-helmet';
import { toast } from 'react-toastify';
import { Flex } from 'reflexbox';

import { Card, Loader } from '~/components';
import { useStateVar } from '~/helpers/useStateVar';
import { apiCall } from '~/providers';
import { t } from 'i18next';

import {
  CardContainer, VarContainer, VarName, VarsCardTitle, VarUnit, VarValue,
} from './styles';

export function OxynRealTime(props: {
  integrId: string
  varsList: {
    varId: string
    name: string
    currVal?: string|null
    valUnit?: string
    card?: string
    subcard?: string
    relevance?: number | string;
  }[],
}): JSX.Element {
  const { integrId } = props;
  const [state, render, setState] = useStateVar({
    loading: true,
    varsCards: [] as {
      title: string
      subCards: {
        title: string
        varsList: {
          name: string
          currVal?: string|null
          valUnit?: string
          relevance?: number | string
        }[],
      }[]
    }[],
  });

  useEffect(() => {
    (async () => {
      try {
        setState({ loading: true });
        const { list: varsList } = await apiCall('/oxyn-get-machine-last-values', {
          machine: integrId,
        });
        varsList.sort((a, b) => {
          if (a.name > b.name) return 1;
          if (a.name < b.name) return -1;
          return 0;
        });
        state.varsCards = [];
        for (const row of varsList) {
          const varInfo = props.varsList.find((x) => x.name === row.name);
          if (!varInfo) continue;
          row.timestamp = row.timestamp && row.timestamp.replace('T', ' ');
          varInfo.currVal = (row.value != null) ? row.value.toString() : null;
          varInfo.valUnit = row.valUnit;
          if (row.name.endsWith(' - Status')) varInfo.currVal = convertOxynStatus(row.value);

          const cardTitle = varInfo.card || 'Info';
          const subcardTitle = varInfo.subcard || 'Info';
          let card = state.varsCards.find((x) => x.title === cardTitle);
          if (!card) {
            card = { title: cardTitle, subCards: [] };
            state.varsCards.push(card);
          }
          let subcard = card.subCards.find((x) => x.title === subcardTitle);
          if (!subcard) {
            subcard = { title: subcardTitle, varsList: [] };
            card.subCards.push(subcard);
          }
          subcard.varsList.push(varInfo);
        }
      } catch (err) { toast.error(t('erro')); console.error(err); }
      setState({ loading: false });
    })();
  }, [props.varsList]);

  return (
    <>
      <Helmet>
        <title>{t('tituloPagDielEnergiaIntegracao')}</title>
      </Helmet>
      {(state.loading) && <Loader />}
      {(!state.loading) && (
        <div>
          {state.varsCards.map((card) => (
            <CardContainer key={card.title}>
              <Card>
                <>
                  <VarsCardTitle>{card.title}</VarsCardTitle>
                  <Flex flexWrap="wrap">
                    {card.subCards.map((subcard) => (
                      <CardContainer key={subcard.title}>
                        <Card>
                          <>
                            <VarsCardTitle>{subcard.title}</VarsCardTitle>
                            {subcard.varsList.map((varInfo) => (
                              <VarContainer key={varInfo.name}>
                                <VarName>
                                  {varInfo.name}
                                </VarName>
                                <div>
                                  <VarValue relevance={varInfo.relevance}>
                                    {varInfo.currVal}
                                  </VarValue>
                                  <VarUnit relevance={varInfo.relevance}>
                                    {varInfo.valUnit}
                                  </VarUnit>
                                </div>
                              </VarContainer>
                            ))}
                          </>
                        </Card>
                      </CardContainer>
                    ))}
                  </Flex>
                </>
              </Card>
            </CardContainer>
          ))}
        </div>
      )}
    </>
  );
}

export function convertOxynStatus(value: number|null): string|null {
  if (value == null) return null;
  if (value === 0) return t('desligadoMin');
  if (value === 2) return t('ligadoMin');
  return String(value);
}
