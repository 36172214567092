import { Seta } from '~/icons';
import { Wrapper } from '../Breadcrumb/styles';
import {
  StyledLink,
  StyledLinkLast,
  Separator,
} from './styles';

export const Breadcrumb2 = ({ items }): JSX.Element => {
  const parts: JSX.Element[] = [];
  for (let index = 0; index < items.length; index++) {
    const linkPath = items[index].link;
    const itemText = items[index].text;
    const isLast = (index >= (items.length - 1));
    const isDesktop = window.matchMedia('(min-width: 765px)');
    const isMobile = !isDesktop.matches;
    if (isLast) {
      parts.push(<StyledLinkLast key={linkPath} to={linkPath}>{itemText.length > 20 ? `${itemText.slice(0, 20)}...` : itemText}</StyledLinkLast>);
    } else if (linkPath) {
      parts.push(
        <StyledLink key={linkPath} to={linkPath}>
          {itemText.length > 20 && isMobile ? `${itemText.slice(0, 20)}...` : itemText}
          <Seta />
        </StyledLink>,
      );
    } else {
      parts.push(
        <Separator key={`lnk:${index}`}>
          {itemText.length > 20 && isMobile ? `${itemText.slice(0, 20)}...` : itemText}
          <Seta />
        </Separator>,
      );
    }
  }

  return (
    <Wrapper>
      {parts}
    </Wrapper>
  );
};
