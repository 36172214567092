import { useEffect, useRef, useState } from 'react';
import {
  BtnActionFilters, BtnFilters, BtnFiltersDropdown, BtnFiltersWrapper, Filters,
} from './styles';
import { CloseIcon, SearchInputIcon, TrashRoundedIcon } from '~/icons';
import { t } from 'i18next';

interface FiltersContainerProps {
  handleRemoveAllFilters?: () => void;
  handleSearchWithFilters?: () => void;
  handleRemoveFilter?: (key, index) => void;
  handleRemoveCategoryFilter?: (label) => void;
  filtersSelected?: {
    [key: string]: {
      label: string;
      values: {
        value: string|number;
        name: string
      }[]
    };
  }
}

export const FiltersContainer: React.FC<FiltersContainerProps> = ({
  handleRemoveAllFilters,
  handleSearchWithFilters,
  handleRemoveCategoryFilter,
  handleRemoveFilter,
  filtersSelected,
}) => {
  const [openDropdown, setOpenDropdown] = useState<string | null>(null);
  const wrapperRef = useRef<HTMLDivElement>(null);
  useEffect(() => {
    function handleClickOutside(event) {
      if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
        setOpenDropdown(null);
      }
    }

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [wrapperRef]);

  return (
    <>
      {filtersSelected && Object.values(filtersSelected).some((item) => item.values.length > 0) && (
      <Filters>
        { handleSearchWithFilters && (
        <BtnActionFilters style={{ height: '25px' }} onClick={handleSearchWithFilters}>
          <SearchInputIcon color="#363BC4" />
          {t('pesquisar')}
        </BtnActionFilters>
        )}
        <BtnActionFilters style={{ height: '25px' }} onClick={handleRemoveAllFilters}>
          <TrashRoundedIcon color="#ED193F" height="16px" />
          {t('limparFiltrosCap')}
        </BtnActionFilters>
        {Object.keys(filtersSelected).map((key) => filtersSelected[key].values.length > 0 && (
        <BtnFiltersWrapper ref={wrapperRef}>
          <BtnFilters
            key={`item-${filtersSelected[key].label}-${key}`}
            onClick={() => setOpenDropdown(openDropdown === key ? null : key)}
          >
            {`${filtersSelected[key].label} (${filtersSelected[key].values.length})`}
            <CloseIcon onClick={() => handleRemoveCategoryFilter && handleRemoveCategoryFilter(key)} cursor="pointer" color="#363BC4" />
          </BtnFilters>
          {openDropdown === key && filtersSelected[key].values.length > 0 && (
          <BtnFiltersDropdown>
            {filtersSelected[key].values.map((filter, index) => (
              <div key={`dropdown-option-${filter.value}-${filter.name}`}>
                <span>{filter.name}</span>
                <CloseIcon onClick={() => handleRemoveFilter && handleRemoveFilter(key, index)} cursor="pointer" color="#363BC4" />
              </div>
            ))}
          </BtnFiltersDropdown>
          )}
        </BtnFiltersWrapper>
        ))}
      </Filters>
      )}
    </>
  );
};
