import moment from 'moment';
import {
  Dropdown,
  DropdownContent,
  DropdownHeader,
  DropdownOption,
  DropdownYearButtons,
  SelectOptionDropdownWrapper,
} from './styles';
import { ClickAwayListener } from '@material-ui/core';
import { ArrowLeftIcon, ArrowRightIcon } from '~/icons';
import { capitalizeFirstLetter } from '~/helpers/capitalizeFirstLetter';
import { getArrayMonthsOfYear } from '~/helpers/getArrayMonthsOfYear';

const YEAR_MODE = 'yearMode';
const MONTH_MODE = 'monthMode';

interface SelectOptionDropdownProps {
  open: boolean;
  handleClickOutside: () => void;
  yearSelected: string;
  monthSelected: string;
  yearOptions: {
    label: string;
    value: string | number;
  }[];
  monthOptions: {
    label: string;
    value: string | number;
  }[];
  mode: string;
  isLoading: boolean;
  handleSelectYear: (value: string) => void;
  handleSelectMonth: (value: string) => void;
  handleChangeDate: (value: string) => void;
  children: React.ReactNode;
}

export const SelectOptionDropdown: React.FC<SelectOptionDropdownProps> = ({
  open,
  yearSelected,
  monthSelected,
  yearOptions,
  monthOptions,
  mode,
  handleSelectYear,
  handleSelectMonth,
  handleClickOutside,
  handleChangeDate,
  isLoading,
  children,
}) => {
  const monthsOfYear = getArrayMonthsOfYear();
  const years = ['2021', '2022', '2023', '2024'];

  const handleGetCurrentYearIndex = () => yearOptions.findIndex(
    (year) => moment.utc(year.value).format('YYYY') === yearSelected,
  );

  const handleGetCurrentMonthIndex = () => monthsOfYear.findIndex(
    (month) => capitalizeFirstLetter(moment(month, 'MMM').format('MMMM')) === capitalizeFirstLetter(monthSelected),
  );

  const handleGetMonthIndex = (monthLabel, yearLabel, format = 'MMM YYYY') => monthOptions.findIndex((month) => {
    const checkedMonth = moment.utc(month.value).format(format);
    return (
      capitalizeFirstLetter(`${monthLabel} ${yearLabel}`) === capitalizeFirstLetter(checkedMonth)
    );
  });

  const handleGetYearIndex = (yearLabel) => yearOptions.findIndex((year) => {
    const checkedYear = moment.utc(year.value).format('YYYY');

    return yearLabel === checkedYear;
  });

  const handleSelect = (direction) => {
    let currentYearIndex = handleGetCurrentYearIndex();
    let currentMonthIndex = handleGetCurrentMonthIndex();

    if (
      currentYearIndex + direction < 0
      || currentYearIndex + direction >= yearOptions.length
    ) return;

    let newMonthIndex;

    for (let monthLoopIndex = 0; monthLoopIndex <= monthsOfYear.length; monthLoopIndex++) {
      if (monthLoopIndex !== currentMonthIndex) continue;

      const month = monthsOfYear[monthLoopIndex];
      const newYear = yearOptions[currentYearIndex + direction];
      const newYearLabel = moment(newYear.value).format('YYYY');
      const newDateIndex = handleGetMonthIndex(month, newYearLabel, 'MMM YYYY');

      if (newDateIndex >= 0) {
        newMonthIndex = newDateIndex;
        break;
      }

      if (currentMonthIndex === 0 || currentMonthIndex === 11) {
        currentMonthIndex = direction > 0 ? 11 : 0;
        currentYearIndex += direction;

        if (currentYearIndex <= 0 || currentYearIndex >= yearOptions.length - 1) {
          newMonthIndex = currentYearIndex <= 0 ? 0 : monthOptions.length - 1;
          break;
        }

        monthLoopIndex = -1;
      } else {
        currentMonthIndex += direction;
        monthLoopIndex = -1;
      }
    }

    handleChangeDate(moment.utc(monthOptions[newMonthIndex].value).format('MMMM YYYY'));
  };

  return (
    <SelectOptionDropdownWrapper>
      {children}
      {open && (
        <ClickAwayListener onClickAway={handleClickOutside}>
          <Dropdown>
            {mode === MONTH_MODE && (
              <DropdownHeader>
                <DropdownYearButtons
                  disabled={handleGetCurrentYearIndex() - 1 < 0}
                  onClick={() => !isLoading && handleSelect(-1)}
                >
                  <ArrowLeftIcon />
                </DropdownYearButtons>
                <span
                  style={{
                    fontWeight: '700',
                    fontSize: '18px',
                    color: '#181842',
                    fontFamily: 'Inter',
                  }}
                >
                  {moment(yearSelected).format('YYYY')}
                </span>
                <DropdownYearButtons
                  disabled={handleGetCurrentYearIndex() + 1 >= yearOptions.length}
                  onClick={() => !isLoading && handleSelect(1)}
                >
                  <ArrowRightIcon />
                </DropdownYearButtons>
              </DropdownHeader>
            )}
            <DropdownContent>
              {mode === YEAR_MODE
                && years.map((year) => (
                  <DropdownOption
                    key={`year-dropdown-${year}-${year}`}
                    selected={yearSelected === moment(year).format('YYYY')}
                    disabled={handleGetYearIndex(year) < 0}
                    onClick={() => !isLoading && handleGetYearIndex(year) >= 0
                      && handleSelectYear(moment(year).format('YYYY'))}
                  >
                    {year}
                  </DropdownOption>
                ))}
              {mode === MONTH_MODE
                && monthsOfYear.map((month) => (
                  <DropdownOption
                    key={`month-dropdown-${month}`}
                    selected={
                      monthSelected === moment(month, 'MMM').format('MMMM')
                    }
                    disabled={handleGetMonthIndex(month, yearSelected) < 0}
                    onClick={() => !isLoading && handleGetMonthIndex(month, yearSelected) >= 0
                      && handleSelectMonth(moment(month, 'MMM').format('MMMM'))}
                  >
                    {capitalizeFirstLetter(month)}
                  </DropdownOption>
                ))}
            </DropdownContent>
          </Dropdown>
        </ClickAwayListener>
      )}
    </SelectOptionDropdownWrapper>
  );
};
