import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { useEffect } from 'react';

import * as echarts from 'echarts/core';
import {
  TooltipComponent,
  GridComponent,
  VisualMapComponent,
} from 'echarts/components';
import { HeatmapChart } from 'echarts/charts';
import { CanvasRenderer } from 'echarts/renderers';

import i18n from '~/i18n';
import {
  HeatmapWrapper,
} from './styles';

import { ApiResps } from '~/providers';
import { convertEnergy } from '~/helpers';
import { TelemetryChartsLegend } from '~/components';

import {
  getDay, getDate, format, getHours, addHours,
// eslint-disable-next-line import/no-duplicates
} from 'date-fns';
// eslint-disable-next-line import/no-duplicates
import { ptBR, enUS } from 'date-fns/locale';

echarts.use([
  TooltipComponent,
  GridComponent,
  VisualMapComponent,
  HeatmapChart,
  CanvasRenderer,
]);

interface HeatmapProps {
  telemetryData: ApiResps['/energy/get-hist-infograph'],
  handleClick: (name: echarts.ECElementEvent) => void
}

export const Heatmap: React.FC<HeatmapProps> = ({ telemetryData, handleClick }) => {
  moment.locale(i18n.language === 'pt' ? 'pt-BR' : 'en');
  const { t } = useTranslation();

  const daysOfWeek = {
    0: t('diasDaSemana.domingo'),
    1: t('diasDaSemana.segunda'),
    2: t('diasDaSemana.terca'),
    3: t('diasDaSemana.quarta'),
    4: t('diasDaSemana.quinta'),
    5: t('diasDaSemana.sexta'),
    6: t('diasDaSemana.sabado'),
  };

  useEffect(() => {
    if (telemetryData.demands && telemetryData.demands.length > 0) {
      const xAxis = handlePrepareXAxis(telemetryData.demands[0].timestamp);
      const yAxis = handlePrepareYAxis();
      const heatmapData = handlePrepareData(telemetryData.demands);

      handleRenderHeatmap(xAxis, yAxis, heatmapData);
    }
  }, [telemetryData]);

  // prepares
  const handlePrepareXAxis = (firstDateTimestamp) => {
    const firstDate = addHours(new Date(firstDateTimestamp), 3);

    const startDate = new Date(
      firstDate.getFullYear(),
      firstDate.getMonth(),
      1,
      3,
      0,
      0,
      0,
    );
    const finishDate = new Date(
      firstDate.getFullYear(),
      firstDate.getMonth() + 1,
      1,
      3,
      0,
      0,
      0,
    );
    finishDate.setDate(finishDate.getDate() - 1);

    const arrOfDates: Date[] = [];
    const controlDate = startDate;

    while (controlDate.getTime() !== finishDate.getTime()) {
      arrOfDates.push(new Date(controlDate));

      controlDate.setDate(controlDate.getDate() + 1);
    }

    arrOfDates.push(finishDate);

    return arrOfDates;
  };

  const handlePrepareYAxis = () => Array.from({ length: 24 }, (_, index) => index);

  const handlePrepareData = (data) => {
    const formattedData: { timestamp: number; value: number }[] = [];

    data.forEach((item) => {
      const index = formattedData.findIndex(
        (prData: { timestamp: number; value: number }) => {
          const isSameHour = getHours(new Date(prData.timestamp)) === getHours(new Date(item.timestamp));
          const isSameDay = getDate(new Date(prData.timestamp)) === getDate(new Date(item.timestamp));

          return isSameHour && isSameDay;
        },
      );

      if (index < 0) {
        formattedData.push({
          timestamp: item.timestamp,
          value: item.value,
        });

        return;
      }

      formattedData[index] = {
        timestamp: formattedData[index].timestamp,
        value: formattedData[index].value + item.value,
      };
    });

    return formattedData.map((item) => {
      const time = addHours(new Date(item.timestamp), 3);
      const value = item.value;

      return [getDate(new Date(time)) - 1, getHours(new Date(time)), value || '-'];
    });
  };

  // formatters
  const xAxisFormatter = (value) => {
    const date = new Date(value);
    let monthDay: string | number = getDate(date);
    const weekDay = getDay(date);

    if (monthDay < 10) monthDay = `0${monthDay}`;

    if (weekDay === 0) return `{bold|${monthDay}\n${daysOfWeek[weekDay][0]}}`;

    return `${monthDay}\n${daysOfWeek[weekDay][0]}`;
  };

  const yAxisFormatter = (value) => {
    if (value === '23') return '24:00h';

    return `${value}:00h`;
  };

  const visualMapFormatter = (value) => {
    const [valueDemandConverted, valueDemandUnitMeasurement] = convertEnergy(value);

    return `${valueDemandConverted.toFixed(1).replace('.', ',')}${valueDemandUnitMeasurement}`;
  };

  const tooltipFormatter = (value) => {
    if (!telemetryData.demands) return '';

    const date = new Date(value.name);

    const valueDemand = value.data[2];
    const valueHour = value.data[1];
    const valueDay = value.data[0];

    const devices = telemetryData.demands.find((item) => {
      const demandDate = addHours(new Date(item.timestamp), 3);

      const isSameHour = getHours(demandDate) === valueHour;
      const isSameDay = getDate(demandDate) - 1 === valueDay;

      return isSameHour && isSameDay;
    });

    if (!devices) return '';

    const devicesValues = devices.telemetries.length > 1 ? devices.telemetries.sort((a, b) => b.value - a.value) : devices.telemetries;

    const stringDate = `${format(date, 'EEE - dd/LLL', { locale: i18n.language === 'pt' ? ptBR : enUS }).replace(/\b[a-z]/, (match) => match.toUpperCase())}`;
    const stringHour = `${valueHour}:00 - ${valueHour !== '24' ? Number(valueHour) + 1 : '00'}:00h`;
    let stringMax = `<strong>${t('max')}:</strong> `;
    let stringAvg = `<strong>${t('media')}:</strong> `;
    let stringMin = `<strong>${t('min')}:</strong> `;

    if (devicesValues.length >= 1) {
      const [maxValueDemandConverted, maxValueDemandUnitMeasurement] = convertEnergy(devicesValues[0].value);
      const [medValueDemandConverted, medValueDemandUnitMeasurement] = convertEnergy(valueDemand);
      const [minValueDemandConverted, minValueDemandUnitMeasurement] = convertEnergy(devicesValues[devicesValues.length - 1].value);

      stringMax += `${maxValueDemandConverted === 0 ? maxValueDemandConverted.toFixed().replace('.', ',') : maxValueDemandConverted.toFixed(1).replace('.', ',')}${maxValueDemandUnitMeasurement}`;
      stringAvg += `${medValueDemandConverted === 0 ? medValueDemandConverted.toFixed().replace('.', ',') : medValueDemandConverted.toFixed(1).replace('.', ',')}${medValueDemandUnitMeasurement}`;
      stringMin += `${minValueDemandConverted === 0 ? minValueDemandConverted.toFixed().replace('.', ',') : minValueDemandConverted.toFixed(1).replace('.', ',')}${minValueDemandUnitMeasurement}`;
    }

    const tooltipContentFactory = [stringDate, stringHour, stringMax, stringAvg, stringMin];

    return tooltipContentFactory.join('<br />');
  };

  // plot heatmap
  const handleRenderHeatmap = (xAxis, yAxis, heatmapData) => {
    const heatmapDom = document.getElementById('heatmap-demanda');

    const heatmapChart = echarts.init(heatmapDom);

    window.addEventListener('resize', () => {
      heatmapChart.resize();
    });

    heatmapChart.on('click', 'series.heatmap', handleClick);

    const heatmapConfig = {
      grid: {
        height: '70%',
        width: '95%',
        top: '15%',
        left: '5%',
        show: true,
        backgroundColor: 'rgba(196, 196, 196, 0.10)',
        borderColor: 'rgba(196, 196, 196, 0.10)',
      },
      yAxis: {
        type: 'category',
        data: yAxis,
        splitArea: { show: false },
        axisLine: { show: false },
        axisTick: { show: false },
        axisLabel: {
          margin: 10,
          interval: 5,
          showMaxLabel: true,
          color: '#000',
          fontFamily: 'Inter',
          fontSize: '10.5',
          formatter: yAxisFormatter,
        },
      },
      xAxis: {
        type: 'category',
        data: xAxis,
        splitArea: { show: false },
        axisLine: { show: false },
        axisTick: { show: false },
        axisLabel: {
          margin: 15,
          interval: 0,
          showMaxLabel: true,
          showMinLabel: true,
          color: '#000',
          fontFamily: 'Inter',
          fontSize: '10.5',
          rich: {
            bold: {
              fontWeight: 600,
              color: '#000',
              fontFamily: 'Inter',
              fontSize: '10.5',
            },
          },
          formatter: xAxisFormatter,
        },
      },
      series: [
        {
          name: 'Punch Card',
          type: 'heatmap',
          data: heatmapData,
          label: { show: false },
          emphasis: { show: false },
          itemStyle: {
            borderColor: '#fff',
            borderWidth: 0.5,
          },
          tooltip: {
            borderColor: 'rgba(0, 0, 0, 0.19)',
            textStyle: {
              color: '#000',
              fontFamily: 'Inter',
              fontSize: '11',
            },
            formatter: tooltipFormatter,
          },
        },
      ],
      visualMap: {
        min: 0,
        max: telemetryData.max_demand?.value,
        calculable: true,
        orient: 'horizontal',
        align: 'right',
        left: 'right',
        top: 'top',
        itemWidth: 10,
        textStyle: {
          color: '#000',
          fontFamily: 'Inter',
          fontSize: 9,
          fontStyle: 'normal',
          fontWeight: 400,
        },
        formatter: visualMapFormatter,
      },
      tooltip: {
        position: 'top',
      },
      gradientColor: ['#B2E1B9', '#5AB365'],
    };

    heatmapConfig && heatmapChart.setOption(heatmapConfig);

    window.removeEventListener('resize', () => {
      heatmapChart.resize();
    });
  };

  return (
    <div>
      <HeatmapWrapper>
        <div id="heatmap-demanda" style={{ width: '100%', height: '100%' }} />
      </HeatmapWrapper>

      <TelemetryChartsLegend telemetryData={telemetryData} />
    </div>
  );
};
