import { useEffect, useRef } from 'react';
import { t } from 'i18next';
import queryString from 'query-string';
import { Helmet } from 'react-helmet';
import { useHistory, useParams } from 'react-router';
import { toast } from 'react-toastify';

import {
  Loader,
  Select,
} from '~/components';
import { useStateVar } from '~/helpers/useStateVar';
import { OxynHistory } from '~/pages/Analysis/Integrations/IntegrHistory/OxynHistory';
import { buildTabLink, IntegrInlineTabs } from '~/pages/Analysis/Integrations/IntegrLayout';
import { IntegrPerfilContents } from '~/pages/Analysis/Integrations/IntegrPerfil/index';
import { IntegrRealTimeContents } from '~/pages/Analysis/Integrations/IntegrRealTime/index';
import { TUnitInfo, UnitLayout } from '~/pages/Analysis/Units/UnitLayout';
import { apiCall, ApiResps } from '~/providers';
import { withTransaction } from '@elastic/apm-rum-react';

export const UnitOxyn = (): JSX.Element => {
  const history = useHistory();
  const routeParams = useParams<{ unitId: string }>();
  const [state, render, setState] = useStateVar({
    unitId: Number(routeParams.unitId),
    isLoading: true,
    unitInfo: null as null| TUnitInfo,
    machinesList: [] as { dataSource: string }[],
    selectedMachine: null as null|{ dataSource: string },
    isLoadingMachine: false,
    devInfoResp: null as (null|ApiResps['/get-integration-info']),
  });

  async function handleGetUnitInfo() {
    try {
      setState({ isLoading: true });
      const [
        unitInfo,
        { list: machinesList },
      ] = await Promise.all([
        apiCall('/clients/get-unit-info', { unitId: state.unitId }),
        apiCall('/get-integrations-list', { supplier: 'oxyn' }),
      ]);
      state.unitInfo = unitInfo;
      state.machinesList = machinesList;
      if (state.machinesList.length === 1) {
        state.selectedMachine = state.machinesList[0];
      }
    } catch (err) {
      console.log(err);
      toast.error(t('houveErro'));
    }
    setState({ isLoading: false });
  }

  useEffect(() => {
    handleGetUnitInfo();
  }, []);

  useEffect(() => {
    (async function () {
      try {
        if (!state.selectedMachine) return;
        setState({ isLoadingMachine: true });
        const response = await apiCall('/get-integration-info', { supplier: 'oxyn', integrId: state.selectedMachine.dataSource });
        state.devInfoResp = response;
      } catch (err) {
        toast.error(t('houveErro'));
        console.log(err);
      }
      setState({ isLoadingMachine: false });
    }());
  }, [state.selectedMachine]);

  const queryPars = queryString.parse(history.location.search);

  return (
    <>
      <Helmet>
        <title>{t('dielEnergiaDetalhesUnidade')}</title>
      </Helmet>
      <UnitLayout unitInfo={state.unitInfo} />
      <br />
      {(state.isLoading) && <Loader />}
      {(!state.isLoading) && (
        <>
          <div style={{ maxWidth: '400px' }}>
            <Select
              options={state.machinesList}
              value={state.selectedMachine}
              placeholder={t('maquina')}
              propLabel="dataSource"
              onSelect={(opt) => setState({ selectedMachine: opt })}
              // @ts-ignore
              notNull
            />
          </div>
          <div style={{ paddingTop: '30px' }}>
            {(state.isLoadingMachine) && <Loader />}
            {(!state.isLoadingMachine) && state.selectedMachine && state.devInfoResp && (
              <div>
                <div style={{ paddingTop: '20px', paddingBottom: '20px' }}>
                  <IntegrInlineTabs integrType="oxyn" />
                </div>
                <div style={{ paddingBottom: '30px' }}>
                  {
                    (queryPars.aba === 'perfil') || (!queryPars.aba)
                      ? (
                        <IntegrPerfilContents
                          integrType="oxyn"
                          integrId={state.selectedMachine.dataSource}
                          devInfoResp={state.devInfoResp}
                          editLink={buildTabLink('editar', history)}
                        />
                      )
                      : (queryPars.aba === 'tempo-real')
                        ? (
                          <IntegrRealTimeContents
                            integrType="oxyn"
                            integrId={state.selectedMachine.dataSource}
                            devInfoResp={state.devInfoResp}
                          />
                        )
                        : (queryPars.aba === 'historico')
                          ? (<OxynHistory integrId={state.selectedMachine.dataSource} />)
                          : '(vazio)'
                  }
                </div>
              </div>
            )}
          </div>
        </>
      )}
    </>
  );
};

export default withTransaction('UnitOxyn', 'component')(UnitOxyn);
