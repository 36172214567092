import styled from 'styled-components';

import { ExpandIcon } from '../../icons';
import { colors } from 'styles/colors';

export const Container = styled.header`
  display: flex;
  font-size: 1em;
  align-items: center;
  justify-content: flex-end;
  background-color: ${colors.White};
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.2);
  top: 0;
  left: 0;
  height: 40px;
  padding: 0 40px;
  position: fixed;
  width: 100vw;
  z-index: 2;
`;

export const UserOptions = styled.div`
  cursor: pointer;
  display: flex;
  padding: 20px 0;
  position: relative;
`;

export const Welcome = styled.span`
  color: ${colors.Grey400};
`;

export const Expand = styled(ExpandIcon)<{ expanded }>`
  align-self: center;
  margin-left: 10px;
  transition: transform 0.2s ease-in-out;
  transform: rotate(${({ expanded }) => (expanded ? 180 : 0)}deg);
`;

export const List = styled.div`
  box-shadow: 0px 2px 8px ${colors.Grey300};
  border-radius: 8px;
  position: absolute;
  top: 57px;
  width: 160px;
  height: 72px;
  padding: 5px 16px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  background-color: ${colors.White};
`;

export const Item = styled.a`
  color: ${colors.Grey300};
  cursor: pointer;
  color: #77818b;
  width: 100%;
  text-decoration: none;
  &:hover {
    background-color: ${colors.Grey050};
  }
`;

export const ItemTitle = styled.span`
  margin-left: 10px;
`;
