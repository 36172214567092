import styled from 'styled-components';

export const ContainerTimezoneWarn = styled.div`
    background-color: rgba(241, 241, 241, 1);
    color: rgba(119, 129, 139, 1);
    font-size: 12px;
    font-weight: 500;
    border-radius: 9px;
    width: 70%;
    max-width: 500px;
    padding: 2px;
    margin-bottom: 10px;
    text-align: center;
`;
export const ContainerTimezoneDST = styled.div`
    background-color: rgba(241, 241, 241, 1);
    color: rgba(119, 129, 139, 1);
    font-size: 12px;
    font-weight: 500;
    border-radius: 9px;
    width: 120px;
    padding: 2px 5px;
    margin-bottom: 10px;
    text-align: center;
`;
