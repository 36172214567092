import { SelectOptionDropdown } from '~/components/SelectOptionDropdown';
import { BarchartWrapper, CheckboxLine, SelectedDate } from './styles';
import { Checkbox } from '~/components';
import { ArrowDownIconV2 } from '~/icons';
import { useContext, useEffect, useState } from 'react';
import { getArrayMonthsOfYear } from '~/helpers/getArrayMonthsOfYear';
import { getArrayDaysOfMonth } from '~/helpers/getArrayDaysOfMonth';
import { t } from 'i18next';

import * as echarts from 'echarts/core';
import { TooltipComponent, GridComponent } from 'echarts/components';
import { BarChart } from 'echarts/charts';
import { CanvasRenderer } from 'echarts/renderers';
import { useCard } from '~/contexts/CardContext';
import { ApiResps } from '~/providers';
import moment from 'moment';
import { formatNumber } from '~/helpers/formatNumber';
import { capitalizeFirstLetter } from '~/helpers/capitalizeFirstLetter';
import { convertEnergy } from '~/helpers';
import { getUserProfile } from '~/helpers/userProfile';
import MenuContext from '~/contexts/menuContext';
import { useStateVar } from '~/helpers/useStateVar';

echarts.use([TooltipComponent, GridComponent, BarChart, CanvasRenderer]);

const daysOfWeek = {
  0: t('diasDaSemana.domingo'),
  1: t('diasDaSemana.segunda'),
  2: t('diasDaSemana.terca'),
  3: t('diasDaSemana.quarta'),
  4: t('diasDaSemana.quinta'),
  5: t('diasDaSemana.sexta'),
  6: t('diasDaSemana.sabado'),
};

export const INCOERENT_ICON = "data:image/svg+xml;charset=utf8,%3Csvg%20width%3D'13'%20height%3D'13'%20viewBox%3D'0%200%2013%2013'%20fill%3D'none'%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%3E%3Cpath%20d%3D'M6.585%208.819V6.585M6.585%204.351H6.59059M12.17%206.585C12.17%209.66951%209.66951%2012.17%206.585%2012.17C3.50049%2012.17%201%209.66951%201%206.585C1%203.50049%203.50049%201%206.585%201C9.66951%201%2012.17%203.50049%2012.17%206.585Z'%20stroke%3D'%23A9A9A9'%20stroke-width%3D'1.5'%20stroke-linecap%3D'round'%20stroke-linejoin%3D'round'%2F%3E%3C%2Fsvg%3E";
export const WARNING_ICON = "data:image/svg+xml;charset=utf8,%3Csvg%20width%3D'15'%20height%3D'13'%20viewBox%3D'0%200%2015%2013'%20fill%3D'none'%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%3E%3Cpath%20d%3D'M7.265%204.97025V7.41193M7.265%209.85361H7.2711M6.41976%201.85206L1.39913%2010.5241C1.12065%2011.0051%200.981414%2011.2456%201.00199%2011.443C1.01994%2011.6151%201.11014%2011.7716%201.25015%2011.8734C1.41066%2011.9901%201.68856%2011.9901%202.24437%2011.9901H12.2856C12.8414%2011.9901%2013.1193%2011.9901%2013.2798%2011.8734C13.4199%2011.7716%2013.5101%2011.6151%2013.528%2011.443C13.5486%2011.2456%2013.4093%2011.0051%2013.1309%2010.5241L8.11024%201.85206C7.83276%201.37278%207.69402%201.13314%207.51301%201.05265C7.35512%200.982448%207.17488%200.982448%207.01699%201.05265C6.83598%201.13314%206.69724%201.37278%206.41976%201.85206Z'%20stroke%3D'%23F3B107'%20stroke-width%3D'1.3'%20stroke-linecap%3D'round'%20stroke-linejoin%3D'round'%2F%3E%3C%2Fsvg%3E";

interface EnergyHistoryProps {
  chartData: ApiResps['/energy/get-energy-analysis-hist'];
  cardYear: string;
  cardMonth: string;
  chartMode: string;
  filterDatesChart: FilterDatesChart;
  isLoading: boolean;
  handleClickBarChart: (name: any) => void;
  handleChangeComparingChart: (
    isComparing: boolean,
    yearSelected: string,
    monthSelected: string
  ) => void;
}

interface FilterDatesChart {
  yearOptions: {
    label: string;
    value: string | number;
  }[];
  monthOptions: {
    label: string;
    value: string | number;
  }[];
}

export const EnergyHistory: React.FC<EnergyHistoryProps> = ({
  chartData,
  cardYear,
  cardMonth,
  chartMode,
  isLoading,
  filterDatesChart,
  handleClickBarChart,
  handleChangeComparingChart,
}) => {
  const isDesktop = window.matchMedia('(min-width: 426px)');
  const isMobile = !isDesktop.matches;
  const profile = getUserProfile();
  const isAdmin = profile.permissions?.isAdminSistema;

  const { menuToogle } = useContext(MenuContext);
  const { cards } = useCard();
  const energyCard = cards.find((card) => card.title === 'Energia');

  const [state, render] = useStateVar({
    isComparingChart: false,
    isOpenComparingDropdown: false,
    monthSelected: cardMonth,
    yearSelected: moment().format('YYYY'),
  });

  const handleGetYearIndex = (yearLabel) => filterDatesChart.yearOptions.findIndex((year) => yearLabel === moment.utc(year.value).format('YYYY'));

  const calculateDelta = (firstNumber, secondNumber) => {
    if (secondNumber === 0) {
      return firstNumber;
    }
    const difference = firstNumber - secondNumber;
    return (difference / secondNumber) * 100;
  };

  const handleGetDataChart = (data, date, format) => data.find((item) => moment.utc(item.time).format(format) === moment(date, format).format(format));

  const checkProblemData = (chartData, date, format) => {
    const chartValue = handleGetDataChart(chartData, date, format);
    return chartValue && Number(chartValue.consumption) < 0;
  };

  const createTooltipElement = (
    tooltipTitle,
    consumptionValue,
    consumptionMeasurement,
    totalChargedData,
    color,
    deltaValue?,
  ) => {
    const createDeltaElement = (signal, color) => `
      <div style="display: flex; gap: 4px; align-items: center;">
        <svg style="transform: rotate(${
  signal === '+' ? '180deg' : '0'
});" width="9" height="8" viewBox="0 0 9 8" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M5.36603 7.5C4.98113 8.16667 4.01887 8.16667 3.63397 7.5L0.602887 2.25C0.217987 1.58333 0.699112 0.749999 1.46891 0.749999L7.53109 0.75C8.30089 0.75 8.78202 1.58333 8.39711 2.25L5.36603 7.5Z" fill="${color}"/>
        </svg>
        <span style="font-weight: 600; font-size: 9px; font-family: Inter;">${signal}${formatNumber(Math.abs(
  deltaValue,
))}%</span>
      </div>
    `;

    let deltaElement = '';
    if (deltaValue && deltaValue < 0) {
      deltaElement = createDeltaElement('-', '#5AB365');
    } else if (deltaValue && deltaValue > 0) {
      deltaElement = createDeltaElement('+', '#E00030');
    }

    return `
      <div style="display: flex; flex-direction: column; gap: 8px; color: #000000;">
        <span style="font-weight: 700; font-size: 12px; font-family: Inter;"><strong>${tooltipTitle}</strong></span>
        <div style="display: flex; gap: 8px;">
          <div style="width: 15px; height: 15px; border-radius: 5px; background-color: ${color}; margin-top: 3px;"></div>
          <div style="display: flex; flex-direction: column;">
            <span style="font-weight: 700; font-size: 10px; font-family: Inter;">Consumo:</span>
            <div style="display: flex; gap: 4px; align-items: center;">
              <span style="font-weight: 400; font-size: 11px; font-family: Inter;"><strong>${consumptionValue}</strong> ${consumptionMeasurement} | R$<strong>${totalChargedData}</strong></span>
              ${deltaElement}
            </div>
          </div>
        </div>
      </div>
    `;
  };

  const createIsProblemTooltipElement = (tooltipTitle) => `
      <div style="display: flex; flex-direction: column; gap: 8px; color: #000000;">
        <span style="font-weight: 700; font-size: 12px; font-family: Inter;"><strong>${tooltipTitle}</strong></span>
        <div style="display: flex; align-items: center; gap: 8px;">
          <svg width="13" height="13" viewBox="0 0 13 13" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M6.585 8.819V6.585M6.585 4.351H6.59059M12.17 6.585C12.17 9.66951 9.66951 12.17 6.585 12.17C3.50049 12.17 1 9.66951 1 6.585C1 3.50049 3.50049 1 6.585 1C9.66951 1 12.17 3.50049 12.17 6.585Z" stroke="#A9A9A9" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
          </svg>
          <span style="font-weight: 600; font-size: 12px; font-family: Inter;">${t('naoFoiPossivelColetar')}</span>
        </div>
      </div>
    `;

  const tooltipFactory = {
    isComparing: {
      yearMode: (values) => {
        const tooltipDate = moment(values[0].name, 'MMM YYYY');
        const monthName = tooltipDate.format('MMMM');
        const year = tooltipDate.format('YYYY');
        let compareYear = '';

        if (year === cardYear) {
          compareYear = state.yearSelected;
        } else if (Number(cardYear) === moment().year()) {
          compareYear = `${Number(state.yearSelected) - 1}`;
        }

        const compareTooltipTitle = `${capitalizeFirstLetter(
          monthName,
        )} ${compareYear}`;

        const chartValueCompare = handleGetDataChart(
          chartData.compare,
          `${monthName} ${compareYear}`,
          'MMMM YYYY',
        );
        const [compareConsumptionValue, compareConsumptionMeasurement] = convertEnergy(chartValueCompare?.consumption ?? 0);

        const showIsProblemCompareElement = !isAdmin && chartValueCompare?.consumption < 0;

        const isProblemCompareElement = createIsProblemTooltipElement(compareTooltipTitle);

        const tooltipCompareColor = isAdmin && chartValueCompare?.consumption < 0 ? '#F3B107' : '#ACACAC';

        const compareElement = createTooltipElement(
          compareTooltipTitle,
          formatNumber(compareConsumptionValue),
          `${compareConsumptionMeasurement}h`,
          formatNumber(chartValueCompare?.totalCharged ?? 0),
          tooltipCompareColor,
        );

        const currentTooltipTitle = `${capitalizeFirstLetter(
          monthName,
        )} ${year}`;

        const chartValueCurrent = handleGetDataChart(
          chartData.current,
          `${monthName} ${year}`,
          'MMMM YYYY',
        );
        const [currentConsumptionValue, currentConsumptionMeasurement] = convertEnergy(chartValueCurrent?.consumption ?? 0);

        const showIsProblemCurrentElement = !isAdmin && chartValueCurrent?.consumption < 0;

        const isProblemCurrentElement = createIsProblemTooltipElement(currentTooltipTitle);

        const tooltipCurrentColor = isAdmin && chartValueCurrent?.consumption < 0 ? '#F3B107' : '#92CC9A';

        const delta = calculateDelta(
          chartValueCurrent?.totalCharged ?? 0,
          chartValueCompare?.totalCharged ?? 0,
        ).toFixed(1);

        const currentElement = createTooltipElement(
          currentTooltipTitle,
          formatNumber(currentConsumptionValue),
          `${currentConsumptionMeasurement}h`,
          formatNumber(chartValueCurrent?.totalCharged ?? 0),
          tooltipCurrentColor,
          delta,
        );

        const divElement = '<div style="width: 100%; height: 1px; background-color: #DDDDDD;"></div>';

        return `<div style="display: flex; flex-direction: column; gap: 8px; padding: 12px;">
          ${showIsProblemCompareElement ? isProblemCompareElement : compareElement}
          ${divElement}
          ${showIsProblemCurrentElement ? isProblemCurrentElement : currentElement}
        </div>`;
      },
      monthMode: (values) => {
        const day = values[0].name;
        const monthName = moment(cardMonth, 'MMMM').format('MM');

        const compareMonthName = moment(state.monthSelected, 'MMMM').format('MM');

        const chartValueCompare = handleGetDataChart(
          chartData.compare,
          `${day}/${compareMonthName}/${state.yearSelected}`,
          'DD/MM/YYYY',
        );

        const compareTooltipTitle = `${day}/${compareMonthName}/${state.yearSelected}`;
        const [compareConsumptionValue, compareConsumptionMeasurement] = convertEnergy(chartValueCompare?.consumption ?? 0);

        const showIsProblemCompareElement = !isAdmin && chartValueCompare?.consumption < 0;

        const isProblemCompareElement = createIsProblemTooltipElement(compareTooltipTitle);

        const tooltipCompareColor = isAdmin && chartValueCompare?.consumption < 0 ? '#F3B107' : '#ACACAC';

        const compareElement = createTooltipElement(
          compareTooltipTitle,
          chartValueCompare ? formatNumber(compareConsumptionValue) : '-',
          `${compareConsumptionMeasurement}h`,
          chartValueCompare ? formatNumber(chartValueCompare?.totalCharged ?? 0) : '-',
          tooltipCompareColor,
        );

        const currentTooltipTitle = `${day}/${monthName}/${cardYear}`;

        const chartValueCurrent = handleGetDataChart(
          chartData.current,
          `${day}/${monthName}/${cardYear}`,
          'DD/MM/YYYY',
        );
        const [currentConsumptionValue, currentConsumptionMeasurement] = convertEnergy(chartValueCurrent?.consumption ?? 0);

        const showIsProblemCurrentElement = !isAdmin && chartValueCurrent?.consumption < 0;

        const isProblemCurrentElement = createIsProblemTooltipElement(currentTooltipTitle);

        const tooltipCurrentColor = isAdmin && chartValueCurrent?.consumption < 0 ? '#F3B107' : '#92CC9A';

        const delta = calculateDelta(
          chartValueCurrent?.totalCharged ?? 0,
          chartValueCompare?.totalCharged ?? 0,
        ).toFixed(1);

        const currentElement = createTooltipElement(
          currentTooltipTitle,
          formatNumber(currentConsumptionValue),
          `${currentConsumptionMeasurement}h`,
          formatNumber(chartValueCurrent?.totalCharged ?? 0),
          tooltipCurrentColor,
          delta,
        );

        const divElement = '<div style="width: 100%; height: 1px; background-color: #DDDDDD;"></div>';

        return `<div style="display: flex; flex-direction: column; gap: 8px; padding: 12px;">
          ${showIsProblemCompareElement ? isProblemCompareElement : compareElement}
          ${divElement}
          ${showIsProblemCurrentElement ? isProblemCurrentElement : currentElement}
        </div>`;
      },
    },
    isNotComparing: {
      yearMode: (values) => {
        const tooltipDate = moment(values[0].name, 'MMM YYYY');
        const monthName = tooltipDate.format('MMMM');
        const year = tooltipDate.format('YYYY');
        const tooltipTitle = `${capitalizeFirstLetter(monthName)} ${year}`;

        const chartValueCurrent = handleGetDataChart(
          chartData.current,
          `${monthName} ${year}`,
          'MMMM YYYY',
        );
        const [consumptionValue, consumptionMeasurement] = convertEnergy(
          chartValueCurrent?.consumption ?? 0,
        );

        const showIsProblemElement = !isAdmin && chartValueCurrent?.consumption < 0;

        const isProblemElement = createIsProblemTooltipElement(tooltipTitle);

        const tooltipColor = isAdmin && chartValueCurrent?.consumption < 0 ? '#F3B107' : '#92CC9A';

        const element = createTooltipElement(
          tooltipTitle,
          formatNumber(consumptionValue),
          `${consumptionMeasurement}h`,
          formatNumber(chartValueCurrent?.totalCharged ?? 0),
          tooltipColor,
        );

        return `<div style="display: flex; flex-direction: column; gap: 8px; padding: 12px;">${
          showIsProblemElement ? isProblemElement : element
        }</div>`;
      },
      monthMode: (values) => {
        const day = values[0].name;
        const monthName = moment(cardMonth, 'MMMM').format('MM');
        const tooltipTitle = `${day}/${monthName}/${cardYear}`;

        const chartValueCurrent = handleGetDataChart(
          chartData.current,
          `${day}/${monthName}/${cardYear}`,
          'DD/MM/YYYY',
        );
        const [consumptionValue, consumptionValueMeasurement] = convertEnergy(
          chartValueCurrent?.consumption ?? 0,
        );

        const showIsProblemElement = !isAdmin && chartValueCurrent?.consumption < 0;

        const isProblemElement = createIsProblemTooltipElement(tooltipTitle);

        const tooltipColor = isAdmin && chartValueCurrent?.consumption < 0 ? '#F3B107' : '#92CC9A';

        const element = createTooltipElement(
          tooltipTitle,
          formatNumber(consumptionValue),
          `${consumptionValueMeasurement}h`,
          formatNumber(chartValueCurrent?.totalCharged ?? 0),
          tooltipColor,
        );

        return `<div style="display: flex; flex-direction: column; gap: 8px; padding: 12px;">${
          showIsProblemElement ? isProblemElement : element
        }</div>`;
      },
    },
  };

  const handleGetXAxisYearMode = () => {
    const monthsOfYear = getArrayMonthsOfYear();

    const monthsOfYearFormatted = monthsOfYear.map(
      (prevYear) => `${prevYear} ${cardYear}`,
    );

    if (Number(cardYear) === moment().year() && !state.isComparingChart) {
      const currentMonth = moment().month();

      const prevYearMonths = monthsOfYear.slice(-(12 - currentMonth));
      const currentYearMonths = monthsOfYear.slice(0, currentMonth + 1);

      const prevYearMonthsFormatted = prevYearMonths.map(
        (prevYear) => `${prevYear} ${isMobile ? (Number(cardYear) - 1).toString().slice(-2) : Number(cardYear) - 1}`,
      );
      const currentYearMonthsFormatted = currentYearMonths.map(
        (prevYear) => `${prevYear} ${isMobile ? (Number(cardYear)).toString().slice(-2) : Number(cardYear)}`,
      );

      return [...prevYearMonthsFormatted, ...currentYearMonthsFormatted];
    }

    if (state.isComparingChart) {
      return monthsOfYearFormatted;
    }

    return [
      ...monthsOfYearFormatted,
      `${monthsOfYear[0]} ${Number(cardYear) + 1}`,
    ];
  };

  const xAxisFactory = {
    yearMode: handleGetXAxisYearMode(),
    monthMode: getArrayDaysOfMonth(
      Number(moment().month(cardMonth).format('M')),
      Number(cardYear),
    ),
  };

  const xAxisFormatterFactory = {
    yearMode: (value) => {
      const [month, year] = value.split(' ');
      const date = `${month} ${year}`;
      const isProblemData = checkProblemData(chartData.current, date, 'MMM YYYY') || checkProblemData(chartData.compare, date, 'MMM YYYY') ? '\n{image|    }' : '';

      return state.isComparingChart ? `${month}${isProblemData}` : `${month}\n{bold|${year}}${isProblemData}`;
    },
    monthMode: (value) => {
      const date = `${cardMonth} ${cardYear} ${value}`;
      const monthDay = moment(date, 'MMMM YYYY DD').format('DD');
      const weekDay = moment(date, 'MMMM YYYY DD').format('d');

      let isProblemData = '';

      if (checkProblemData(chartData.current, date, 'MMMM YYYY DD') || checkProblemData(chartData.compare, date, 'MMMM YYYY DD')) {
        isProblemData = '\n{image|    }';
      }

      let result = `${monthDay}\n`;
      if (weekDay === '0' && !state.isComparingChart) {
        result = `{bold|${result}${daysOfWeek[weekDay][0]}}`;
      } else if (!state.isComparingChart) {
        result += daysOfWeek[weekDay][0];
      }
      result += isProblemData;

      return result;
    },
  };

  const yAxisFormatter = (value) => {
    const [valueDemandConverted, valueDemandUnitMeasurement] = convertEnergy(value);

    return `${formatNumber(
      valueDemandConverted,
    )} ${valueDemandUnitMeasurement}h`;
  };

  const barWidthFactory = {
    isComparing: {
      yearMode: {
        isExpanded: '15px',
        isNotExpanded: '7px',
      },
      monthMode: {
        isExpanded: '9',
        isNotExpanded: '4px',
      },
    },
    isNotComparing: {
      yearMode: {
        isExpanded: '30px',
        isNotExpanded: '18px',
      },
      monthMode: {
        isExpanded: '15px',
        isNotExpanded: '7px',
      },
    },
  };

  const formatSeriesChart = (data, isAdmin) => {
    if (!data.consumption || (!isAdmin && data.consumption < 0)) {
      return 0;
    }

    if (isAdmin && data.consumption < 0) {
      return {
        value: data.consumption,
        itemStyle: {
          color: '#F3B107',
          borderRadius: [0, 0, 5, 5],
        },
      };
    }

    return data.consumption;
  };

  const seriesFactory = (chartData) => {
    const currentFormattedData = chartData.current.map((data) => formatSeriesChart(data, isAdmin));
    const compareFormattedData = chartData.compare.map((data) => formatSeriesChart(data, !isAdmin));

    return {
      isComparing: [
        {
          name: 'Consumo Compare',
          type: 'bar',
          barWidth:
            barWidthFactory.isComparing[chartMode][
              energyCard?.isExpanded ? 'isExpanded' : 'isNotExpanded'
            ],
          color: '#ACACAC',
          data: compareFormattedData,
          itemStyle: {
            borderRadius: [5, 5, 0, 0],
            padding: [0, 20, 0, 0],
          },
        },
        {
          name: 'Consumo',
          type: 'bar',
          barWidth:
            barWidthFactory.isComparing[chartMode][
              energyCard?.isExpanded ? 'isExpanded' : 'isNotExpanded'
            ],
          color: '#92CC9A',
          data: currentFormattedData,
          itemStyle: {
            borderRadius: [5, 5, 0, 0],
            padding: [0, 20, 0, 0],
          },
        },
      ],
      isNotComparing: [
        {
          name: 'Consumo',
          type: 'bar',
          barWidth: isMobile ? '12px' : barWidthFactory.isNotComparing[chartMode][
            energyCard?.isExpanded ? 'isExpanded' : 'isNotExpanded'
          ],
          color: '#92CC9A',
          data: currentFormattedData,
          itemStyle: {
            borderRadius: [5, 5, 0, 0],
            padding: [0, 20, 0, 0],
          },
        },
      ],
    };
  };

  const handleRenderBarchart = (chartData) => {
    const barchartDom = document.getElementById('barchart-demanda');

    if (!barchartDom) return;

    echarts.dispose(barchartDom);
    const barChart = echarts.init(barchartDom);

    window.addEventListener('resize', () => {
      barChart.resize();
    });

    barChart.on('click', 'series', (options) => {
      const barClicked = moment(options.name, 'MMM YYYY');

      state.monthSelected = barClicked.format('MMMM');
      handleClickBarChart(options);
    });

    const barchartOptions = {
      tooltip: {
        trigger: 'axis',
        formatter:
          tooltipFactory[state.isComparingChart ? 'isComparing' : 'isNotComparing'][
            chartMode
          ],
        axisPointer: {
          type: 'none',
        },
        shadowStyle: {
          shadowBlur: 2,
          shadowOffsetY: 0,
          shadowOffsetX: 1,
        },
        lineStyle: {
          width: '40px',
        },
      },
      grid: {
        left: '5%',
        right: '5%',
        bottom: '2%',
        top: '14px',
        width: '90%',
        containLabel: true,
      },
      xAxis: [
        {
          type: 'category',
          data: xAxisFactory[chartMode],
          boundaryGap: false,
          splitLine: {
            show: true,
            lineStyle: {
              type: 'dashed',
            },
          },
          axisLabel: {
            show: true,
            align: 'center',
            margin: 8,
            showMaxLabel: true,
            interval: 0,
            color: '#000',
            fontFamily: 'Inter',
            fontSize: '10',
            lineHeight: 12,
            rich: {
              bold: {
                fontWeight: 'bold',
                color: '#000',
                fontFamily: 'Inter',
                fontSize: '10',
              },
              image: {
                backgroundColor: {
                  image: isAdmin ? WARNING_ICON : INCOERENT_ICON,
                },
              },
            },
            formatter: xAxisFormatterFactory[chartMode],
          },
          axisLine: { show: false },
          axisTick: { show: false },
        },
      ],
      yAxis: [
        {
          type: 'value',
          position: 'left',
          splitNumber: 5,
          axisLabel: {
            align: 'right',
            fontSize: 9,
            color: '#000',
            fontFamily: 'Inter',
            formatter: yAxisFormatter,
          },
          offset: 18,
          axisLine: { show: false },
          axisTick: { show: false },
          splitLine: {
            lineStyle: {
              type: 'dashed',
            },
          },
        },
      ],
      series:
        seriesFactory(chartData)[
          state.isComparingChart ? 'isComparing' : 'isNotComparing'
        ],
    };

    barchartOptions && barChart.setOption(barchartOptions);

    window.removeEventListener('resize', () => {
      barChart.resize();
    });
  };

  useEffect(() => {
    chartData.current && chartData.compare && handleRenderBarchart(chartData);
  }, [chartData, chartMode, state.isComparingChart, energyCard?.isExpanded, menuToogle]);

  return (
    <div style={{ padding: '5px 20px 24px' }}>
      <div style={{ display: 'flex', justifyContent: 'end', margin: '0 2%' }}>
        <SelectOptionDropdown
          mode={chartMode}
          open={state.isOpenComparingDropdown}
          optionSelected={state.yearSelected}
          handleClickOutside={() => {
            state.isOpenComparingDropdown = false;
            render();
          }}
          yearOptions={filterDatesChart.yearOptions}
          monthOptions={filterDatesChart.monthOptions}
          yearSelected={state.yearSelected}
          monthSelected={state.monthSelected}
          isLoading={isLoading}
          handleSelectMonth={(value) => {
            state.monthSelected = value;
            render();
            handleChangeComparingChart(state.isComparingChart, state.yearSelected, value);
          }}
          handleSelectYear={(value) => {
            state.yearSelected = value;
            render();
            handleChangeComparingChart(state.isComparingChart, value, state.monthSelected);
          }}
          handleChangeDate={(value) => {
            const newDate = moment(value, 'MMM YYYY');

            state.yearSelected = newDate.format('YYYY');
            state.monthSelected = newDate.format('MMMM');
            render();
            handleChangeComparingChart(state.isComparingChart, newDate.format('YYYY'), newDate.format('MMMM'));
          }}
        >
          <CheckboxLine>
            <Checkbox
              checked={state.isComparingChart}
              onClick={() => {
                state.isComparingChart = !state.isComparingChart;

                const currentYear = moment(cardYear, 'YYYY').format('YYYY');
                const currentYearIndex = handleGetYearIndex(currentYear);

                if (state.isComparingChart && filterDatesChart.yearOptions.length > 1 && currentYearIndex === 0) {
                  state.yearSelected = moment(filterDatesChart.yearOptions[0].value).format('YYYY');
                }

                if (state.isComparingChart && filterDatesChart.yearOptions.length > 1 && currentYearIndex > 0) {
                  state.yearSelected = moment(filterDatesChart.yearOptions[currentYearIndex - 1].value).format('YYYY');
                }

                handleChangeComparingChart(
                  state.isComparingChart,
                  state.yearSelected,
                  state.monthSelected,
                );
                render();
              }}
              size={15}
              color="primary"
            />
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              <span>{t('compararPeriodo')}</span>
              {state.isComparingChart && (
                <SelectedDate
                  onClick={() => {
                    if (!isLoading) {
                      state.isOpenComparingDropdown = !state.isOpenComparingDropdown;
                      render();
                    }
                  }}
                >
                  <span>
                    {chartMode === 'monthMode'
                      && capitalizeFirstLetter(state.monthSelected)}
                    {' '}
                    {state.yearSelected}
                  </span>
                  <ArrowDownIconV2 color="#000000" width="9" heigth="8" />
                </SelectedDate>
              )}
            </div>
          </CheckboxLine>
        </SelectOptionDropdown>
      </div>
      <BarchartWrapper>
        <div id="barchart-demanda" style={{ width: '100%', height: '100%' }} />
      </BarchartWrapper>
    </div>
  );
};
