import { useMemo, useState } from 'react';
import { useTable, useSortBy, useResizeColumns } from 'react-table';
import { colors } from '../../styles/colors';
import ReactTooltip from 'react-tooltip';
import { t } from 'i18next';

import {
  ArrowTable,
  StyledLink,
  StyledCell,
  StyledTableHeader,
  StyledTableRow,
  StyledBody,
  StyledTable,
  StyledHead,
  selectTableHeadContentColor,
  TitleColumn,
  NoDataComponent,
  OrderColumn,
  ButtonFilters,
  PopoverContent,
  PopoverHeader,
  SearchInput,
  Label,
} from './styles';
import { OrderIcon } from '~/icons';
import * as Popover from '@radix-ui/react-popover';
import SelectSearch, { fuzzySearch } from 'react-select-search';
import { Checkbox, FiltersContainer } from '~/components';

type TableProps = {
  columns: any;
  data: any;
  initialSortBy?: string;
  variant?: 'primary'|'secondary';
  dense?: boolean;
  border?: boolean;
  disableSortBy?: boolean;
  noBorderBottom?: boolean;
  reduceWidth?: boolean;
  maxItens?: number;
  borderRow?: string;
  noDataComponent?: () => void;
  setFilter?: (mensagem: string) => void;
  handleRemoveAllFilters?: () => void;
  handleSearchWithFilters?: () => void;
  handleRemoveFilter?: (key, index) => void;
  handleRemoveCategoryFilter?: (columnKey: string) => void;
  filtersSelected?: {
    [key: string]: {
      label: string;
      values: {
        value: string|number;
        name: string
      }[]
    };
  }
}

type FilterOptions = { onChangeFilter?: (name, item, option) => void, onSelectAllOptions?: (name, filterAll) => void, value?: string[], options?: {}[], hasFilter: boolean, filterAll?: boolean };

export const Table = ({
  setFilter = () => {},
  noDataComponent,
  columns, data, initialSortBy = undefined, variant = 'primary', dense = undefined, disableSortBy = false, maxItens = undefined, handleRemoveAllFilters, handleSearchWithFilters, handleRemoveFilter, filtersSelected, handleRemoveCategoryFilter, borderRow, ...props
}: TableProps): JSX.Element => {
  const defaultColumn = useMemo(
    () => ({
      minWidth: 30,
      width: 100,
      maxWidth: 300,
    }),
    [],
  );

  const {
    getTableProps, getTableBodyProps, headerGroups, prepareRow, rows,
  } = useTable(
    {
      columns,
      data,
      defaultColumn,
      initialState: {
        sortBy: initialSortBy ? [
          {
            id: initialSortBy,
            desc: false,
          },
        ] : [],
      },
      disableSortBy,
    },
    useSortBy,
    useResizeColumns,
  );

  function sliceFinal() {
    if (maxItens) {
      return maxItens;
    }
    return rows.length;
  }

  const filterSet = (filter) => {
    setFilter(filter);
  };

  return (
    <>
      <StyledTable {...props} {...getTableProps()}>
        <StyledHead variant={variant}>
          {headerGroups.map((headerGroup, index) => (
            <StyledTableRow key={index} {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                <StyledTableHeader
                  variant={variant}
                  key={column.Header}
                  {...column.getHeaderProps(column.getSortByToggleProps())}
                  dense={dense}
                  noBorderBottom={props.noBorderBottom}
                  onClick={() => {
                    column.id
                      ? filterSet(column.id) : null;
                  }}
                  style={props.reduceWidth && { width: `calc(${column.width}px + 10px)` }}
                >
                  {column.render('Header')}
                  <ArrowTable
                    {...column.getResizerProps()}
                    style={{ cursor: 'pointer' }}
                    isopen={column.isSortedDesc ? 1 : 0}
                    color={
                      column.isSorted
                        ? selectTableHeadContentColor(variant)
                        : variant === 'primary'
                          ? colors.White
                          : colors.Blue300
                    }
                    dense={dense}
                  />
                </StyledTableHeader>
              ))}
            </StyledTableRow>
          ))}
          {filtersSelected && Object.values(filtersSelected).some((item) => item.values.length > 0) && (
            <tr>
              <th colSpan={columns.length} style={{ padding: borderRow ? '' : '0 10px 20px 10px' }}>
                <FiltersContainer
                  handleRemoveAllFilters={handleRemoveAllFilters}
                  handleRemoveFilter={handleRemoveFilter}
                  filtersSelected={filtersSelected}
                  handleSearchWithFilters={handleSearchWithFilters}
                  handleRemoveCategoryFilter={handleRemoveCategoryFilter}
                />
              </th>
            </tr>
          )}
        </StyledHead>

        {(rows.length === 0 && noDataComponent) ? (
          noDataComponent()
        ) : (
          <>
            <StyledBody borderRow={borderRow} {...getTableBodyProps()}>
              {rows.slice(0, sliceFinal()).map((row) => {
                if (!row.original.hide) {
                  prepareRow(row);
                  return (
                    <StyledTableRow key={(row.original && row.original.rowKey) || row} {...row.getRowProps()} backgroundColor={row.original.backgroundColor}>
                      {row.cells.map((cell, index) => {
                        if (cell.column.Header !== 'Button') {
                          if (cell.row.original.hasInternalLink) {
                            return (
                              <StyledCell key={index} {...cell.getCellProps()} dense={dense}>
                                <StyledLink to={cell.row.original.url}>{cell.render('Cell')}</StyledLink>
                              </StyledCell>
                            );
                          }
                          return (
                            <StyledCell key={index} {...cell.getCellProps()} dense={dense}>
                              {cell.render('Cell')}
                            </StyledCell>
                          );
                        }
                        return (
                          <td
                            key={index}
                            {...cell.getCellProps()}
                            align="right"
                            style={{ borderBottom: '1px solid rgba(162, 172, 189, 0.2)', paddingRight: '24px' }}
                          >
                            {cell.render('Cell')}
                          </td>
                        );
                      })}
                    </StyledTableRow>
                  );
                }
              })}
            </StyledBody>
          </>
        )}
      </StyledTable>
    </>
  );
};

export function ReturnLabelContent(value: string|number, extraProps?: { padding?: boolean, marginBottom?: boolean }): JSX.Element {
  return (
    <div style={{ paddingLeft: extraProps?.padding ? '25px' : '0', marginBottom: extraProps?.marginBottom ? '20px' : '0' }}>{value}</div>
  );
}

export function AddTooltipIfOverflow(text: string, maxLength: number, marginBottom?: boolean): JSX.Element {
  const textAux = text ?? '-';

  const truncatedText = `${textAux?.slice(0, maxLength)}...`;
  const style = { marginBottom: marginBottom ? '20px' : '0' };

  return (
    <>
      { textAux?.length <= maxLength ? (
        <div style={style}>
          {textAux}
        </div>
      ) : (
        <div data-tip={textAux} data-for={`tooltip-${textAux}`} style={style}>
          {truncatedText}
          <ReactTooltip
            id={`tooltip-${textAux}`}
            place="top"
            effect="solid"
            delayHide={100}
            textColor="#000000"
            border
            backgroundColor="rgba(256, 256, 256, 1)"
          />
        </div>
      )}
    </>
  );
}

export function VerifyColumns(visibleColumns: { visible: boolean }[], columnValue: string, marginBottom?: boolean): JSX.Element {
  const columnsVisible = visibleColumns.filter((x) => x.visible === true).length;

  return (
    <>
      {
        columnsVisible <= 3 ? (
          ReturnLabelContent(columnValue, { marginBottom })
        ) : (
          AddTooltipIfOverflow(columnValue, 60, marginBottom)
        )
      }
    </>
  );
}

export function NoColumnsSelected(): JSX.Element {
  return (
    <NoDataComponent>
      <span style={{ fontSize: 13 }}><strong>{t('nenhumaColunaSelecionada')}</strong></span>
    </NoDataComponent>
  );
}

export function GenerateItemColumn(
  name: string,
  accessor: string,
  handleSort: (column: string) => void,
  sortBy: { column: string, desc: boolean },
  filterOptions?: FilterOptions,
): JSX.Element {
  return (
    <TitleColumn key={`accessor_${accessor}`}>
      <PopoverFilters name={name} filterOption={filterOptions} />
      <OrderColumn onClick={() => handleSort(accessor)}>
        <OrderIcon orderDesc={sortBy.column === accessor ? sortBy.desc : false} />
      </OrderColumn>
    </TitleColumn>
  );
}

export function PopoverFilters(props: {
  name, filterOption,
}): JSX.Element {
  const [onOpenPopover, setOnOpenPopover] = useState(false);
  const { name, filterOption } = props;

  return (
    <Popover.Root open={onOpenPopover} onOpenChange={() => filterOption?.options?.length > 0 && setOnOpenPopover((prevOnOpenPopover) => !prevOnOpenPopover)}>
      <Popover.Trigger asChild>
        <ButtonFilters hasFilter={filterOption?.hasFilter}>{name}</ButtonFilters>
      </Popover.Trigger>
      {(filterOption?.hasFilter && filterOption?.options?.length > 0) && (
        <Popover.Portal>
          <Popover.Content
            align="start"
            className="PopoverContent"
            sideOffset={5}
          >
            <PopoverContent>
              <PopoverHeader>
                <svg
                  width="16"
                  height="15"
                  viewBox="0 0 16 15"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M1.79652 2.84785C1.27246 2.26214 1.01043 1.96928 1.00055 1.72039C0.991961 1.50417 1.08487 1.29636 1.25173 1.15859C1.44381 1 1.83678 1 2.62272 1H12.9073C13.6932 1 14.0862 1 14.2783 1.15859C14.4451 1.29636 14.538 1.50417 14.5295 1.72039C14.5196 1.96928 14.2575 2.26214 13.7335 2.84786L9.77966 7.26682C9.6752 7.38358 9.62296 7.44195 9.58572 7.50839C9.55269 7.56732 9.52845 7.63076 9.51378 7.6967C9.49723 7.77104 9.49723 7.84938 9.49723 8.00605V11.711C9.49723 11.8465 9.49723 11.9142 9.47538 11.9728C9.45607 12.0246 9.42466 12.071 9.38377 12.1081C9.3375 12.1502 9.2746 12.1753 9.14878 12.2257L6.79295 13.168C6.53828 13.2699 6.41095 13.3208 6.30873 13.2996C6.21934 13.281 6.1409 13.2279 6.09045 13.1518C6.03277 13.0648 6.03277 12.9276 6.03277 12.6533V8.00605C6.03277 7.84938 6.03277 7.77104 6.01622 7.6967C6.00155 7.63076 5.97731 7.56732 5.94428 7.50839C5.90704 7.44195 5.8548 7.38358 5.75034 7.26682L1.79652 2.84785Z"
                    stroke="#363BC4"
                    strokeWidth="1.7"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
                <h2>Filtro Rápido</h2>
              </PopoverHeader>
              <SearchInput>
                <Label>{name}</Label>
                <SelectSearch
                  options={filterOption.options}
                  value={filterOption.value}
                  multiple
                  printOptions="on-focus"
                  search
                  placeholder="Selecionar"
                  filterOptions={fuzzySearch}
                  onChange={(e, option) => filterOption.onChangeFilter && filterOption.onChangeFilter(name, e, option)}
                />
              </SearchInput>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                }}
              >
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                  }}
                >
                  <Checkbox
                    size={15}
                    checked={filterOption.filterAll}
                    onClick={() => filterOption.onSelectAllOptions && filterOption.onSelectAllOptions(name, !filterOption.filterAll)}
                  />
                  <p
                    style={{
                      textAlign: 'center',
                      marginBottom: '4px',
                      fontSize: '10px',
                      paddingLeft: '4px',
                    }}
                  >
                    Selecionar tudo
                  </p>
                </div>
                <p
                  onClick={() => filterOption.onSelectAllOptions && filterOption.onSelectAllOptions(name, false)}
                  style={{
                    fontSize: '10px',
                    textUnderlineOffset: '4px',
                    color: 'blue',
                    paddingRight: '4px',
                    cursor: 'pointer',
                  }}
                >
                  Limpar
                </p>
              </div>
            </PopoverContent>
          </Popover.Content>
        </Popover.Portal>
      )}
    </Popover.Root>
  );
}
