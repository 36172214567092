import { t } from 'i18next';
import { ContainerTimezoneDST, ContainerTimezoneWarn } from './styles';
import moment from 'moment-timezone';

export const TimezoneWarn = (props: {area: string, gmt: number}): JSX.Element => {
  const isDST = moment().tz(props.area).isDST();
  return (
    <div style={{ display: 'flex', gap: 10 }}>
      <ContainerTimezoneWarn>
        <span>
          {t('estaUnidadeEstaOperandoForaDe')}
          <strong>
            {' '}
            {props.area}
            {' '}
            (GMT
            {' '}
            {props.gmt}
            )
          </strong>
        </span>
      </ContainerTimezoneWarn>
      {
        isDST && (
          <ContainerTimezoneDST>
            <span>{t('horariodeverao')}</span>
          </ContainerTimezoneDST>
        )
      }
    </div>
  );
};
