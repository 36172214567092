import { t } from 'i18next';
import { GenerateItemColumn } from '~/components/Table';
import {
  LinkedStringLabel, StringLabel, ValueLabelWithSufix, ValueLabelWithSufixNullable,
} from '~/pages/General/TableConfigs';

export const ColumnsTableConfig = (state, handleSortData) => [
  {
    Header: () => (
      GenerateItemColumn(t('cliente'), 'clientName', handleSortData, state.columnSort.clientName)
    ),
    accessor: 'clientName',
    disableSortBy: true,
    Cell: (props) => StringLabel(props.row.original.clientName, `clientName-${props.row.original.unitId}`, '0', '11px'),
  },
  {
    Header: () => (
      GenerateItemColumn(t('estado'), 'stateName', handleSortData, state.columnSort.stateName)
    ),
    accessor: 'stateName',
    disableSortBy: true,
    Cell: (props) => StringLabel(props.row.original.stateName, `stateName-${props.row.original.unitId}`, '0', '11px'),
  },
  {
    Header: () => (
      GenerateItemColumn(t('cidade'), 'cityName', handleSortData, state.columnSort.cityName)
    ),
    accessor: 'cityName',
    disableSortBy: true,
    Cell: (props) => StringLabel(props.row.original.cityName, `cityName-${props.row.original.unitId}`, '0', '11px'),
  },
  {
    Header: () => (
      GenerateItemColumn(t('unidade'), 'unitName', handleSortData, state.columnSort.unitName)
    ),
    accessor: 'unitName',
    disableSortBy: true,
    Cell: (props) => LinkedStringLabel(props.row.original.unitName, props.row.original.unitId, `unitName-${props.row.original.unitId}`, '0', '11px'),
  },
  {
    Header: () => (
      GenerateItemColumn(`${t('consumo')} (kWh)`, 'consumption', handleSortData, state.columnSort.consumption)
    ),
    accessor: 'consumption',
    disableSortBy: true,
    Cell: (props) => ValueLabelWithSufix(props.row.original.consumption, ' kWh', `consumption-${props.row.original.unitId}`, '0', '11px'),
  },
  {
    Header: () => (
      GenerateItemColumn(`${t('consumo')} (R$)`, 'totalCharged', handleSortData, state.columnSort.totalCharged)
    ),
    accessor: 'totalCharged',
    disableSortBy: true,
    Cell: (props) => ValueLabelWithSufixNullable(props.row.original.totalCharged, ' R$', `totalCharged-${props.row.original.unitId}`, '0', '11px'),
  },
  {
    Header: () => (
      GenerateItemColumn(`${t('consumoPorM')} (kWh/m²)`, 'consumptionByArea', handleSortData, state.columnSort.consumptionByArea)
    ),
    accessor: 'consumptionByArea',
    disableSortBy: true,
    Cell: (props) => ValueLabelWithSufixNullable(props.row.original.consumptionByArea, ' kWh/m²', `consumptionByArea-${props.row.original.unitId}`, '0'),
  },
];
