import { useState, useEffect } from 'react';

import { useDispatch } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';

import DielLogo from '~/assets/img/logos/NewLogoDiel.svg';
import SpringerLogoPng from '~/assets/img/logos/springer-logo.png';
import BurgerIcon from '~/assets/img/sidebar/Burger.png';
import CloseIcon from '~/assets/img/sidebar/Close.png';
import UserIcon from '~/assets/img/sidebar/User.png';
import { MenuMobile } from '~/components/MenuMobile';
import { getUserProfile } from '~/helpers/userProfile';
import { useTranslation } from 'react-i18next';
import CelciusLogoWhiteBallWhite from '../../assets/img/logos/CelciusLogoWhiteBallWhite.svg';
import {
  StyledLink,
  NavItem,
  UserContainer,
  WrapperItem,
  RelativeWrapper,
  CloseWrapper,
  ModalNavItems,
  ActionableWrapper,
  Icon,
  Logo,
  Topbar,
  Wrapper,
  SpringerLogo,
} from './styles';
import { apiCall } from '~/providers';

const MobileNavbar = ({ history }) => {
  const { t } = useTranslation();
  const [isModalNavItemsOpen, setIsModalNavItemsOpen] = useState(false);
  const [isModalUserOpen, setIsModalUserOpen] = useState(false);
  const [profile] = useState(getUserProfile);
  const me = profile.fullName;
  const { user } = profile;
  const dispatch = useDispatch();
  const [clientIds, setClientIds] = useState<number[] | null>(null);

  const logout = () => {
    localStorage.clear();
    dispatch({ type: 'RESET_DATA' });
    history.push('/login');
  };

  useEffect(() => {
    if (!isModalUserOpen && !isModalNavItemsOpen) {
      document.body.style.overflow = 'auto';
    } else {
      document.body.style.overflow = 'hidden';
    }
  }, [isModalUserOpen, isModalNavItemsOpen]);

  const getUserClientId = async () => {
    try {
      const { profiles_v2 } = await apiCall('/users/get-user-info', { userId: profile.user });
      setClientIds([...profiles_v2.map((p) => p.clientId), ...profile.permissions.PER_CLIENT.map((c) => (c.clientId))]);
    } catch (err) {
      console.log(err);
      setClientIds([]);
    }
  };

  useEffect(() => {
    getUserClientId();
  }, []);

  return (
    <Wrapper>
      <Topbar>
        <ActionableWrapper onClick={() => setIsModalNavItemsOpen(true)}>
          <Icon src={BurgerIcon} alt="burger" />
        </ActionableWrapper>
        <Link to="/visao-geral">
          {/* 157 - Atacadão
          158 - Carrier */}
          {
            (clientIds && clientIds?.filter((item) => (item !== 157 && item !== 158)).length !== 0 || profile.permissions.isAdminSistema) && <Logo src={CelciusLogoWhiteBallWhite} alt="logoCelcius " />
          }
          {
            (clientIds && clientIds?.filter((item) => (item !== 157 && item !== 158)).length === 0 && !profile.permissions.isAdminSistema) && <SpringerLogo src={SpringerLogoPng} alt="logoSpringer" />
          }
        </Link>
        <ActionableWrapper onClick={() => setIsModalUserOpen(true)}>
          <Icon src={UserIcon} alt="user-icon" />
        </ActionableWrapper>
      </Topbar>
      {(isModalNavItemsOpen || isModalUserOpen) && (
        <ModalNavItems>
          <RelativeWrapper>
            <CloseWrapper
              onClick={() => (isModalNavItemsOpen ? setIsModalNavItemsOpen(false) : setIsModalUserOpen(false))}
            >
              <Icon src={CloseIcon} alt="close-icon" />
            </CloseWrapper>
            {isModalNavItemsOpen ? (
              <MenuMobile setIsModalNavItemsOpen={setIsModalNavItemsOpen} />
            ) : (
              <UserContainer>
                <NavItem isBold>{me || user || ''}</NavItem>
                <ActionableWrapper onClick={() => setIsModalUserOpen(false)}>
                  <StyledLink to="/configuracoes">
                    <NavItem>{t('configuracoes')}</NavItem>
                  </StyledLink>
                </ActionableWrapper>
                <WrapperItem style={{ bottom: 150 }}>
                  <NavItem onClick={logout}>{t('sair')}</NavItem>
                </WrapperItem>
              </UserContainer>
            )}
          </RelativeWrapper>
        </ModalNavItems>
      )}
    </Wrapper>
  );
};

const MobileNavbarWrapper = withRouter(MobileNavbar);

export { MobileNavbarWrapper as MobileNavbar };
