import { Flex } from 'reflexbox';
import {
  BlackBlob,
  GreenBlob, GreyBlob, RedBlob, YellowBlob,
} from './styles';
import ProgressBar from './ProgressBar';
import { t } from 'i18next';

const getAlarmInfo = (value: number | null) => {
  if (value === 0) { // Normal
    return {
      label: t('semAlarmes'),
      blob: <GreenBlob />,
    };
  }
  if (value === 1) { // Alert
    return {
      label: t('emAlerta'),
      blob: <YellowBlob />,
    };
  }
  if (value === 2) { // Alarm
    return {
      label: t('emAlarme'),
      blob: <RedBlob />,
    };
  }

  return {
    label: t('semInformacao'),
    blob: <GreyBlob />,
  };
};

const getGeralStatusInfo = (value: number | null) => {
  if (value === 0) { // Start
    return {
      label: t('desligadoMinusculo'),
    };
  }

  if (value === 1) { // Stop
    return {
      label: t('emFuncionamento'),
    };
  }
  if (value === 2) { // Stop
    return {
      label: t('emParada'),
    };
  }
  if (value === 3) { // Stop
    return {
      label: t('emPartida'),
    };
  }
  if (value === 4) { // Stop
    return {
      label: t('Desarmado'),
    };
  }
  if (value === 5) { // Stop
    return {
      label: t('pronto'),
    };
  }
  if (value === 6) { // Stop
    return {
      label: t('forcado'),
    };
  }
  if (value === 7) { // Stop
    return {
      label: t('emDescongelamento'),
    };
  }
  if (value === 8) { // Stop
    return {
      label: t('emTesteCarga'),
    };
  }
  if (value === 9) { // Stop
    return {
      label: t('teste'),
    };
  }
  return {
    label: t('semInformacao'),
  };
};

const getEmergencyStopInfo = (value: number | null) => {
  if (value === 0) { // Off
    return {
      label: t('desligadoMinusculo'),
      blob: <BlackBlob />,
    };
  }
  if (value === 1) { // On
    return {
      label: t('emFuncionamento'),
      blob: <RedBlob />,
    };
  }
  if (value === 2) { // Off
    return {
      label: t('parando'),
      blob: <BlackBlob />,
    };
  }
  if (value === 3) { // On
    return {
      label: t('atrasado'),
      blob: <RedBlob />,
    };
  }
  if (value === 4) { // Off
    return {
      label: t('desarmado'),
      blob: <BlackBlob />,
    };
  }
  if (value === 5) { // On
    return {
      label: t('preparado'),
      blob: <RedBlob />,
    };
  }
  if (value === 6) { // Off
    return {
      label: t('forcado'),
      blob: <BlackBlob />,
    };
  }
  if (value === 7) { // On
    return {
      label: t('descongelando'),
      blob: <RedBlob />,
    };
  }
  if (value === 8) { // Off
    return {
      label: t('testeCorrida'),
      blob: <BlackBlob />,
    };
  }
  if (value === 9) { // On
    return {
      label: t('teste'),
      blob: <RedBlob />,
    };
  }

  return {
    label: t('semInformacao'),
    blob: <GreyBlob />,
  };
};

export default function StatusCard(props: {totalCapacity: number, geralStatus: number | null, alarm: number | null, emergencyStop: number | null, tablet: boolean, mobile: boolean}): JSX.Element {
  const {
    totalCapacity, geralStatus, alarm, emergencyStop, tablet, mobile,
  } = props;

  return (
    <Flex flexDirection="column" height="135px" width={tablet ? '100%' : 'unset'} padding={mobile ? '15px 15px 0 15px' : '15px 30px 0px 30px'} alignItems="center" justifyContent="flex-start" style={{ border: '1px solid #d9d9d9', borderRadius: 10 }}>
      <Flex style={{ fontWeight: 'bold' }} marginBottom="10px" alignSelf="flex-start">
        {t('status')}
      </Flex>
      <Flex flexDirection="row" alignSelf={tablet ? 'flex-start' : 'unset'}>
        <Flex flexDirection="column" paddingRight={mobile ? '0px' : '20px'} marginRight={mobile ? '10px' : '20px'} style={{ borderRight: '1px solid #d9d9d9' }}>
          <span style={{ fontWeight: 'bold', fontSize: '12px' }}>{t('geral')}</span>
          <Flex flexDirection="row" alignItems="center" justifyContent="space-between" fontSize={mobile ? '12px' : 'unset'} marginRight={mobile ? '6px' : '15px'}>
            {getGeralStatusInfo(geralStatus).label}
          </Flex>
        </Flex>
        <Flex flexDirection="column" paddingRight={(alarm === 2 ? '20px' : '0px')} marginRight={(alarm === 2 ? '20px' : '0px')} style={{ borderRight: (alarm === 2 ? '1px solid #d9d9d9' : 'none') }}>
          <span style={{ fontWeight: 'bold', fontSize: '12px' }}>{t('alarme')}</span>
          <Flex flexDirection="row" alignItems="center" justifyContent="space-between" fontSize={mobile ? '12px' : 'unset'} marginRight={mobile ? '6px' : '15px'}>
            {getAlarmInfo(alarm).blob}
            {getAlarmInfo(alarm).label}
          </Flex>
        </Flex>
        {(alarm === 2) && (
          <Flex flexDirection="column" paddingRight="0px">
            <span style={{ fontWeight: 'bold', fontSize: '12px', marginBottom: '-5px' }}>{t('paradaDeEmergencia')}</span>
            <Flex flexDirection="row" alignItems="center" justifyContent="space-between" fontSize={mobile ? '12px' : 'unset'}>
              {getEmergencyStopInfo(emergencyStop).blob}
              {getEmergencyStopInfo(emergencyStop).label}
            </Flex>
          </Flex>
        )}
      </Flex>
      <Flex flexDirection="row" width="100%" alignSelf="flex-start">
        <ProgressBar title={t('capacidadeTotalLabel')} progress={Math.floor(totalCapacity)} disabled={(!totalCapacity) || (!geralStatus && geralStatus !== 0)} />
      </Flex>
    </Flex>
  );
}
