import SelectSearch, {
  fuzzySearch,
  SelectedOptionValue,
  SelectedOption,
  SelectSearchOption,
  SelectSearchProps,
} from 'react-select-search';
import { Box, Flex } from 'reflexbox';
import { useStateVar } from '~/helpers/useStateVar';
import {
  SearchInput,
  Label,
  StatusIcon,
  ControlFilter,
  CleanBtn,
  ExportBtn,
  TempGreat,
  TempHigh,
  TempLow,
  NoTempData,
  OptionExportList,
  CancelButton,
  ContentDate,
  DateLabel,
  ContainerCheckbox,
  HoverExportList,
  ModalWindowStyled,
  ContainerModal,
  TitleModal,
  SubtitleModal,
  FiltersContainer,
} from './styles';
import { DateRangePicker, SingleDatePicker } from 'react-dates';
import i18n from '~/i18n';
import {
  Button, Card, Checkbox, HealthIcon, ModalWindow,
} from '~/components';

import {
  useEffect,
  useRef,
  useState,
} from 'react';
import { toast } from 'react-toastify';
import {
  ArrowDownIconV2, ArrowUpIconV2, CompleteListIcon, CustomListIcon, ExportIcon, FilterIcon, InfoIcon,
} from '~/icons';
import { colors } from '~/styles/colors';
import { CSVLink } from 'react-csv';
import { getUserProfile } from '~/helpers/userProfile';
import { QuickSelection } from '~/components/QuickSelection';
import ReactTooltip from 'react-tooltip';
import moment, { Moment } from 'moment';
import { StyledCalendarIcon } from '../../DACs/DACHistoric';
import {
  getClientsList, getCitiesList, getStatesList, getUnitsList, getCountry,
} from '~/helpers/genericHelper';
import { InputSearch } from '~/components/NewInputs/Search';
import { apiCall } from '~/providers';

const t = i18n.t.bind(i18n);

// para usar o filtro tem que ter as variaveis criadas no state do componente:
// state.searchState
// state.selectedCity
// state.selectedState
// state.selectedUnit
// state.selectedClientFilter
// state.selectedHealthOpts
// state.selectedTemperatureOpts
// state.selectedControlOpts
// state.selectedModeEcoOpts
// state.selectedPeriodOpts
// state.selectedDataTypeOpts
// state.selectedAnalysisOpts
// state.selectedCountry

export type TListFilters = 'progDisp' | 'tipo' | 'operacao' | 'cliente' | 'estado' | 'cidade' | 'unidade' | 'statusOperacao' | 'conexao' | 'status' | 'saude' | 'temperatura' | 'controle' | 'modoEco' | 'periodo' | 'data' | 'dataUnica' | 'dados' | 'analise' | 'pais' | 'id' | 'search' | 'tipoNotificacao' | 'subtipoNotificacao' | 'destinatario';

type TValueFilter = string | string[];

export const UtilFilter = (props:{
  state: any,
  updateCheckInformations?: (value: any, date?: Moment) => void,
  informationsUnits?: any,
  optionsConnetions?: SelectSearchOption[],
  optionsConnetionsName?: string,
  render: any,
  searchLabel?: string;
  onAply: () => void, // funçao para quando mudar o valor do select;
  clearFilters?: () => void, // função de limpar o filtro
  listFilters?: TListFilters[], // passar os selects que é pra ter;
  exportFunc?: () => Promise<void> | void, // usado em exportação;
  typeValueName?: TListFilters[], // usado para quando você quer que os valores sejam os nomes ou seja label e name ficam iguais;
  isProg?: boolean, // usado no select de dispostivo / programação;
  setView?: (timeSelected: string) => void, // usado no select de periodo para ter as seleções rapidas
  setProg?: (value: string) => void, // usado no select de dispostivo / programação;
  infoType?: { value: string, name: string }[],
  isFilterButton?: boolean,
  isPeriodLabel?: boolean,
  isMaxOneMonthPeriod?: boolean,
  csvHeader?: { // usado para exportação
  label: string;
  key: string;
  }[]}): JSX.Element => {
  const [state, render, setState] = useStateVar(() => {
    const state = {
      isLoading: false, // setIsLoading
      showFilter: true as boolean,
      statesListOpts: [] as { value: string, name: string }[],
      selectedStateOpts: [] as TValueFilter,
      citiesListFilterOpts: [] as { value: string, name: string, stateId: number }[],
      citiesListOpts: [] as { value: string, name: string, stateId: number }[],
      selectedCityOpts: [] as TValueFilter,
      clientsFilterListOpts: [] as { value: string, name: string, cities: string[] }[],
      clientsListOpts: [] as { value: string, name: string, cities: string[], states: number[] }[],
      selectedClientOpts: [] as TValueFilter,
      unitsFilterOpts: [] as { value: string, name: string, clientId: number, city: string }[],
      unitsListOpts: [] as { value: string, name: string, clientId: number, city: string, state: string }[],
      selectedUnitOpts: [] as TValueFilter,
      selectedConnectionOpts: [] as TValueFilter,
      selectedStatusOpts: [] as TValueFilter,
      selectedHealthOpts: [] as TValueFilter,
      selectedTemperatureOpts: [] as TValueFilter,
      selectedControlOpts: [] as TValueFilter,
      selectedModeEcoOpts: [] as TValueFilter,
      selectedPeriodOpts: '' as string,
      selectedDataTypeOpts: 'energia' as TValueFilter,
      selectedAnalysisOpts: 'unidades' as TValueFilter,
      selectedCountry: [] as TValueFilter,
      countryListOpts: [] as { value: string | number; name: string; }[],
      searchTerms: '',
      selectStartOperationOpts: '' as TValueFilter,
      selectEndOperationOpts: '' as TValueFilter,
      selectedOperationStatusOpts: [] as TValueFilter,
      selectedOwnershipFilter: props.infoType ? 'all' : { value: 'CLIENTS' } as any | any[],
      selectedInfoExib: 'dispositivo' as any | any[],
      dateValuePicker: {} as {
        startDate: moment.Moment | null,
        endDate: moment.Moment | null,
      },
      tomorrow: moment(moment().add(1, 'days').format('YYYY-MM-DD')),
      focused: {} as {
        id: string,
        focused: boolean,
      },
      selectedDevId: '',
      csvData: [], // setCsvData,
      selectedNotificationType: [] as TValueFilter,
      selectedNotificationSubtype: [] as TValueFilter,
      selectedNotificationDestinatary: [] as TValueFilter,
      selectedDateStart: null as Moment | null,
      selectedDateEnd: null as Moment | null,
      selectFocused: false,
      destinataryListOps: [] as { name: string, value: string }[],
      buttonName: props?.isFilterButton ? t('filtrar') : t('botaoAnalisar'),
      typesNotificationFilterOpts: [] as { value: number, name: string }[],
      typesNotificationListOpts: [] as { value: number, name: string }[],
      subtypesNotificationFilterOpts: [] as { value: number, name: string, typeId: number }[],
      subtypesNotificationListOpts: [] as { value: number, name: string, typeId: number }[],
    };
    return state;
  });
  const [profile] = useState(getUserProfile);

  function clearFilters() {
    state.selectedCityOpts = [];
    state.searchTerms = '';
    state.selectedClientOpts = [];
    state.selectedConnectionOpts = [];
    state.selectedStateOpts = [];
    state.selectedStatusOpts = [];
    state.selectedUnitOpts = [];
    state.selectedHealthOpts = [];
    state.selectedTemperatureOpts = [];
    state.selectedControlOpts = [];
    state.selectedModeEcoOpts = [];
    state.selectedPeriodOpts = '';
    state.selectedDataTypeOpts = [];
    state.selectedAnalysisOpts = [];
    state.selectedNotificationType = [];
    state.selectedNotificationSubtype = [];
    state.selectedNotificationDestinatary = [];
    if (props.listFilters?.includes('data')) {
      props.informationsUnits.dates.dateStart = null;
      props.informationsUnits.dates.dateEnd = null;
    }
    if (props.state.isUnits) {
      props.informationsUnits.statusUnitsCheck = '2';
    }
    state.selectEndOperationOpts = '';
    state.selectStartOperationOpts = '';
    state.dateValuePicker.startDate = null;
    state.dateValuePicker.endDate = null;
    state.selectedDateStart = null;
    state.selectedDateEnd = null;
    state.selectedDevId = '';
    clearOwners();
    setState({ citiesListFilterOpts: state.citiesListOpts });
    setState({ unitsFilterOpts: state.unitsListOpts });
    setState({ clientsFilterListOpts: state.clientsListOpts });
    setState({ typesNotificationFilterOpts: state.typesNotificationListOpts });
    setState({ subtypesNotificationFilterOpts: state.subtypesNotificationListOpts });
    render();
    if (!props.onAply) {
      filter();
    }
    sessionStorage.clear();
    props.state.needFilter = null;
    state.isLoading = false;
  }

  async function getDestinataryListOpts() {
    try {
      const clientIds = Array.isArray(state.selectedClientOpts) && state.selectedClientOpts.length > 0 ? state.selectedClientOpts.map((x) => Number(x)) : undefined;
      await apiCall('/users/list-users', { CLIENT_IDS: clientIds, includeAdmins: profile.permissions.isAdminSistema }).then(({ list, adminUsers }) => {
        const fullList = list.map((item) => ({ FULLNAME: item.FULLNAME, USER: item.USER, unitIds: item.unitIds })) as {
          FULLNAME: string,
          USER: string,
          unitIds?: number[],
        }[];
        for (const adminUser of (adminUsers || [])) {
          if (fullList.some((x) => x.USER === adminUser.USER)) { continue; } // user already in the list
          else fullList.push({ FULLNAME: adminUser.FULLNAME, USER: adminUser.USER });
        }
        state.destinataryListOps = fullList.map((x) => ({ name: x.FULLNAME, value: x.USER }));
      });
    } catch (err) {
      console.log(err);
      toast.error(t('erroDestinatarios'));
    }
    render();
  }

  function getTypesNotificationListOpts() {
    setState({
      typesNotificationListOpts: [
        { value: 1, name: t('ambiente') },
        { value: 2, name: t('maquina') },
        { value: 3, name: t('utilitario') },
      ],
    });
    setState({ typesNotificationFilterOpts: state.typesNotificationListOpts });
    render();
  }

  function getSubtypesNotificationListOpts() {
    setState({
      subtypesNotificationListOpts: [
        { value: 4, name: t('agua'), typeId: 3 },
        { value: 1, name: t('ambiente'), typeId: 1 },
        { value: 3, name: t('energia'), typeId: 3 },
        { value: 5, name: t('saude'), typeId: 2 },
        { value: 6, name: t('usoCondensadora'), typeId: 2 },
        { value: 7, name: t('VRF'), typeId: 2 },
      ],
    });
    setState({ subtypesNotificationFilterOpts: state.subtypesNotificationListOpts });
    render();
  }

  function clearOwners() {
    if (props.infoType) {
      state.selectedOwnershipFilter = 'all';
    } else {
      state.selectedOwnershipFilter = { value: 'CLIENTS' };
    }
  }

  async function getValues() {
    if (props.listFilters?.includes('estado')) {
      await getStatesList(state, render);
    }
    if (props.listFilters?.includes('cidade')) {
      await getCitiesList(state, render);
      setState({ citiesListFilterOpts: state.citiesListOpts });
    }
    if (props.listFilters?.includes('cliente')) {
      await getClientsList(state, render);
      setState({ clientsFilterListOpts: state.clientsListOpts });
    }
    if (props.listFilters?.includes('unidade')) {
      await getUnitsList(state, render);
      setState({ unitsFilterOpts: state.unitsListOpts });
    }
    if (props.listFilters?.includes('pais')) {
      await getCountry(state, render, props.typeValueName);
    }

    getPreFilteredParams();
  }

  useEffect(() => {
    if (props.listFilters?.includes('tipoNotificacao')) {
      getTypesNotificationListOpts();
    }

    if (props.listFilters?.includes('subtipoNotificacao')) {
      getSubtypesNotificationListOpts();
    }

    getValues();
  }, []);

  useEffect(() => {
    if (props.listFilters?.includes('destinatario')) {
      getDestinataryListOpts();
    }
  }, [state.selectedClientOpts]);

  function getPreFilteredParams() {
    let hasPrefilters = false;

    if (props.state?.selectedState?.length) {
      hasPrefilters = true;
      setState({ selectedStateOpts: props.state.selectedState });
    }
    if (props.state?.selectedCity?.length) {
      hasPrefilters = true;
      setState({ selectedCityOpts: props.state.selectedCity });
    }
    if (props.state?.selectedUnit?.length) {
      hasPrefilters = true;
      setState({ selectedUnitOpts: props.state.selectedUnit });
    }
    if (props.state.selectedTemperature?.length) {
      hasPrefilters = true;
      setState({ selectedTemperatureOpts: props.state.selectedTemperature });
    }

    if (hasPrefilters) {
      setVariables();
      props.onAply!();
    }
  }

  function filter() {
    props.state.isLoading = true; props.render();
    props.state.filteredUtilities = props.state.utilities
      .filter((dev) => !state.selectedStateOpts!.length || state.selectedStateOpts!.includes(dev.STATE_UF))
      .filter((dev) => !state.selectedCityOpts!.length || state.selectedCityOpts!.includes(dev.CITY_NAME))
      .filter((dev) => !state.selectedClientOpts!.length || state.selectedClientOpts!.includes(dev.CLIENT_NAME))
      .filter((dev) => !state.selectedUnitOpts!.length || state.selectedUnitOpts!.includes(dev.UNIT_NAME))
      .filter((dev) => !state.selectedConnectionOpts!.length || state.selectedConnectionOpts!.includes(dev.connection))
      .filter((dev) => !state.selectedStatusOpts!.length || state.selectedStatusOpts!.includes(dev.status));

    props.state.isLoading = false; props.render();
  }

  function setVariablesUsuals() {
    if (props.listFilters?.includes('estado')) {
      props.state.selectedState = state.selectedStateOpts;
      defineSessionStorage('filterStates', state.selectedStateOpts);
    }
    if (props.listFilters?.includes('cidade')) {
      props.state.selectedCity = state.selectedCityOpts;
      defineSessionStorage('filterCity', state.selectedCityOpts);
    }
    if (props.listFilters?.includes('cliente')) {
      props.state.selectedClientFilter = state.selectedClientOpts;
      defineSessionStorage('filterClient', state.selectedClientOpts);
    }
    if (props.listFilters?.includes('unidade')) {
      props.state.selectedUnit = state.selectedUnitOpts;
      defineSessionStorage('filterUnit', state.selectedUnitOpts);
    }
    if (props.listFilters?.includes('search')) {
      props.state.searchState = state.searchTerms;
    }
  }

  function setVariablesNotification() {
    if (props.listFilters?.includes('dataUnica')) {
      props.state.selectedDateStart = state.selectedDateStart;
      props.state.selectedDateEnd = state.selectedDateEnd;
    }
    if (props.listFilters?.includes('tipoNotificacao')) {
      props.state.selectedNotificationType = state.selectedNotificationType;
    }
    if (props.listFilters?.includes('subtipoNotificacao')) {
      props.state.selectedNotificationSubtype = state.selectedNotificationSubtype;
    }
    if (props.listFilters?.includes('destinatario')) {
      props.state.selectedNotificationDestinatary = state.selectedNotificationDestinatary;
    }
  }

  function defineSessionStorage(key, value) {
    sessionStorage.setItem(key, value);
  }

  function findCity(cities, selected) {
    let include = false;
    cities.forEach((item) => {
      if (selected.includes(item)) {
        include = true;
      }
    });
    return include;
  }

  function findState(value, stateClients) {
    let include = false;
    value.forEach((item) => {
      if (stateClients.includes(Number(item))) {
        include = true;
      }
    });
    return include;
  }

  function onChangeState(value) {
    if (value) {
      state.selectedStateOpts = value;
      state.selectedUnitOpts = [];
      state.selectedCityOpts = [];
      state.selectedClientOpts = [];
      setState({ citiesListFilterOpts: state.citiesListOpts.filter((item) => (value.includes(item.stateId?.toString()) || value === item.stateId?.toString())) });
      setState({ unitsFilterOpts: state.unitsListOpts.filter((item) => (value.includes(item.state?.toString()) || value === item.state?.toString())) });
      setState({ clientsFilterListOpts: state.clientsListOpts.filter((item) => findState(value, item.states)) });
      render();
    }
  }

  function onChangeCity(value) {
    if (value) {
      state.selectedClientOpts = [];
      state.selectedUnitOpts = [];
      state.selectedCityOpts = value;
      setState({ clientsFilterListOpts: state.clientsListOpts.filter((item) => findCity(item.cities, value)) });
      setState({ unitsFilterOpts: state.unitsListOpts.filter((item) => (value.includes(item.city?.toString()) || value === item.city?.toString())) });
      render();
    }
  }
  function onChangeClient(value) {
    if (value) {
      state.selectedUnitOpts = [];
      state.selectedClientOpts = value;
      setState({ unitsFilterOpts: state.unitsListOpts.filter((item) => (value.includes(item.clientId?.toString()) || value === item.clientId?.toString()) && (state.selectedCityOpts.length === 0 || state.selectedCityOpts.includes(item.city) || state.selectedCityOpts === item.city)) });
      render();
    }
  }
  function onChangeNotificationTypes(value) {
    if (value) {
      state.selectedNotificationSubtype = [];
      state.selectedNotificationType = value;
      setState({ subtypesNotificationFilterOpts: state.subtypesNotificationListOpts.filter((item) => (value.includes(item.typeId) || value === item.typeId)) });
      render();
    }
  }

  function setVariables() {
    setVariablesUsuals();
    if (props.listFilters?.includes('conexao')) {
      props.state.selectedConnection = state.selectedConnectionOpts;
    }
    if (props.listFilters?.includes('status')) {
      props.state.selectedStatus = state.selectedStatusOpts;
    }
    if (props.listFilters?.includes('saude')) {
      props.state.selectedHealth = state.selectedHealthOpts;
    }
    if (props.listFilters?.includes('temperatura')) {
      props.state.selectedTemperature = state.selectedTemperatureOpts;
    }
    if (props.listFilters?.includes('controle')) {
      props.state.selectedControlType = state.selectedControlOpts;
    }
    if (props.listFilters?.includes('modoEco')) {
      props.state.selectedEcoMode = state.selectedModeEcoOpts;
    }
    if (props.listFilters?.includes('periodo')) {
      props.state.selectedTimeRange = state.selectedPeriodOpts;
    }
    if (props.listFilters?.includes('dados')) {
      props.state.selectedDataType = state.selectedDataTypeOpts;
    }
    if (props.listFilters?.includes('analise')) {
      props.state.selectedAnalysis = state.selectedAnalysisOpts;
    }
    if (props.listFilters?.includes('pais')) {
      props.state.selectedCountry = state.selectedCountry;
    }
    if (props.listFilters?.includes('operacao')) {
      props.state.selectedOperationStatus = state.selectedOperationStatusOpts;
    }
    if (props.listFilters?.includes('tipo')) {
      props.state.ownershipFilter = state.selectedOwnershipFilter;
    }
    if (props.listFilters?.includes('id')) {
      props.state.selectedDevId = state.selectedDevId;
    }
    setVariablesNotification();
  }

  function quickHandleChangeData(startDate, endDate, timeSelected) {
    const data = [...props.state.data];
    data.forEach((itemData) => {
      itemData.date.startDate = startDate.format('YYYY-MM-DD');
      itemData.date.endDate = endDate.format('YYYY-MM-DD');
    });
    setState({ ...state, selectedPeriodOpts: timeSelected, data });
    props.setView!(timeSelected);
    props.state.selectedTimeRange = state.selectedPeriodOpts;
  }

  const handleClickChange = (value: any, date?: Moment | null) => {
    if (props.updateCheckInformations) {
      if (date) props.updateCheckInformations(value, date);
      else props.updateCheckInformations(value);
    }
    props.render();
  };

  function quickChangeData(startDate, endDate) {
    props.informationsUnits.dates.dateStart = startDate;
    if (endDate) { handleClickChange('dateEnd', endDate); }
  }

  function onSelectedOwnership(value) {
    if (!props.infoType) {
      state.selectedOwnershipFilter = { value };
    } else {
      state.selectedOwnershipFilter = value;
    }
    render();
  }

  const setOnChange = (opt, data) => {
    if (opt.placeholder === t('inicioOperacao')) {
      state.dateValuePicker.startDate = data;
    } else {
      state.dateValuePicker.endDate = data;
    }
  };
  const isOutsideRange = (day) => {
    if (!props.isMaxOneMonthPeriod || props.state.focusedInput === 'startDate') return !day.isBefore(state.tomorrow);
    if (props.state.focusedInput === 'endDate') {
      return !day.isBefore(state.tomorrow) || day.isAfter(moment(new Date(props.informationsUnits.dates.dateStart)).add(30, 'days'));
    }
    return false;
  };

  return (
    <Flex style={{ position: 'relative' }} flexDirection="column">
      <FiltersContainer
        flexWrap={['wrap', 'wrap', 'wrap', 'nowrap', 'nowrap']}
        showFilter={state.showFilter}
      >
        {
          state.showFilter && (
            <Flex
              flexDirection="row"
              justifyContent="center"
              mt={state.showFilter ? 0 : -23}
              alignItems="flex-start"
              style={{
                transition: 'visibility 0.5s, opacity 0.5s, background-color 0.5s, margin-top 1s',
                visibility: state.showFilter ? 'visible' : 'hidden',
                opacity: state.showFilter ? '1' : '0',
              }}
              flexWrap={['wrap', 'wrap', 'wrap', 'wrap', 'nowrap']}
            >
              <div style={{
                width: '100%',
              }}
              >
                <div style={{
                  display: 'flex',
                  flexWrap: 'wrap',
                }}
                >
                  {
                    props.listFilters?.includes('pais') && (
                      <FilterOption
                        options={state.countryListOpts}
                        value={state.selectedCountry}
                        onChange={(value) => { state.selectedCountry = value as any; render(); }}
                        multiple
                        placeholder={t('pais')}
                        disabled={state.isLoading || props.state.isLoading}
                      />
                    )
                  }
                  {
                    props.listFilters?.includes('estado') && (
                      <FilterOption
                        options={state.statesListOpts}
                        value={state.selectedStateOpts}
                        onChange={(value) => { onChangeState(value); }}
                        multiple
                        placeholder={t('estado')}
                        disabled={state.isLoading || props.state.isLoading}
                      />
                    )
                  }
                  {
                    props.listFilters?.includes('cidade') && (
                      <FilterOption
                        options={state.citiesListFilterOpts}
                        value={state.selectedCityOpts}
                        onChange={(value) => { onChangeCity(value); }}
                        multiple
                        placeholder={t('cidade')}
                        disabled={state.isLoading || props.state.isLoading}
                      />
                    )
                  }
                  {
                    (props.listFilters?.includes('cliente') && state.clientsListOpts.length > 1) && (
                      <FilterOption
                        options={state.clientsFilterListOpts}
                        value={state.selectedClientOpts}
                        onChange={(value) => { onChangeClient(value); }}
                        multiple
                        placeholder={t('cliente')}
                        disabled={state.isLoading || props.state.isLoading}
                      />
                    )
                  }
                  {
                    props.listFilters?.includes('unidade') && (
                      <FilterOption
                        value={state.selectedUnitOpts}
                        options={state.unitsFilterOpts}
                        onChange={(value) => { state.selectedUnitOpts = value as any; render(); }}
                        multiple
                        placeholder={t('unidade')}
                        disabled={state.isLoading || props.state.isLoading}
                      />
                    )
                  }
                  {
                    props.listFilters?.includes('statusOperacao') && profile.manageAllClients && (
                      <FilterOption
                        value={props.informationsUnits.statusUnitsCheck}
                        options={[
                          { value: '0', name: 'Em instalação' },
                          { value: '1', name: 'Em operação' },
                          { value: '2', name: 'Todas' },
                        ]}
                        onChange={(value) => { handleClickChange(value);
                          props.render(); }}
                        placeholder="Status"
                        disabled={state.isLoading}
                        renderOption
                      />
                    )
                  }
                  {
                    props.listFilters?.includes('conexao') && (
                      <FilterOption
                        value={state.selectedConnectionOpts}
                        options={props.optionsConnetions || [
                          { value: 'ONLINE', name: t('online'), icon: <StatusIcon status="ONLINE" /> },
                          { value: 'LATE', name: t('late'), icon: <StatusIcon status="LATE" /> },
                          { value: 'OFFLINE', name: t('offline'), icon: <StatusIcon status="OFFLINE" /> },
                        ]}
                        onChange={(value) => { state.selectedConnectionOpts = value as any; render(); }}
                        multiple
                        placeholder={props.optionsConnetionsName || t('conexao')}
                        disabled={state.isLoading || props.state.isLoading}
                        renderOption
                      />
                    )
                  }
                  {
                    props.listFilters?.includes('status') && (
                      <>
                        {props.state.utilities[0] && props.state.utilities[0].APPLICATION === 'Nobreak' && (
                        <FilterOption
                          value={state.selectedStatusOpts}
                          options={[
                            { value: 'Rede Elétrica', name: 'Rede Elétrica' },
                            { value: 'Bateria', name: 'Bateria' },
                            { value: 'Desligado', name: 'Desligado' },
                          ]}
                          onChange={(value) => { state.selectedStatusOpts = value as any; render(); }}
                          multiple
                          placeholder="Status"
                          disabled={state.isLoading || props.state.isLoading}
                          renderOption
                        />
                        )}

                        {props.state.utilities[0] && props.state.utilities[0].APPLICATION === 'Iluminação' && (
                        <FilterOption
                          value={state.selectedStatusOpts}
                          options={[
                            { value: 1, name: 'Ligado' },
                            { value: 0, name: 'Desligado' },
                          ]}
                          onChange={(value) => { state.selectedStatusOpts = value as any; render(); }}
                          multiple
                          placeholder="Status"
                          disabled={state.isLoading || props.state.isLoading}
                          renderOption
                        />
                        )}
                      </>
                    )
                  }
                  {
                    props.listFilters?.includes('saude') && (
                      <FilterOption
                        value={state.selectedHealthOpts}
                        options={[
                          { value: '100', name: t('operandoCorretamente'), icon: <HealthIcon health="100" /> },
                          { value: '75', name: t('foraDeEspecificacao'), icon: <HealthIcon health="75" /> },
                          { value: '50', name: t('riscoIminente'), icon: <HealthIcon health="50" /> },
                          { value: '25', name: t('manutencaoUrgente'), icon: <HealthIcon health="25" /> },
                          { value: '2', name: t('equipamentoOffline'), icon: <HealthIcon health="2" /> },
                          { value: '4', name: t('maquinaDesativada'), icon: <HealthIcon health="4" /> },
                        ]}
                        onChange={(value) => { state.selectedHealthOpts = value as any; render(); }}
                        multiple
                        placeholder={t('saude')}
                        disabled={state.isLoading || props.state.isLoading}
                        renderOption
                      />
                    )
                  }
                  {
                    props.listFilters?.includes('temperatura') && (
                      <FilterOption
                        value={state.selectedTemperatureOpts}
                        options={[
                          { value: 'high', name: t('acima'), icon: <TempHigh /> },
                          { value: 'good', name: t('correta'), icon: <TempGreat /> },
                          { value: 'low', name: t('abaixo'), icon: <TempLow /> },
                          { value: 'no data', name: t('semInfo'), icon: <NoTempData /> },
                        ]}
                        onChange={(value) => { state.selectedTemperatureOpts = value as any; render(); }}
                        multiple
                        placeholder={t('temperatura')}
                        disabled={state.isLoading || props.state.isLoading}
                      />
                    )
                  }
                  {
                    props.listFilters?.includes('controle') && (
                      <FilterOption
                        value={state.selectedControlOpts}
                        options={[
                          { value: 'Automático', name: t('automatico') },
                          { value: 'Local', name: t('local') },
                          { value: 'Manual', name: t('manual') },
                          { value: 'Sem controle', name: t('semControle') },
                        ]}
                        onChange={(value) => { state.selectedControlOpts = value as any; render(); }}
                        multiple
                        placeholder={t('tipoControle')}
                        disabled={state.isLoading || props.state.isLoading}
                      />
                    )
                  }
                  {
                    props.listFilters?.includes('tipoNotificacao') && (
                      <FilterOption
                        value={state.selectedNotificationType}
                        options={state.typesNotificationFilterOpts}
                        onChange={(value) => { onChangeNotificationTypes(value); }}
                        multiple
                        placeholder={t('tipoNotificacao')}
                        disabled={state.isLoading || props.state.isLoading}
                      />
                    )
                  }
                  {
                    props.listFilters?.includes('subtipoNotificacao') && (
                      <FilterOption
                        value={state.selectedNotificationSubtype}
                        options={state.subtypesNotificationFilterOpts}
                        onChange={(value) => { state.selectedNotificationSubtype = value as any; render(); }}
                        multiple
                        placeholder="Subtipo"
                        disabled={state.isLoading || props.state.isLoading || !state.selectedNotificationType.length}
                      />
                    )
                  }
                  {
                    props.listFilters?.includes('destinatario') && (
                      <FilterOption
                        value={state.selectedNotificationDestinatary}
                        options={state.destinataryListOps}
                        onChange={(value) => { state.selectedNotificationDestinatary = value as any; render(); }}
                        multiple
                        placeholder="Destinatário"
                        disabled={state.isLoading || props.state.isLoading}
                      />
                    )
                  }
                  {
                    props.listFilters?.includes('modoEco') && (
                      <FilterOption
                        value={state.selectedModeEcoOpts}
                        options={[
                          { value: 'habilitado', name: t('habilitado') },
                          { value: 'desabilitado', name: t('desabilitado') },
                        ]}
                        onChange={(value) => { state.selectedModeEcoOpts = value as any; render(); }}
                        multiple
                        placeholder={t('modoEco')}
                        disabled={state.isLoading || props.state.isLoading}
                      />
                    )
                  }
                  {
                    props.listFilters?.includes('operacao') && (
                      <FilterOption
                        value={state.selectedOperationStatusOpts}
                        options={[
                          { value: 'Liberado', name: t('liberadoMin') },
                          { value: 'Bloqueado', name: t('bloqueadoMin') },
                          { value: 'Ventilação', name: t('ventilacao') },
                          { value: 'Eco-Mode', name: t('modoEco') },
                          { value: 'Aberto', name: t('aberto') },
                          { value: 'Fechado', name: t('fechado') },
                          { value: 'Sem status', name: t('semStatus') },
                        ]}
                        onChange={(value) => { state.selectedOperationStatusOpts = value as any; render(); }}
                        multiple
                        placeholder={t('operacao')}
                        disabled={state.isLoading || props.state.isLoading}
                      />
                    )
                  }
                  {
                    props.listFilters?.includes('analise') && (
                      <FilterOption
                        value={state.selectedAnalysisOpts}
                        options={[
                          { value: 'unidades', name: t('unidades') },
                        ]}
                        onChange={(value) => { state.selectedAnalysisOpts = value as any; render(); }}
                        placeholder={t('analisar')}
                        disabled
                      />
                    )
                  }
                  {
                    props.listFilters?.includes('dados') && (
                      <FilterOption
                        value={state.selectedDataTypeOpts}
                        options={[
                          { value: 'energia', name: t('energia') },
                        ]}
                        onChange={(value) => { state.selectedDataTypeOpts = value as any; render(); }}
                        placeholder={t('tipoDeDados')}
                        disabled
                      />
                    )
                  }
                  {
                    props.listFilters?.includes('periodo') && (
                      <div style={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'end',
                      }}
                      >
                        <FilterOption
                          value={state.selectedPeriodOpts}
                          options={[
                            { value: 'Ano', name: 'Ano' },
                            { value: 'Mês', name: 'Mês' },
                            { value: 'Semana', name: 'Semana' },
                            { value: 'Dia', name: 'Dia' },
                            { value: 'Flexível', name: 'Flexível' },
                            { value: ['Ano', 'Mês', 'Semana', 'Dia', 'Flexível'].includes(state.selectedPeriodOpts) ? '' : state.selectedPeriodOpts, name: ['Ano', 'Mês', 'Semana', 'Dia', 'Flexível'].includes(state.selectedPeriodOpts) ? '' : state.selectedPeriodOpts },
                          ]}
                          onChange={(value) => { state.selectedPeriodOpts = value as any; render(); }}
                          placeholder={t('periodo')}
                        />
                        <QuickSelection setDate={quickHandleChangeData} />
                      </div>
                    )
                  }
                  {
                    props.listFilters?.includes('data') && (

                    <div style={{
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'end',
                    }}
                    >
                      <ContentDate>
                        <DateLabel>{props?.isPeriodLabel ? t('periodo') : t('inicioOperacao')}</DateLabel>
                        {!props?.isPeriodLabel && <InfoIcon width="12px" data-tip data-for="startOperation" color="#BDBDBD" />}
                        <ReactTooltip
                          id="startOperation"
                          place="top"
                          effect="solid"
                        >

                          <HoverExportList>
                            {t('informacoesInicioOperacao')}
                          </HoverExportList>

                        </ReactTooltip>
                        <DateRangePicker
                          readOnly
                          disabled={props.state.isLoading}
                          startDate={props.informationsUnits.dates.dateStart}// momentPropTypes.momentObj or null,
                          startDateId="your_unique_start_date_id" // PropTypes.string.isRequired,
                          endDate={props.informationsUnits.dates.dateEnd}// momentPropTypes.momentObj or null,
                          endDateId="your_unique_end_date_id" // PropTypes.string.isRequired,
                          onDatesChange={({ startDate, endDate }) => {
                            if (startDate) { handleClickChange('dateStart', startDate); }
                            if (startDate !== props.informationsUnits.dates.dateStart) { handleClickChange('dateEnd', null); }
                            else {
                              handleClickChange('dateEnd', endDate);
                            }
                          }} // PropTypes.func.isRequired,
                          onFocusChange={(focused: 'endDate' | 'startDate' | null) => {
                            props.state.focusedInput = focused;
                            props.render();
                          }}
                          focusedInput={props.state.focusedInput}
                          noBorder
                          isOutsideRange={(d) => isOutsideRange(d)}
                          startDatePlaceholderText={t('dataInicial')}
                          endDatePlaceholderText={t('dataFinal')}
                        />
                      </ContentDate>
                      <QuickSelection isShortMode={props?.isPeriodLabel} setDate={quickChangeData} />
                    </div>

                    )
                  }

                  {
                    (props.listFilters?.includes('tipo') && (profile.viewAllClients || profile.permissions.isInstaller)) && (
                      <FilterOption
                        options={props.infoType ? props.infoType : [
                          { value: 'CLIENTS', name: t('deClientes') },
                          { value: 'N-COMIS', name: t('naoComissionados') }, // aqueles com DEV_ID default de firmware
                          { value: 'N-ASSOC', name: t('naoAssociados') }, // Aqueles que já tem DEV_ID próprio, mas não possuem cliente associado
                          { value: 'MANUFAC', name: t('comissionadosEmFabrica') }, // Aqueles que já tem DEV_ID próprio, e possuem cliente SERDIA
                          { value: 'D-TESTS', name: t('emTestes') }, // Aqueles que estão associados ao cliente DIEL ENERGIA LTDA.
                          { value: 'ALLDEVS', name: t('todos') },
                        ]}
                        value={state.selectedOwnershipFilter.value}
                        onChange={(value) => { onSelectedOwnership(value); }}
                        placeholder={t('tipo')}
                        disabled={state.isLoading || props.state.isLoading}
                      />
                    )
                  }
                  {
                    (props.listFilters?.includes('progDisp')) && (
                      <FilterOption
                        options={[
                          { value: 'programacao', name: t('programacao') },
                          { value: 'dispositivo', name: t('dispositivo') }, // aqueles com DEV_ID default de firmware
                        ]}
                        value={state.selectedInfoExib}
                        onChange={(value) => { state.selectedInfoExib = value; props.setProg!(state.selectedInfoExib); render(); }}
                        placeholder={t('informacoesExibidas')}
                        disabled={state.isLoading || props.state.isLoading}
                      />
                    )
                  }
                  {
                    (props.listFilters?.includes('id') && (
                      <div style={{ marginLeft: '16px', width: '221px', fontSize: '11px' }}>
                        <InputSearch
                          style={{ height: '55px', width: 219, fontSize: '11px' }}
                          id="search"
                          name="search"
                          label={t('idDoDispositivo')}
                          placeholder={t('digitar')}
                          value={state.selectedDevId}
                          onEnterKey={() => { setVariables(); props.onAply!(); }}
                          onChange={(id) => { state.selectedDevId = id; render(); }}
                          disabled={props.state.isLoading || state.isLoading}
                          filterStyle
                          noBorder
                        />
                      </div>
                    ))
                  }
                  {
                    (props.listFilters?.includes('search') && (
                      <div style={{
                        marginLeft: '16px', width: '221px', fontSize: '11px', position: 'relative',
                      }}
                      >
                        <InfoIcon
                          width="12px"
                          data-tip
                          data-for="search"
                          color="#BDBDBD"
                          style={{
                            position: 'absolute', top: 0, right: 0, zIndex: 2, marginRight: 5, marginTop: 5,
                          }}
                        />
                        <ReactTooltip
                          id="search"
                          place="top"
                          effect="solid"
                        >

                          <HoverExportList>
                            {t('pesquiseAmbientes')}
                          </HoverExportList>

                        </ReactTooltip>
                        <InputSearch
                          style={{ height: '55px', width: 219, fontSize: '11px' }}
                          id="search"
                          name="search"
                          label={props.searchLabel || t('buscar')}
                          placeholder={t('digitar')}
                          value={state.searchTerms}
                          onEnterKey={() => { setVariables(); props.onAply!(); }}
                          onChange={(term) => { state.searchTerms = term; render(); }}
                          disabled={props.state.isLoading || state.isLoading}
                          filterStyle
                          noBorder
                        />
                      </div>
                    ))
                  }
                </div>
                {
                    props.listFilters?.includes('dataUnica') && (
                      <ContentDate style={{ maxWidth: '220px' }}>
                        <DateLabel>{t('data')}</DateLabel>
                        <SingleDatePicker
                          disabled={props.state.isLoading}
                          date={state.selectedDateStart}
                          onDateChange={(value) => setState({ selectedDateStart: value, selectedDateEnd: value })}
                          focused={state.selectFocused}
                          onFocusChange={({ focused }) => setState({ selectFocused: focused })}
                          id="datepicker"
                          numberOfMonths={1}
                          isOutsideRange={(d) => !d.isBefore(state.tomorrow)}
                          placeholder={t('data')}
                        />

                        <StyledCalendarIcon color="#202370" />
                      </ContentDate>
                    )

                  }
                <div>
                  {
                    (state.showFilter)
                    && (
                    <CleanBtn onClick={() => { clearFilters(); setVariables(); props.onAply!(); }}>
                      {t('limparFiltrosCap')}
                    </CleanBtn>
                    )
                  }
                </div>
              </div>
              <Button
                style={{
                  width: '110px',
                  marginLeft: '20px',
                  marginTop: 3,
                  minWidth: '100px',
                }}
                type="button"
                variant={state.isLoading || props.state.isLoading ? 'disabled' : 'primary'}
                onClick={() => { setVariables(); props.onAply!(); }}
              >
                {state.buttonName}
              </Button>
            </Flex>
          )
        }
      </FiltersContainer>
      {state.showFilter && <div style={{ height: 10 }} />}
      <div
        style={{
          marginTop: 10,
          marginBottom: 20,
          display: 'flex',
          alignItems: 'center',
        }}
      >
        <ControlFilter style={{ marginTop: 1, width: '110px' }} onClick={() => { state.showFilter = !state.showFilter; render(); }}>
          <FilterIcon style={{ marginRight: 4 }} />
          {t('filtros')}
          {state.showFilter ? <ArrowDownIconV2 width="8" heigth="7" style={{ marginLeft: 4 }} /> : <ArrowUpIconV2 width="8" heigth="7" style={{ marginLeft: 4 }} />}
        </ControlFilter>
        {
          props.exportFunc && (
            <CustomFilter
              showFilter={state.showFilter}
              stateFilter={state}
              render={props.render}
              state={props.state}
              updateCheckInformations={props.updateCheckInformations}
              informationsUnits={props.informationsUnits}
              exportFunc={props.exportFunc}
              csvHeader={props.csvHeader}
              tab="devices"
            />
          )
        }
      </div>
      {state.showFilter && <div style={{ height: 10 }} />}

    </Flex>
  );
};

export const CustomFilter = (props : {
  showFilter: boolean,
  tab: string,
  state: any,
  updateCheckInformations?: (value: any, date?: Moment) => void,
  informationsUnits?: any,
  stateFilter: any,
  render: any,
  exportFunc: (() => Promise<void> | void) | undefined,
  csvHeader: {
    label: string;
    key: string;
  }[] | undefined
}): JSX.Element => {
  const csvLinkEl = useRef();
  const [profile] = useState(getUserProfile);

  function verifyParams() {
    if (props.state.isUnits && props.state.openExportList === false) {
      return openExportList();
    }
    if (props.exportFunc) {
      return props.exportFunc();
    }
    return getCsvData();
  }
  function openExportList() {
    try {
      props.state.openExportList = true;
      props.render();
    } catch (err) { console.log(err); toast.error(t('houveErro')); }
  }
  function isCompleteList() {
    try {
      handleClick('complete');
      props.render();
    } catch (err) { console.log(err); toast.error(t('houveErro')); }
  }
  function isCustomList() {
    try {
      handleClick('custom');
      props.render();
    } catch (err) { console.log(err); toast.error(t('houveErro')); }
  }
  function closeExportList() {
    try {
      props.state.openExportList = false;
      props.state.modeExportList = '';
      props.render();
    } catch (err) { console.log(err); toast.error(t('houveErro')); }
  }
  const handleClick = (value: string, date?: Moment) => {
    if (props.updateCheckInformations) {
      if (date) props.updateCheckInformations(value, date);
      else props.updateCheckInformations(value);
    }
  };

  const getCsvData = async () => {
    props.state.isLoading = true; props.render();
    const formatterCSV = props.state.filteredUtilities.map((dev) => ({
      CLIENT_NAME: dev.CLIENT_NAME || '-',
      STATE: dev.STATE_UF || '-',
      CITY_NAME: dev.CITY_NAME || '-',
      NAME: dev.NAME || '-',
      UNIT: dev.UNIT_NAME || '-',
      DAT_CODE: dev.DAT_CODE || '-',
      DMT_CODE: dev.DMT_CODE || '-',
      STATUS: dev.status || '-',
      averageDur: dev.averageDur || '-',
      autonon: dev.autonon || '-',
      connection: dev.connection || '-',
    }));

    props.stateFilter.csvData = formatterCSV;
    props.render();

    setTimeout(() => {
      (csvLinkEl as any).current.link.click();
      window.location.reload();
    }, 1000);
    props.state.isLoading = false; props.render();
  };

  useEffect(() => {
    props.state.filteredUtilities = props.state.utilities;
    props.render();
  }, []);

  return (
    <Flex
      style={{
        position: 'relative', transition: 'top 0.6s', top: 0,
      }}
      flexDirection="row"
      alignItems="center"
      justifyContent="flex-end"
      width="99%"
    >
      {
        props.tab === 'devices' && (
        <>
          <ExportBtn onClick={() => { verifyParams(); }}>
            <ExportIcon />
            <span style={{ marginLeft: 10 }}>
              {t('exportarListaCap')}
            </span>
            <CSVLink
              headers={props.csvHeader}
              data={props.stateFilter.csvData}
              separator=";"
              enclosingCharacter={"'"}
              filename={t('utilitario')}
              asyncOnClick
              ref={csvLinkEl}
            />
          </ExportBtn>
        </>
        )
      }
      {props.state.isUnits && props.state.openExportList && (
      <div style={{ zIndex: 3, position: 'sticky' }}>
        <ModalWindowStyled
          topBorder
          onClickOutside={() => closeExportList()}
        >
          <Card noPadding>
            <ContainerModal>
              <TitleModal>
                {t('exportarPlanilha')}
              </TitleModal>
              <SubtitleModal>
                {t('selecioneOpcaoDesejada')}
              </SubtitleModal>

              <Flex
                style={{
                  gap: '13px',
                  marginTop: '23px',
                  marginBottom: '23px',
                }}
              >
                {/* Complete */}
                <OptionExportList
                  style={{
                    backgroundColor: props.informationsUnits.modeExportList === 'complete' ? '#363BC4' : ' white',
                    color: props.informationsUnits.modeExportList === 'complete' ? 'white' : ' black',
                  }}
                  onClick={() => { isCompleteList(); }}
                >
                  <CompleteListIcon color={props.informationsUnits.modeExportList === 'complete' ? 'white' : '#363BC4'} />
                  <span>{t('planilhaCompleta')}</span>
                  <InfoIcon width="14px" data-tip data-for="complete" color="#BDBDBD" />
                  <ReactTooltip
                    id="complete"
                    place="top"
                    effect="solid"
                  >
                    <HoverExportList>
                      {t('informacoesPlanilhaCompleta')}
                    </HoverExportList>
                  </ReactTooltip>
                </OptionExportList>

                {/* Custom */}
                <OptionExportList
                  style={{
                    backgroundColor: props.informationsUnits.modeExportList === 'custom' ? '#363BC4' : ' white',
                    color: props.informationsUnits.modeExportList === 'custom' ? 'white' : ' #363BC4',
                  }}
                  onClick={() => { isCustomList(); }}
                >
                  <CustomListIcon color={props.informationsUnits.modeExportList === 'custom' ? 'white' : '#363BC4'} />

                  <span style={{ width: '135px' }}>
                    {t('planilhaCustomizada')}
                  </span>
                  <InfoIcon width="14px" data-tip data-for="custom" color="#BDBDBD" />

                  <ReactTooltip
                    id="custom"
                    place="top"
                    effect="solid"
                  >

                    <HoverExportList>
                      {t('informacoesPlanilhaCustomizada')}
                    </HoverExportList>

                  </ReactTooltip>
                </OptionExportList>
              </Flex>

              {props.informationsUnits.modeExportList === 'custom' && (
              <>

                <Flex
                  marginBottom="20px"
                  flexDirection="column"
                >
                  <span style={{ fontWeight: 'bolder', marginBottom: '16px' }}>
                    {t('informacoes')}
                    :
                  </span>
                  <Box
                    display="flex"
                    flexWrap="wrap"
                    justifyContent="space-between"
                  >
                    <ContainerCheckbox>
                      <Checkbox
                        checked={props.informationsUnits.informationsUnitsCheck.checkState}
                        onClick={() => {
                          handleClick('estado');
                        }}
                        size={16}
                      />
                      <span>
                        {t('estado')}
                      </span>
                    </ContainerCheckbox>
                    <ContainerCheckbox>
                      <Checkbox
                        checked={props.informationsUnits.informationsUnitsCheck.checkCity}
                        onClick={() => {
                          handleClick('cidade');
                        }}
                        size={16}
                      />
                      <span>
                        {t('cidade')}
                      </span>
                    </ContainerCheckbox>
                    <ContainerCheckbox>
                      <Checkbox
                        checked={props.informationsUnits.informationsUnitsCheck.checkUnit}
                        onClick={() => {
                          handleClick('unidade');
                        }}
                        size={16}
                      />
                      <span>
                        {t('unidade')}
                      </span>
                    </ContainerCheckbox>
                    <ContainerCheckbox>
                      <Checkbox
                        checked={props.informationsUnits.informationsUnitsCheck.checkMachines}
                        onClick={() => {
                          handleClick('maquinas');
                        }}
                        size={16}
                      />
                      <span>
                        {t('maquinas')}
                      </span>
                    </ContainerCheckbox>
                    <ContainerCheckbox>
                      <Checkbox
                        checked={props.informationsUnits.informationsUnitsCheck.checkEnvironments}
                        onClick={() => {
                          handleClick('ambientes');
                        }}
                        size={16}
                      />
                      <span>
                        {t('ambientes')}
                      </span>
                    </ContainerCheckbox>
                    <ContainerCheckbox>
                      <Checkbox
                        checked={props.informationsUnits.informationsUnitsCheck.checkUtilities}
                        onClick={() => {
                          props.updateCheckInformations !== undefined && props.updateCheckInformations('utilitarios');
                          props.render();
                        }}
                        size={16}
                      />
                      <span>
                        {t('utilitarios')}
                      </span>
                    </ContainerCheckbox>

                    {profile.manageAllClients && (
                    <ContainerCheckbox>
                      <Checkbox
                        checked={props.informationsUnits.informationsUnitsCheck.checkStartMonitoring}
                        onClick={() => {
                          props.updateCheckInformations !== undefined && props.updateCheckInformations('inicioMonitoramento');
                          props.render();
                        }}
                        size={16}
                      />
                      <span>
                        {t('inicioMonitoramento')}
                      </span>
                    </ContainerCheckbox>

                    )}
                    <ContainerCheckbox>
                      <Checkbox
                        checked={props.informationsUnits.informationsUnitsCheck.checkWaterDevices}
                        onClick={() => {
                          props.updateCheckInformations !== undefined && props.updateCheckInformations('dispositivosAgua');
                          props.render();
                        }}
                        size={16}
                      />
                      <span>
                        {t('dispositivosAgua')}
                      </span>
                    </ContainerCheckbox>
                    <ContainerCheckbox>
                      <Checkbox
                        checked={props.informationsUnits.informationsUnitsCheck.checkEnergyDevices}
                        onClick={() => {
                          props.updateCheckInformations !== undefined && props.updateCheckInformations('dispositivosEnergia');
                          props.render();
                        }}
                        size={16}
                      />
                      <span>
                        {t('dispositivosEnergia')}
                      </span>
                    </ContainerCheckbox>
                    <ContainerCheckbox>
                      <Checkbox
                        checked={props.informationsUnits.informationsUnitsCheck.checkAutomationDevices}
                        onClick={() => {
                          props.updateCheckInformations !== undefined && props.updateCheckInformations('dispositivosAutomacao');
                          props.render();
                        }}
                        size={16}
                      />
                      <span>
                        {t('dispositivosAutomacao')}
                      </span>
                    </ContainerCheckbox>
                    <ContainerCheckbox style={{ alignItems: 'flex-end' }}>
                      <Checkbox
                        checked={props.informationsUnits.informationsUnitsCheck.checkMonitoringDevices}
                        onClick={() => {
                          props.updateCheckInformations !== undefined && props.updateCheckInformations('dispositivosMonitoramento');
                          props.render();
                        }}
                        size={16}
                      />
                      <span>
                        {t('dispositivosMonitoramento')}
                      </span>
                    </ContainerCheckbox>

                    {profile.manageAllClients && (
                    <ContainerCheckbox>
                      <Checkbox
                        checked={props.informationsUnits.informationsUnitsCheck.checkStatus}
                        onClick={() => {
                          props.updateCheckInformations !== undefined && props.updateCheckInformations('status');
                          props.render();
                        }}
                        size={16}
                      />
                      <span>
                        {t('status')}
                      </span>
                    </ContainerCheckbox>
                    )}

                  </Box>

                </Flex>
              </>

              )}

              <Button
                variant={(props.state.isLoading || props.informationsUnits.modeExportList === '') ? 'disabled' : 'primary'}
                onClick={() => verifyParams()}
              >
                {t('exportar')}
              </Button>

            </ContainerModal>
            <CancelButton
              onClick={() => closeExportList()}
            >
              {t('cancelar')}
            </CancelButton>

          </Card>
        </ModalWindowStyled>
      </div>
      )}
    </Flex>
  );
};

const FilterOption = (props: {
  value: string | string[] | undefined
  placeholder: string,
  onChange: ((selectedValue: SelectedOptionValue | SelectedOptionValue[], selectedOption: SelectedOption | SelectedOption[], optionSnapshot: SelectSearchProps) => void) | undefined
  options: SelectSearchOption[],
  disabled?: boolean,
  multiple?: boolean,
  closeOnSelected?: boolean,
  renderOption?: boolean,
  type?: string,
  getSelectedDate?: (opt: any, state: any) => any,
  setOnChange?: (opt: any, data: any) => void,
  state?: any,
  setState?: any,
  render?: any
}) => {
  function renderOption(props, option, snapshot, className) {
    return (
      <button {...props} className={className} type="button">
        <div style={{
          display: 'flex', flexFlow: 'row nowrap', alignItems: 'center',
        }}
        >
          {option.icon}
          <span style={{ marginLeft: '10px' }}>{option.name}</span>
        </div>
      </button>
    );
  }
  return (
    <>
      {
        props.type && props.type === 'date'
          ? (
            <>
              <ContentDate
                style={{
                  marginLeft: '16px',
                  backgroundColor: 'white',
                  paddingTop: 5,
                }}
                key={props.placeholder}
              >
                <DateLabel>{props.placeholder}</DateLabel>
                <br />
                <SingleDatePicker
                  disabled={props.state.isLoading}
                  date={props.getSelectedDate!(props, props.state)}
                  onDateChange={(data) => {
                    props.setOnChange!(props, data);
                    props.onChange!(data, [], [] as any);
                    props.render();
                  }}
                  focused={props.state.focused.id === props.placeholder ? props.state.focused.focused : false}
                  onFocusChange={({ focused }) => {
                    props.setState({ focused: { id: props.placeholder, focused } });
                    props.render();
                  }}
                  numberOfMonths={1}
                  displayFormat="DD/MM/YYYY"
                  isOutsideRange={(d) => {
                    if (props.placeholder === t('fimOperacao') && props.state.dateValuePicker.startDate) {
                      return d.isBefore(props.state.dateValuePicker.startDate);
                    }
                    return d.isAfter(props.state.dateValuePicker.endDate);
                  }}
                  placeholder={props.placeholder}
                />
                <StyledCalendarIcon color="#202370" />
              </ContentDate>
            </>
          ) : (
            <SearchInput disabled={props.disabled} style={{ width: 'auto', marginBottom: 10, height: 57 }}>
              <div style={{
                width: '100%',
                paddingTop: 3,
                paddingBottom: 3,
                minWidth: '80px',
              }}
              >
                <Label style={{ opacity: props.disabled ? '0.4' : '1' }}>{props.placeholder}</Label>
                <SelectSearch
                  options={props.options}
                  value={props.value}
                  multiple={props.multiple}
                  closeOnSelect={props.closeOnSelected}
                  printOptions="on-focus"
                  search
                  filterOptions={fuzzySearch}
                  placeholder={props.placeholder}
                  // eslint-disable-next-line react/jsx-no-bind
                  onChange={props.onChange}
                  // onBlur={onFilterUnitBlur}
                  disabled={props.disabled}
                  renderOption={props.renderOption ? renderOption : undefined}
                />
              </div>
            </SearchInput>
          )
      }
    </>
  );
};
