import { Flex } from 'reflexbox';
import { Card, Loader, ModalLoading } from '../../../../../components';
import StatusCard from './components/StatusCard';
import MonitoringCard from './components/MonitoringCard';
import ChillerCard from './components/ChillerCard';
import { useStateVar } from '~/helpers/useStateVar';
import { useWebSocket } from '~/helpers/wsConnection';
import { apiCall } from '~/providers';
import { toast } from 'react-toastify';
import { useEffect } from 'react';
import { t } from 'i18next';
import { getCachedDevInfo, getCachedDevInfoSync } from '~/helpers/cachedStorage';
import i18n from 'i18n';
import moment from 'moment';
import ChillerParameters from './components/ChillerParameters';
import ChillerAlarms from './components/ChillerAlarms';

export default function DriChillerCarrierRealTime(props: { devId: string, varsList: any }): JSX.Element {
  const { devId } = props;
  const [state, render, setState] = useStateVar({
    driInterval: null as null | number,
    loading: true,
    devInfo: getCachedDevInfoSync(devId),
    timezoneOffset: 0,
    lastTelemetry: null as string | null,
    oldDate: false as boolean,
    status: null as string | null,
    width: window.innerWidth,
    tablet: window.innerWidth < 1145,
    mobile: window.innerWidth < 740,
    alarm: null as number | null,
    RSSI: null as number | null,
    circuitAParams: {
      CP_A1: { value: null as number | null, name: null as string | null, unitMeasurement: null as string | null },
      CP_A2: { value: null as number | null, name: null as string | null, unitMeasurement: null as string | null },
      CAPA_T: { value: null as number | null, name: null as string | null, unitMeasurement: null as string | null },
      DP_A: { value: null as number | null, name: null as string | null, unitMeasurement: null as string | null },
      SP_A: { value: null as number | null, name: null as string | null, unitMeasurement: null as string | null },
      SCT_A: { value: null as number | null, name: null as string | null, unitMeasurement: null as string | null },
      SST_A: { value: null as number | null, name: null as string | null, unitMeasurement: null as string | null },
      CPA1_OP: { value: null as number | null, name: null as string | null, unitMeasurement: null as string | null },
      CPA2_OP: { value: null as number | null, name: null as string | null, unitMeasurement: null as string | null },
      DOP_A1: { value: null as number | null, name: null as string | null, unitMeasurement: null as string | null },
      DOP_A2: { value: null as number | null, name: null as string | null, unitMeasurement: null as string | null },
      CPA1_DGT: { value: null as number | null, name: null as string | null, unitMeasurement: null as string | null },
      CPA2_DGT: { value: null as number | null, name: null as string | null, unitMeasurement: null as string | null },
      EXV_A: { value: null as number | null, name: null as string | null, unitMeasurement: null as string | null },
      HR_CP_A1: { value: null as number | null, name: null as string | null, unitMeasurement: null as string | null },
      HR_CP_A2: { value: null as number | null, name: null as string | null, unitMeasurement: null as string | null },
      CPA1_TMP: { value: null as number | null, name: null as string | null, unitMeasurement: null as string | null },
      CPA2_TMP: { value: null as number | null, name: null as string | null, unitMeasurement: null as string | null },
      CPA1_CUR: { value: null as number | null, name: null as string | null, unitMeasurement: null as string | null },
      CPA2_CUR: { value: null as number | null, name: null as string | null, unitMeasurement: null as string | null },
    },
    circuitBParams: {
      CP_B1: { value: null as number | null, name: null as string | null, unitMeasurement: null as string | null },
      CP_B2: { value: null as number | null, name: null as string | null, unitMeasurement: null as string | null },
      CAPB_T: { value: null as number | null, name: null as string | null, unitMeasurement: null as string | null },
      DP_B: { value: null as number | null, name: null as string | null, unitMeasurement: null as string | null },
      SP_B: { value: null as number | null, name: null as string | null, unitMeasurement: null as string | null },
      SCT_B: { value: null as number | null, name: null as string | null, unitMeasurement: null as string | null },
      SST_B: { value: null as number | null, name: null as string | null, unitMeasurement: null as string | null },
      CPB1_OP: { value: null as number | null, name: null as string | null, unitMeasurement: null as string | null },
      CPB2_OP: { value: null as number | null, name: null as string | null, unitMeasurement: null as string | null },
      DOP_B1: { value: null as number | null, name: null as string | null, unitMeasurement: null as string | null },
      DOP_B2: { value: null as number | null, name: null as string | null, unitMeasurement: null as string | null },
      CPB1_DGT: { value: null as number | null, name: null as string | null, unitMeasurement: null as string | null },
      CPB2_DGT: { value: null as number | null, name: null as string | null, unitMeasurement: null as string | null },
      EXV_B: { value: null as number | null, name: null as string | null, unitMeasurement: null as string | null },
      HR_CP_B1: { value: null as number | null, name: null as string | null, unitMeasurement: null as string | null },
      HR_CP_B2: { value: null as number | null, name: null as string | null, unitMeasurement: null as string | null },
      CPB1_TMP: { value: null as number | null, name: null as string | null, unitMeasurement: null as string | null },
      CPB2_TMP: { value: null as number | null, name: null as string | null, unitMeasurement: null as string | null },
      CPB1_CUR: { value: null as number | null, name: null as string | null, unitMeasurement: null as string | null },
      CPB2_CUR: { value: null as number | null, name: null as string | null, unitMeasurement: null as string | null },
    },
    generalParams: {
      CHIL_S_S: { value: null as number | null, name: null as string | null, unitMeasurement: null as string | null },
      CAP_T: { value: null as number | null, name: null as string | null, unitMeasurement: null as string | null },
      DEM_LIM: { value: null as number | null, name: null as string | null, unitMeasurement: null as string | null },
      LAG_LIM: { value: null as number | null, name: null as string | null, unitMeasurement: null as string | null },
      SP: { value: null as number | null, name: null as string | null, unitMeasurement: null as string | null },
      CTRL_PNT: { value: null as number | null, name: null as string | null, unitMeasurement: null as string | null },
      EMSTOP: { value: null as number | null, name: null as string | null, unitMeasurement: null as string | null },
      COND_LWT: { value: null as number | null, name: null as string | null, unitMeasurement: null as string | null },
      COND_EWT: { value: null as number | null, name: null as string | null, unitMeasurement: null as string | null },
      COOL_LWT: { value: null as number | null, name: null as string | null, unitMeasurement: null as string | null },
      COOL_EWT: { value: null as number | null, name: null as string | null, unitMeasurement: null as string | null },
      COND_SP: { value: null as number | null, name: null as string | null, unitMeasurement: null as string | null },
      CHIL_OCC: { value: null as number | null, name: null as string | null, unitMeasurement: null as string | null },
      STATUS: { value: null as number | null, name: null as string | null, unitMeasurement: null as string | null },
    },
    alarmParams: {
      alarm_1: null as number | null,
      alarm_2: null as number | null,
      alarm_3: null as number | null,
      alarm_4: null as number | null,
      alarm_5: null as number | null,
    },
  });

  moment.locale(i18n.language === 'pt' ? 'pt-BR' : 'en');

  const updateDimensions = () => {
    state.width = window.innerWidth;
    if (window.innerWidth < 1145) {
      state.tablet = true;
    } else {
      state.tablet = false;
    }
    if (window.innerWidth < 580) {
      state.mobile = true;
    } else {
      state.mobile = false;
    }
    render();
  };

  useEffect(() => {
    window.addEventListener('resize', updateDimensions);
    return () => window.removeEventListener('resize', updateDimensions);
  }, []);

  useEffect(() => {
    Promise.resolve().then(async () => {
      try {
        setState({ loading: true });
        // @ts-ignore
        const devInfo = (await getCachedDevInfo(devId, { forceFresh: true }))!;
        updateInfoParams();

        await apiCall('/get-timezone-offset-by-devId', { devId }).then((tzOffset) => {
          if (tzOffset != null) state.timezoneOffset = tzOffset;
        });

        if (devInfo.lastMessageTS) state.lastTelemetry = devInfo.lastMessageTS;
        state.devInfo = devInfo;
        render();
        state.driInterval = devInfo.dri.varsCfg.driConfigs.find((cfg) => cfg.protocol === 'interval')?.value;
        if (state.driInterval) updateTimeReceiveTelemetries(devId, 10);
      } catch (err) {
        toast.error(t('erro')); console.error(err);
        setState({ loading: false });
      }
    });
  }, []);

  function updateInfoParams() {
    props?.varsList?.forEach((param) => {
      if (state.circuitAParams[param.inputRow.Alias]) {
        state.circuitAParams[param.inputRow.Alias].unitMeasurement = param.inputRow.Unidade;
      } else if (state.circuitBParams[param.inputRow.Alias]) {
        state.circuitBParams[param.inputRow.Alias].unitMeasurement = param.inputRow.Unidade;
      } else if (state.generalParams[param.inputRow.Alias]) {
        state.generalParams[param.inputRow.Alias].unitMeasurement = param.inputRow.Unidade;
      }
    });
  }

  function checkLastTelemetry(lastTelemetry: string | null) {
    if (lastTelemetry) {
      const dateLastTelemetry = new Date(lastTelemetry);
      const dateCurrent = new Date();
      const dateDifference = dateCurrent.getTime() - dateLastTelemetry.getTime();

      if (dateDifference > 60000) {
        state.oldDate = true;
      } else {
        state.oldDate = false;
      }
    }
  }

  const verifyAndUpdateDataStatus = (payload) => {
    if (payload.data.status) state.status = payload.data.status;
    if (payload.data.timestamp || payload.data.deviceTimestamp) {
      const timestamp = payload.data.timestamp || payload.data.deviceTimestamp;
      const data = moment(timestamp);
      const dateWithOffset = data.utcOffset(state.timezoneOffset);
      payload.data.timestamp = dateWithOffset.format('YYYY-MM-DDTHH:mm:ss');
      state.lastTelemetry = payload.data.timestamp;
      state.RSSI = payload.data.RSSI;
      checkLastTelemetry(state.lastTelemetry);
    }
  };

  const roundOnePlace = (value) => (value || value === 0 ? Math.round(value * 10) / 10 : null);

  const updateChillerCarrierTelemetry = (payload) => {
    if (state.status === 'ONLINE' && !state.oldDate) {
      // Os parâmetros de alarme são em ordem crescente de chegada, o alarm_1 é o mais recente, o alarm_5 é o mais antigo
      state.alarm = roundOnePlace(payload.data.ALM);
      state.alarmParams.alarm_1 = roundOnePlace(payload.data.alarm_1);
      state.alarmParams.alarm_2 = roundOnePlace(payload.data.alarm_2);
      state.alarmParams.alarm_3 = roundOnePlace(payload.data.alarm_3);
      state.alarmParams.alarm_4 = roundOnePlace(payload.data.alarm_4);
      state.alarmParams.alarm_5 = roundOnePlace(payload.data.alarm_5);

      state.circuitAParams.CP_A1.value = roundOnePlace(payload.data.CP_A1);
      state.circuitAParams.CP_A2.value = roundOnePlace(payload.data.CP_A2);
      state.circuitAParams.CAPA_T.value = roundOnePlace(payload.data.CAPA_T);
      state.circuitAParams.DP_A.value = roundOnePlace(payload.data.DP_A);
      state.circuitAParams.SP_A.value = roundOnePlace(payload.data.SP_A);
      state.circuitAParams.SCT_A.value = roundOnePlace(payload.data.SCT_A);
      state.circuitAParams.SST_A.value = roundOnePlace(payload.data.SST_A);
      state.circuitAParams.CPA1_OP.value = roundOnePlace(payload.data.CPA1_OP);
      state.circuitAParams.CPA2_OP.value = roundOnePlace(payload.data.CPA2_OP);
      state.circuitAParams.DOP_A1.value = roundOnePlace(payload.data.DOP_A1);
      state.circuitAParams.DOP_A2.value = roundOnePlace(payload.data.DOP_A2);
      state.circuitAParams.CPA1_DGT.value = roundOnePlace(payload.data.CPA1_DGT);
      state.circuitAParams.CPA2_DGT.value = roundOnePlace(payload.data.CPA2_DGT);
      state.circuitAParams.EXV_A.value = roundOnePlace(payload.data.EXV_A);
      state.circuitAParams.HR_CP_A1.value = roundOnePlace(payload.data.HR_CP_A1);
      state.circuitAParams.HR_CP_A2.value = roundOnePlace(payload.data.HR_CP_A2);
      state.circuitAParams.CPA1_TMP.value = roundOnePlace(payload.data.CPA1_TMP);
      state.circuitAParams.CPA2_TMP.value = roundOnePlace(payload.data.CPA2_TMP);
      state.circuitAParams.CPA1_CUR.value = roundOnePlace(payload.data.CPA1_CUR);
      state.circuitAParams.CPA2_CUR.value = roundOnePlace(payload.data.CPA2_CUR);

      state.circuitBParams.CP_B1.value = roundOnePlace(payload.data.CP_B1);
      state.circuitBParams.CP_B2.value = roundOnePlace(payload.data.CP_B2);
      state.circuitBParams.CAPB_T.value = roundOnePlace(payload.data.CAPB_T);
      state.circuitBParams.DP_B.value = roundOnePlace(payload.data.DP_B);
      state.circuitBParams.SP_B.value = roundOnePlace(payload.data.SP_B);
      state.circuitBParams.SCT_B.value = roundOnePlace(payload.data.SCT_B);
      state.circuitBParams.SST_B.value = roundOnePlace(payload.data.SST_B);
      state.circuitBParams.CPB1_OP.value = roundOnePlace(payload.data.CPB1_OP);
      state.circuitBParams.CPB2_OP.value = roundOnePlace(payload.data.CPB2_OP);
      state.circuitBParams.DOP_B1.value = roundOnePlace(payload.data.DOP_B1);
      state.circuitBParams.DOP_B2.value = roundOnePlace(payload.data.DOP_B2);
      state.circuitBParams.CPB1_DGT.value = roundOnePlace(payload.data.CPB1_DGT);
      state.circuitBParams.CPB2_DGT.value = roundOnePlace(payload.data.CPB2_DGT);
      state.circuitBParams.EXV_B.value = roundOnePlace(payload.data.EXV_B);
      state.circuitBParams.HR_CP_B1.value = roundOnePlace(payload.data.HR_CP_B1);
      state.circuitBParams.HR_CP_B2.value = roundOnePlace(payload.data.HR_CP_B2);
      state.circuitBParams.CPB1_TMP.value = roundOnePlace(payload.data.CPB1_TMP);
      state.circuitBParams.CPB2_TMP.value = roundOnePlace(payload.data.CPB2_TMP);
      state.circuitBParams.CPB1_CUR.value = roundOnePlace(payload.data.CPB1_CUR);
      state.circuitBParams.CPB2_CUR.value = roundOnePlace(payload.data.CPB2_CUR);

      state.generalParams.CHIL_S_S.value = roundOnePlace(payload.data.CHIL_S_S);
      state.generalParams.CHIL_S_S.value = roundOnePlace(payload.data.CHIL_S_S);
      state.generalParams.CAP_T.value = roundOnePlace(payload.data.CAP_T);
      state.generalParams.DEM_LIM.value = roundOnePlace(payload.data.DEM_LIM);
      state.generalParams.LAG_LIM.value = roundOnePlace(payload.data.LAG_LIM);
      state.generalParams.SP.value = roundOnePlace(payload.data.SP);
      state.generalParams.CTRL_PNT.value = roundOnePlace(payload.data.CTRL_PNT);
      state.generalParams.EMSTOP.value = roundOnePlace(payload.data.EMSTOP);
      state.generalParams.COND_LWT.value = roundOnePlace(payload.data.COND_LWT);
      state.generalParams.COND_EWT.value = roundOnePlace(payload.data.COND_EWT);
      state.generalParams.COOL_LWT.value = roundOnePlace(payload.data.COOL_LWT);
      state.generalParams.COOL_EWT.value = roundOnePlace(payload.data.COOL_EWT);
      state.generalParams.COND_SP.value = roundOnePlace(payload.data.COND_SP);
      state.generalParams.CHIL_OCC.value = roundOnePlace(payload.data.CHIL_OCC);
      state.generalParams.STATUS.value = roundOnePlace(payload.data.STATUS);
    }
  };

  useWebSocket(onWsOpen, onWsMessage, beforeWsClose);

  function onWsOpen(wsConn) {
    wsConn.send({ type: 'driSubscribeRealTime', data: { DRI_ID: devId } });
  }

  function onWsMessage(response) {
    if (response && response.type === 'driTelemetry' && response.data.dev_id === devId) {
      verifyAndUpdateDataStatus(response);
      updateChillerCarrierTelemetry(response);
      render();
    }
  }

  function beforeWsClose(wsConn) {
    wsConn.send({ type: 'driUnsubscribeRealTime' });
    if (state.driInterval) updateTimeReceiveTelemetries(devId, state.driInterval);
  }

  async function updateTimeReceiveTelemetries(DRI_ID: string, interval: number) {
    try {
      await apiCall('/dri/update-time-send-config', {
        DRI_ID,
        VALUE: interval,
      });
    } catch (err) {
      console.log(err);
      toast.error(t('erroAtualizarTempoReceberTelemetrias'));
    }
  }

  return (
    <>
      <Card overflowHidden style={{ marginTop: '10px', marginBottom: '10px' }}>
        <Flex width="100%" flexDirection="column" justifyContent="center" alignItems="center">
          <Flex width="100%" flexWrap="wrap-reverse" flexDirection="row" alignItems="center" marginBottom="20px" justifyContent="center">
            <StatusCard
              totalCapacity={state.generalParams.CAP_T.value ?? 0}
              geralStatus={state.generalParams.STATUS.value}
              alarm={state.alarm}
              emergencyStop={state.generalParams.EMSTOP.value}
              tablet={state.tablet}
              mobile={state.mobile}
            />
            {!state.tablet && <div style={{ width: '25px' }} />}
            {state.tablet && <div style={{ height: '15px', width: '100%' }} />}

            <MonitoringCard
              status={state.status}
              lastTelemetry={(state.lastTelemetry && moment(state.lastTelemetry).format('lll')) || t('semInformacao')}
              lteNetwork={state.devInfo?.LTE_NETWORK}
              lteRSRP={state.devInfo?.LTE_RSRP}
              RSSI={state.RSSI}
              tablet={state.tablet}
              mobile={state.mobile}
            />
          </Flex>
          <ChillerCard
            compressorA1={state.circuitAParams.CP_A1.value}
            compressorA2={state.circuitAParams.CP_A2.value}
            compressorB1={state.circuitBParams.CP_B1.value}
            capacityCA={state.circuitAParams.CAPA_T.value}
            capacityCB={state.circuitBParams.CAPB_T.value}
            circuitSucA={state.circuitAParams.SP_A.value}
            circuitSucB={state.circuitAParams.SP_A.value}
            circuitDescB={state.circuitBParams.DP_B.value}
            circuitDescA={state.circuitAParams.DP_A.value}
            compressorOleoA1={state.circuitAParams.CPA1_OP.value}
            compressorOleoA2={state.circuitAParams.CPA2_OP.value}
            compressorOleoB1={state.circuitBParams.CPB1_OP.value}
            evaporatorSP={state.generalParams.SP.value}
            evaporatorInputTemp={state.generalParams.COOL_EWT.value}
            evaporatorOutputTemp={state.generalParams.COOL_LWT.value}
            condenserInputTemp={state.generalParams.COND_EWT.value}
            condenserOutputTemp={state.generalParams.COND_LWT.value}
            alarm={state.alarm}
            mobile={state.mobile}
            tablet={state.tablet}
          />
        </Flex>
      </Card>
      <ChillerParameters circuitAParams={state.circuitAParams} circuitBParams={state.circuitBParams} generalParams={state.generalParams} />
      <ChillerAlarms alarmParams={state.alarmParams} driId={devId} />
    </>
  );
}
