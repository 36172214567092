import {
  useState, useEffect, useCallback, useRef,
} from 'react';

import { useDispatch } from 'react-redux';
import { withRouter } from 'react-router-dom';

import { getUserProfile } from '../../helpers/userProfile';

import {
  Container,
  UserOptions,
  Welcome,
  Expand,
  List,
  Item,
  ItemTitle,
} from './styles';

const HeaderComp = ({ history }): JSX.Element => {
  const dispatch = useDispatch();
  const [focus, setFocus] = useState(false);
  const refContainer = useRef<any>(null);
  const [profile] = useState(getUserProfile);
  const me = profile.fullName;
  const { user } = profile;
  const hasToken = !!localStorage.getItem('@diel:token');

  const handleClickOutside = useCallback((event) => {
    if (refContainer.current && !refContainer.current.contains(event.target)) {
      setFocus(false);
    }
  }, []);

  const logout = () => {
    localStorage.clear();
    dispatch({ type: 'RESET_DATA' });
    history.push('/login');
  };

  useEffect(() => {
    focus
      ? document.addEventListener('mousedown', handleClickOutside)
      : document.removeEventListener('mousedown', handleClickOutside);
  }, [focus, handleClickOutside]);

  return (
    <Container>
      {hasToken && (
        <UserOptions ref={refContainer} onClick={() => setFocus(!focus)}>
          <Welcome>
            Bem vindo,
            {' '}
            {me || user || ''}
          </Welcome>
          <Expand expanded={focus} />
          {focus && (
            <List>
              <Item onClick={() => history.push('/configuracoes')}>
                <ItemTitle>Configurações</ItemTitle>
              </Item>
              <Item onClick={logout}>
                <ItemTitle>Sair</ItemTitle>
              </Item>
            </List>
          )}
        </UserOptions>
      )}
    </Container>
  );
};

const Header = withRouter(HeaderComp);

export { Header };
