import { useEffect } from 'react';

import moment from 'moment';
import { SingleDatePicker } from 'react-dates';
import Plot from 'react-plotly.js';
import { toast } from 'react-toastify';
import i18n from '~/i18n';
import {
  Loader,
} from 'components';
import {
  AxisInfo,
  ChartLineNumber,
  ChartLineSteps,
  fillDynamicValues,
  fillMaxMin,
  fillSteps,
} from 'helpers/axisCalc';
import { useStateVar } from 'helpers/useStateVar';
import { apiCall } from 'providers';

import 'react-datepicker/dist/react-datepicker.css';
import { ContentDate, Label, StyledCalendarIcon } from './styles';
import { useTranslation } from 'react-i18next';

export function OxynHistory(props: { integrId: string }): JSX.Element {
  const { t } = useTranslation();
  moment.locale(i18n.language === 'pt' ? 'pt-BR' : 'en');
  const { integrId: equipFolder } = props;
  const [state, render, setState] = useStateVar({
    loading: true,
    chartData: {
      vars: null as null|{
        name: string;
        x: (null|number)[];
        y: (null|number)[];
        type: string; // 'scatter'
        mode: string; // 'lines'
        line?: {
          color: string;
          width: number;
          dash?: string; // dot, dashdot
        };
        maxY?: null|number;
        minY?: null|number;
        y_orig?: (null|number|string)[];
        steps?: {
          y_orig: (null|number|string);
          y_chart: (null|number);
          label: string;
        }[];
      }[],
      leftLimits: [-1, 0, 50],
      leftTicks: [-1, 0, 50],
      leftTicksLabels: ['-1', '0', '50'],
    },
    focused: false,
    date: moment().subtract(1, 'days'),
    tomorrow: moment(moment().add(1, 'days').format('YYYY-MM-DD')),
  });

  useEffect(() => {
    state.chartData.vars = null;
    Promise.resolve().then(async () => {
      try {
        if (state.date) {
          setState({ loading: true });
          const { list: varsList } = await apiCall('/oxyn-get-machine-charts-v2', {
            machine: equipFolder,
            day: moment(state.date).format('YYYY-MM-DD'),
          });
          varsList.sort((a, b) => {
            if (a.name > b.name) return 1;
            if (a.name < b.name) return -1;
            return 0;
          });
          state.chartData.vars = [];
          const linesCont = [] as ChartLineNumber[];
          const linesDisc = [] as ChartLineSteps[];
          for (const row of varsList) {
            if (row.name.includes('Horímetro')) continue;
            if (row.name.includes('Partidas')) continue;
            if (row.name.includes('Umidade')) continue;
            if (row.name.includes('Status')) {
              const line: ChartLineSteps = {
                name: row.name,
                x: row.x,
                y_orig: row.y.map((v) => ((v === 0) ? t('desligadoMin') : (v === 2) ? t('ligadoMin') : (v && String(v)))),
                y: row.y,
                steps: [],
              };
              fillSteps(line);
              linesDisc.push(line);
            } else {
              const line: ChartLineNumber = {
                name: row.name,
                x: row.x,
                y: row.y,
                maxY: null,
                minY: null,
              };
              fillMaxMin(line);
              linesCont.push(line);
            }
          }
          const axisInfo: AxisInfo = { left: null };
          fillDynamicValues(axisInfo, linesCont, linesDisc);
          for (const line of linesCont) {
            state.chartData.vars.push({ ...line, type: 'scatter', mode: 'lines' });
          }
          for (const line of linesDisc) {
            state.chartData.vars.push({ ...line, type: 'scatter', mode: 'lines' });
          }
          if (axisInfo.left) {
            state.chartData.leftLimits = axisInfo.left.range;
            state.chartData.leftTicks = axisInfo.left.tickvals;
            state.chartData.leftTicksLabels = axisInfo.left.ticktext;
          }
        }
      } catch (err) { toast.error(t('erro')); console.error(err); }
      setState({ loading: false });
    });
  }, [state.date]);

  return (
    <>
      {(state.loading) && <Loader />}
      {(!state.loading)
        && (
        <div>
          <div style={{ display: 'flex', flexWrap: 'wrap' }}>
            <ContentDate>
              <Label>{t('Data')}</Label>
              <SingleDatePicker
                disabled={state.loading}
                date={state.date}
                onDateChange={(value) => setState({ date: value })}
                focused={state.focused}
                onFocusChange={({ focused }) => { state.focused = focused; render(); }}
                id="datepicker"
                numberOfMonths={1}
                isOutsideRange={(d) => !d.isBefore(state.tomorrow)}
              />
              <StyledCalendarIcon color="#202370" />
            </ContentDate>
          </div>
          {(state.chartData.vars) && (
            <Plot
              data={state.chartData.vars}
              layout={{
                height: 700,
                showlegend: true,
                title: null,
                margin: {
                  l: 80, r: 20, b: 20, t: 20, pad: 4,
                },
                xaxis: {
                  title: null,
                  showgrid: true,
                  zeroline: false,
                  showline: true,
                  range: [0, 24],
                  tickvals: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24],
                },
                yaxis: {
                  title: null,
                  showgrid: true,
                  zeroline: false,
                  showline: true,
                  range: state.chartData.leftLimits,
                  tickvals: state.chartData.leftTicks,
                  ticktext: state.chartData.leftTicksLabels,
                },
              }}
              config={{ responsive: true }}
              style={{ width: '100%' }}
            />
          )}
        </div>
        )}
    </>
  );
}
