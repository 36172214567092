import SelectSearch, { fuzzySearch } from 'react-select-search';
import {
  CustomInput,
  Label,
  IconWrapper,
  PlaceholderWrapper,
} from './styles';
import { Input } from 'components/NewInputs/Default';
import { ToggleSwitchMini } from '../ToggleSwitch';
import {
  useState,
  useEffect,
} from 'react';
import { useStateVar } from 'helpers/useStateVar';
import { Flex } from 'reflexbox';
import {
  ChipIcon,
  LayersIcon,
  TemperatureIcon,
} from '../../icons';
import { useForm } from 'react-hook-form';

interface ComponentProps {
    onHandleChange,
    unitsOpt: {
      value: number
      name: string
    }[],
    comboOpts: {
      fluids: { value: number, name: string, id: string, }[],
      types: { value: number, name: string, id: string, }[],
      brands: { value: number, name: string, id: string, }[],
      applics: { value: number, name: string, id: string, }[],
      roles: { value: number, name: string }[],
    },
    machine: {
      groupId: number|null,
      name: string|null,
      unitId: number|null,
      unitName: string|null,
      brandName: string|null,
      type: string|null,
      model: string|null,
      refrigerationCapacity: string|null,
      refrigerationFluid: string|null,
      applic: string|null,
      ratedPower: string | null,
      installationDate: string|null,
      automationDevId: string|null,
      capacityMeasurementUnit: string|null,
      refDUTId: string | null
      assetsSumRatedPower: string | null,
    },
    registerParente,
}

type Inputs = {
  name: string|null,
  model: string|null,
  refrigerationCapacity: string|null,
  capacityMeasurementUnit: string|null,
  ratedPower: string | null,
  installationDate: string|null,
  automationDevId: string|null,
  refDUTId: string | null,
};

const formValidators = {
  name: {
    message: 'O campo deve ter entre 3 e 250 caracteres.',
    required: true,
    validate: (value) => {
      if (value) {
        return (value.length < 250);
      } return true;
    },
  },
  model: {
    required: false,
  },
  refrigerationCapacity: {
    required: false,
  },
  capacityMeasurementUnit: {
    required: false,
  },
  refrigerationFluid: {
    required: false,
  },
  applic: {
    required: false,
  },
  ratedPower: {
    required: false,
  },
  installationDate: {
    required: false,
  },
  automationDevId: {
    required: false,
  },
  refDUTId: {
    required: false,
  },
};

export const FormMachine = (props: ComponentProps): JSX.Element => {
  const [state] = useStateVar({
    isLoading: false as boolean,
  });

  const [switchCapacity] = useState(props.machine.capacityMeasurementUnit && props.machine.capacityMeasurementUnit.startsWith('BTU/h'));
  const [switchAutomation, setSwitchAutomation] = useState(props.machine.automationDevId || props.machine.groupId == null);
  const [switchRefDut, setSwitchRefDut] = useState(props.machine.refDUTId || props.machine.groupId == null);

  const [formData, render] = useStateVar({
    groupId: props.machine.groupId,
    name: props.machine.name,
    unitId: props.machine.unitId,
    unitName: props.machine.unitName,
    brandName: props.machine.brandName,
    brandValue: props.machine.brandName ? (props.comboOpts.brands.find((brand) => brand.id === props.machine.brandName))?.value : null as number|null,
    type: props.machine.type,
    typeValue: props.machine.type ? (props.comboOpts.types.find((type) => type.id === props.machine.type))?.value : null as number|null,
    model: props.machine.model,
    refrigerationCapacity: props.machine.refrigerationCapacity,
    refrigerationFluid: props.machine.refrigerationFluid,
    refrigerationFluidValue: props.machine.refrigerationFluid ? (props.comboOpts.fluids.find((fluid) => fluid.id === props.machine.refrigerationFluid))?.value : null as number|null,
    applic: props.machine.applic,
    applicValue: props.machine.applic ? (props.comboOpts.applics.find((applic) => applic.id === props.machine.applic))?.value : null as number|null,
    ratedPower: props.machine.ratedPower,
    installationDate: props.machine.installationDate,
    automationDevId: props.machine.automationDevId,
    capacityMeasurementUnit: props.machine.capacityMeasurementUnit || 'TR',
    refDUTId: props.machine.refDUTId,
    assetsSumRatedPower: props.machine.assetsSumRatedPower,
  });

  const isEdit = !!formData.groupId;

  useEffect(() => {
    updateMachineData();
  }, []);

  const updateMachineData = () => {
    state.isLoading = true;
    setValue('name', formData.name);
    setValue('model', formData.model);
    setValue('refrigerationCapacity', formData.refrigerationCapacity);
    setValue('capacityMeasurementUnit', formData.capacityMeasurementUnit);
    setValue('ratedPower', formData.ratedPower);
    setValue('installationDate', formData.installationDate);
    setValue('automationDevId', formData.automationDevId);
    setValue('refDUTId', formData.refDUTId);

    state.isLoading = false;
  };

  const {
    register, setValue, watch, formState: { errors },
  } = useForm<Inputs>({
    mode: 'all',
  });

  function onFilterUnitChange(unitId) {
    formData.unitId = unitId;
    const unitAux = props.unitsOpt.find((unit) => unit.value === unitId);
    formData.unitName = unitAux?.name || null;
    props.onHandleChange(formData);
    render();
  }

  function onFilterTypeChange(typeId) {
    const typeAux = props.comboOpts.types.find((type) => type.value === typeId);
    formData.type = typeAux?.id || null;
    formData.typeValue = typeId;
    props.onHandleChange(formData);
    render();
  }

  function onFilterBrandChange(brandId) {
    const brandAux = props.comboOpts.brands.find((brand) => brand.value === brandId);
    formData.brandName = brandAux?.id || null;
    formData.brandValue = brandId;
    props.onHandleChange(formData);
    render();
  }

  function maskInput(value: string) {
    value = value.replace(/\D/g, '');
    value = value.replace(/(\d{2})(\d)/, '$1/$2');
    value = value.replace(/(\d{2})(\d)/, '$1/$2');
    value = value.replace(/(\d{2})(\d{2})$/, '$1$2');

    return value;
  }

  function onFilterRefrigerationFluidChange(fluidId) {
    const fluidAux = props.comboOpts.fluids.find((fluid) => fluid.value === fluidId);
    formData.refrigerationFluid = fluidAux?.id || null;
    formData.refrigerationFluidValue = fluidId;
    props.onHandleChange(formData);
    render();
  }

  function onFilterApplicChange(applicId) {
    const applicAux = props.comboOpts.applics.find((applic) => applic.value === applicId);
    formData.applic = applicAux?.id || null;
    formData.applicValue = applicId;
    props.onHandleChange(formData);
    render();
  }

  return (
    <Flex flexWrap="wrap" flexDirection="column" justifyContent="space-between">
      <Flex flexWrap="nowrap" flexDirection="row" justifyContent="left">
        <Flex flexWrap="nowrap" flexDirection="row" justifyContent="left">
          <IconWrapper>
            <LayersIcon />
          </IconWrapper>
          <strong style={{ fontSize: '14px' }}>
            Máquina
          </strong>
        </Flex>
        {isEdit && (
          <Flex flexWrap="nowrap" flexDirection="row" justifyContent="left" ml="272px">
            <strong style={{ fontSize: '14px' }}>
              {`Id: ${formData.groupId}`}
            </strong>
          </Flex>
        )}
      </Flex>
      <Flex flexWrap="wrap" flexDirection="row" mt="24px" justifyContent="space-between">
        <Input
          placeholder="Digite o nome da máquina"
          isInputFilled={!!watch('name')}
          label="Nome da Máquina"
          formLabel="name"
          validation={formValidators.name}
          error={errors.name ? formValidators.name.message : undefined}
          register={register}
          style={{ width: '404px' }}
          handleChange={(event) => { formData.name = event.target.value; props.onHandleChange(formData); }}
          data-test-id="nameMachine"
        />
      </Flex>
      <Flex flexWrap="wrap" flexDirection="row" mt="15px" justifyContent="space-between">
        <CustomInput style={{ width: '404px' }}>
          <div style={{ width: '100%', paddingTop: 3 }}>
            <Label>Unidade</Label>
            <SelectSearch
              options={props.unitsOpt}
              value={formData.unitId?.toString() || ''}
              printOptions="on-focus"
              search
              filterOptions={fuzzySearch}
              onChange={onFilterUnitChange}
              placeholder="Selecione a Unidade"
              // eslint-disable-next-line react/jsx-no-bind
              disabled={state.isLoading}
              closeOnSelect={false}
              data-test-id="unityMachine"
            />
          </div>
        </CustomInput>
      </Flex>
      <Flex flexWrap="wrap" flexDirection="row" mt="15px" justifyContent="space-between">
        <CustomInput style={{ width: '404px' }}>
          <div style={{ width: '100%', paddingTop: 3 }}>
            <Label>Tipo de Máquina</Label>
            <SelectSearch
              options={props.comboOpts.types}
              value={formData.typeValue?.toString() || ''}
              printOptions="on-focus"
              search
              filterOptions={fuzzySearch}
              placeholder="Selecione o tipo"
              // eslint-disable-next-line react/jsx-no-bind
              onChange={onFilterTypeChange}
              disabled={state.isLoading}
              closeOnSelect={false}
              data-test-id="typeMachine"
            />
          </div>
        </CustomInput>
      </Flex>
      <Flex flexWrap="nowrap" flexDirection="row" mt="15px" justifyContent="left">
        <CustomInput style={{ width: '194px' }}>
          <div style={{ width: '100%', paddingTop: 3 }}>
            <Label>Fabricante</Label>
            <SelectSearch
              options={props.comboOpts.brands}
              value={formData.brandValue?.toString() || ''}
              printOptions="on-focus"
              search
              filterOptions={fuzzySearch}
              placeholder="Selecionar fabricante"
              // eslint-disable-next-line react/jsx-no-bind
              onChange={onFilterBrandChange}
              disabled={state.isLoading}
              closeOnSelect={false}
              data-test-id="manufacturerMachine"
            />
          </div>
        </CustomInput>
        <CustomInput style={{ width: '195px', marginLeft: '15px' }}>
          <div style={{ width: '100%', paddingTop: 3 }}>
            <Label>Aplicação</Label>
            <SelectSearch
              options={props.comboOpts.applics}
              value={formData.applicValue?.toString() || ''}
              printOptions="on-focus"
              search
              filterOptions={fuzzySearch}
              placeholder="Selecionar fluído"
              // eslint-disable-next-line react/jsx-no-bind
              onChange={onFilterApplicChange}
              disabled={state.isLoading}
              closeOnSelect={false}
              data-test-id="applicationMachine"
            />
          </div>
        </CustomInput>
      </Flex>
      <Flex flexWrap="nowrap" flexDirection="row" mt="15px" justifyContent="left">
        <CustomInput style={{ width: '195px' }}>
          <div style={{ width: '100%' }}>
            <Input
              placeholder="Digite"
              isInputFilled={!!watch('refrigerationCapacity')}
              label="Cap. Frigorífica"
              formLabel="refrigerationCapacity"
              validation={false}
              error={errors.refrigerationCapacity ? errors.refrigerationCapacity.message : undefined}
              register={register}
              style={{ width: '100%', border: '0px' }}
              disabled
              handleChange={(event) => { formData.refrigerationCapacity = event.target.value; props.onHandleChange(formData); }}
              data-test-id="capacityMachine"
            />
          </div>
          <PlaceholderWrapper>
            {!switchCapacity ? 'TR' : 'BTU/h'}
          </PlaceholderWrapper>
        </CustomInput>
        <CustomInput style={{ width: '195px', marginLeft: '15px' }}>
          <div style={{ width: '100%', paddingTop: 3 }}>
            <Label>Fluído Refrigerante</Label>
            <SelectSearch
              options={props.comboOpts.fluids}
              value={formData.refrigerationFluidValue?.toString() || ''}
              printOptions="on-focus"
              search
              filterOptions={fuzzySearch}
              placeholder="Selecionar fluído"
              // eslint-disable-next-line react/jsx-no-bind
              onChange={onFilterRefrigerationFluidChange}
              disabled={state.isLoading}
              closeOnSelect={false}
              data-test-id="fluidMachine"
            />
          </div>
        </CustomInput>
      </Flex>
      <Flex flexWrap="wrap" flexDirection="row" mt="4px" justifyContent="space-between">
        <div style={{ fontSize: '12.5px' }}>
          Unidade Cap. Frig. TR
          <ToggleSwitchMini
            checked={switchCapacity}
            style={{ marginLeft: '7px', marginRight: '7px' }}
            onClick={(e) => { e.preventDefault(); }}
            disabled
            data-test-id="UnCapFrigTRBTU"
          />
          BTU/h
        </div>
      </Flex>
      <Flex flexWrap="nowrap" flexDirection="row" mt="15px" justifyContent="left">
        <CustomInput style={{ width: '195px' }}>
          <div style={{ width: '100%' }}>
            <Input
              placeholder="Digite"
              isInputFilled={!!watch('ratedPower')}
              label="Potência Nominal"
              formLabel="ratedPower"
              validation={false}
              error={errors.ratedPower ? errors.ratedPower.message : undefined}
              register={register}
              style={{ width: '100%', border: '0px' }}
              handleChange={(event) => { formData.ratedPower = event.target.value.replace(',', '.'); props.onHandleChange(formData); }}
              data-test-id="PowerMachine"
            />
          </div>
          <PlaceholderWrapper>
            kW
          </PlaceholderWrapper>
        </CustomInput>
        <div style={{ marginLeft: '15px' }}>
          <Input
            placeholder="__/__/____"
            isInputFilled={!!watch('installationDate')}
            label="Instalado em"
            formLabel="installationDate"
            validation={false}
            error={errors.installationDate ? errors.installationDate.message : undefined}
            register={register}
            style={{ width: '194px' }}
            handleChange={(event) => { formData.installationDate = maskInput(event.target.value); setValue('installationDate', formData.installationDate); props.onHandleChange(formData); }}
            data-test-id="installationMachine"
          />
        </div>
      </Flex>
      <Flex flexWrap="wrap" flexDirection="row" justifyContent="left" mt="15px">
        <Flex flexWrap="wrap" flexDirection="column" justifyContent="left" mt="24px">
          <Flex>
            <IconWrapper>
              <ChipIcon />
            </IconWrapper>
            <strong style={{ fontSize: '12px', marginLeft: '5px' }}>
              Possui automação?
            </strong>
          </Flex>
          <div style={{ fontSize: '12.5px', marginLeft: '30px' }}>
            Não
            <ToggleSwitchMini checked={switchAutomation} style={{ marginLeft: '7px', marginRight: '7px' }} onClick={(e) => { e.preventDefault(); setSwitchAutomation(!switchAutomation); }} />
            Sim
          </div>
        </Flex>
        <Flex flexWrap="nowrap" flexDirection="row" justifyContent="left" ml="49px" mt="20px">
          <Input
            placeholder=""
            isInputFilled={!!watch('automationDevId')}
            label="Dispositivo Automação"
            formLabel="automationDevId"
            validation={formValidators.automationDevId}
            error={errors.automationDevId ? errors.automationDevId.message : undefined}
            register={register}
            style={{ width: '223px' }}
            disabled={!switchAutomation}
            handleChange={(event) => { formData.automationDevId = event.target.value; props.onHandleChange(formData); }}
            data-test-id="automationMachine"
          />
        </Flex>
      </Flex>
      <Flex flexWrap="wrap" flexDirection="row" justifyContent="left">
        <Flex flexWrap="wrap" flexDirection="column" justifyContent="left" mt="24px">
          <Flex>
            <IconWrapper>
              <TemperatureIcon />
            </IconWrapper>
            <strong style={{ fontSize: '12px', marginLeft: '5px' }}>
              Possui DUT referência?
            </strong>
          </Flex>
          <div style={{ fontSize: '12.5px', marginLeft: '30px' }}>
            Não
            <ToggleSwitchMini checked={switchRefDut} style={{ marginLeft: '7px', marginRight: '7px' }} onClick={(e) => { e.preventDefault(); setSwitchRefDut(!switchRefDut); }} />
            Sim
          </div>
        </Flex>
        <Flex flexWrap="nowrap" flexDirection="row" justifyContent="left" ml="28px" mt="20px">
          <Input
            placeholder=""
            isInputFilled={!!watch('refDUTId')}
            label="DUT Referência"
            formLabel="refDUTId"
            validation={formValidators.refDUTId}
            error={errors.refDUTId ? errors.refDUTId.message : undefined}
            register={register}
            style={{ width: '223px' }}
            disabled={!switchRefDut}
            handleChange={(event) => { formData.refDUTId = event.target.value; props.onHandleChange(formData); }}
            data-test-id="refDutMachine"
          />
        </Flex>
      </Flex>
    </Flex>
  );
};
