import { useEffect, useState, useRef } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { colors } from '~/styles/colors';
import queryString from 'query-string';
import { toast } from 'react-toastify';
import { AxiosError } from 'axios';
import { Flex, Box } from 'reflexbox';
import {
  Button,
  ClearSelect,
  Input,
  Loader,
  StatusBox,
} from '~/components';
import { UploadFileIcon } from '~/icons/UploadFile';
import { Select } from '~/components/NewSelect';
import { getUserProfile } from '~/helpers/userProfile';
import parseDecimalNumber from 'helpers/parseDecimalNumber';
import { useStateVar } from '~/helpers/useStateVar';
import { apiCall, apiCallFormData, ApiResps } from '~/providers';
import { CardsCfg } from '.';
import { CardsConfig } from './CardsConfig';
import { Headers2 } from '../../Header';
import { SearchInput } from '../../styles';
import {
  FileInput,
  BtnClean,
  ControlTemperatureButton,
  SetpointButton,
} from './styles';

import {
  driApplicationOpts,
  driMeterApplications,
  driProtocolsOpts,
  driParityOpts,
  driStopBitsOpts,
  driLayerOpts,
  currentCapacityOpts,
  driMetersCfgs,
  installationTypeOpts,
  driVAVsApplications,
  driApplicationCfgs,
  driFancoilsApplications,
  driChillerCarrierApplications,
} from '~/helpers/driConfigOptions';
import { useTranslation } from 'react-i18next';
import { ArrowDownIconV2, ArrowUpIconV2 } from '~/icons';
import SelectSearch, { fuzzySearch } from 'react-select-search';
import { CustomInput, Label } from '~/components/FormMachine/styles';
import { OverLay } from '~/components/EnergyEfficiencyCard/styles';
import { getCachedDevInfo } from '~/helpers/cachedStorage';
import { DevFullInfo } from '~/store';

function getKeyByValue(object, value) {
  return Object.keys(object).find((key) => object[key] === value);
}

const MANUFACTURER = 'Diel Energia';

export const DriConfig = (props: {
  devInfoResp: ApiResps['/get-integration-info'],
  varsList: {
    varId: string
    name: string
    currVal?: string
    valUnit?: string
    card?: string
    subcard?: string
    relevance?: string | number
  }[],
  cards: string[],
  subcards: string[],
  relevances: string[],
}): JSX.Element => {
  const { t } = useTranslation();
  const history = useHistory();
  const integrType = 'diel';
  const [profile] = useState(getUserProfile);

  const [state, render, setState] = useStateVar({
    isLoading: false,
    devInfo: null as null|DevFullInfo,
    modbusBaudRate: '',
    driApplication: null as null | string,
    driMeterModel: null as null | { MODEL_ID: number, MANUFACTURER_ID: number, NAME: string },
    driChillerCarrierModel: null as null | { id: string, name: string },
    driVavFancoilModel: null as null | string,
    thermManuf: null as null | string,
    thermTmin: 21 as number,
    thermTmax: 24 as number,
    valveManuf: null as null | string,
    otherValveManuf: '',
    valveModel: '',
    vavBoxManuf: null as null | string,
    vavBoxModel: '',
    fancoilManuf: null as null | string,
    fancoilModel: '',
    otherFancoilManuf: '',
    roomName: '',
    fancoilRoomName: null as null | { RTYPE_ID?: number, ROOM_NAME?: string, DEV_ID?: string},
    vavLoaded: false,
    fancoilLoaded: false,
    driProtocol: null as null | string,
    driParity: null as null | string,
    driLayer: null as null | string,
    driStopBits: null as null | string,
    driSendInterval: '',
    selectedCurrentCapacity: null as null | { name: string, value: string },
    selectedInstallationType: null as null | { name: string, value: string },
    driSlaveId: '',
    driCfgFile: null as null | (Blob & { name: string }),
    comboOpts: {
      clients: [] as { NAME: string, CLIENT_ID: number }[],
      states: [] as { STATE_NAME: string, STATE_ID: string }[],
      cities: [] as { CITY_NAME: string, CITY_ID: string, STATE_ID: string }[],
      units: [] as { UNIT_NAME: string, UNIT_ID: number }[],
      duts: [] as { comboLabel: string, DUT_ID: string }[],
      enableEco: [{ name: t('sim'), value: 1 }, { name: t('nao'), value: 0 }],
      ecoModeCfg: [{ name: t('desligar'), value: 'eco-D' }, { name: t('ventilar'), value: 'eco-V' }],
      meterModels: [] as { MODEL_ID: number, MANUFACTURER_ID: number, NAME: string }[],
      thermManuf: [] as string[],
      valveManuf: [] as string[],
      boxManuf: [] as string[],
      fancoilManuf: [] as string[],
      fancoilValveManuf: [] as string[],
      groups: [] as { label: string, value: number, withDacAut?: boolean, checked?: boolean, unit: number}[],
      assets: [] as { value: number, label: string, name: string, UNIT_ID: number, CLIENT_ID: number, GROUP_ID: number, DEV_ID: string|null, ASSET_ROLE: number, DAT_ID: string|null, DAT_INDEX: number|null, MCHN_APPL: string|null, MCHN_BRAND: string|null }[],
    },
    formData: {
      CLIENT_ID_item: null as null | { NAME: string, CLIENT_ID: number },
      UNIT_ID_item: null as null | { UNIT_NAME: string, UNIT_ID: number },
      GROUP_ID_item: null as null|{ label: string, value: number, unit: number, name?: string },
      ASSET_ID_item: null as null|{ value: number, label: string, UNIT_ID: number, CLIENT_ID: number, GROUP_ID: number, DEV_ID: string|null, ASSET_ROLE: number, DAT_ID: string|null, DAT_INDEX: number|null },
      establishmentName: '',
      REL_DUT_ID_item: null as null | { comboLabel: string, DUT_ID: string },
      ENABLE_ECO_item: null as null | { name: string, value: number },
      ECO_MODE_CFG_item: null as null | { name: string, value: string },
      ECO_INTERVAL_TIME: '',
      ECO_OFST_START: '',
      ECO_OFST_END: '',
    },
    persistedAssetDevice: null as null|{ value: number, label: string, UNIT_ID: number, CLIENT_ID: number, GROUP_ID: number, DEV_ID: string|null, ASSET_ROLE: number, DAT_ID: string|null, DAT_INDEX: number|null },
    varsCfg: props.devInfoResp.dri,
    openModal: null as null | 'add-unit',
    rooms: [] as { RTYPE_ID: number, RTYPE_NAME: string }[],
    roomDutInfo: null as null | undefined | { DEV_ID?: string, RTYPE_NAME?: string, ROOM_NAME?: string, TUSEMIN?: number, TUSEMAX?: number},
  });

  const setDriApplication = (driCfg: ApiResps['/get-integration-info']['dri']) => {
    if (driCfg?.application && getKeyByValue(driMeterApplications, driCfg.application)) {
      state.driApplication = 'Medidor de Energia';
      state.driMeterModel = state.comboOpts.meterModels.find((opt) => opt.NAME === getKeyByValue(driMeterApplications, driCfg.application)) ?? null;
    } else if (driCfg?.application && getKeyByValue(driVAVsApplications, driCfg.application)) {
      state.driApplication = 'VAV';
      state.driVavFancoilModel = getKeyByValue(driVAVsApplications, driCfg.application) ?? null;
    } else if (driCfg?.application && getKeyByValue(driFancoilsApplications, driCfg.application)) {
      state.driApplication = 'Fancoil';
      state.driVavFancoilModel = getKeyByValue(driFancoilsApplications, driCfg.application) ?? null;
    } else if (driCfg?.application && getKeyByValue(driChillerCarrierApplications, driCfg.application)) {
      state.driApplication = 'Chiller Carrier';
      const model = getKeyByValue(driChillerCarrierApplications, driCfg.application);
      state.driChillerCarrierModel = model ? { name: model, id: model } : null;
    } else {
      state.driApplication = getKeyByValue(driApplicationOpts, driCfg?.application) ?? null;
    }
  };

  const setDriCfg = (driCfg: ApiResps['/get-integration-info']['dri']) => {
    setDriApplication(driCfg);
    state.driProtocol = getKeyByValue(driProtocolsOpts, driCfg?.protocol) ?? null;
    const parity = driCfg?.driConfigs?.find((cfg) => cfg.protocol === 'mdb_parity')?.value;
    state.driParity = (parity !== undefined) ? Object.keys(driParityOpts)[parity] : null;
    const serialMode = driCfg?.driConfigs?.find((cfg) => cfg.protocol === 'serial_mode')?.value;
    state.driLayer = (serialMode !== undefined) ? Object.keys(driLayerOpts)[Number(serialMode) - 1] : null;
    const stopBits = driCfg?.driConfigs?.find((cfg) => cfg.protocol === 'mdb_stopbits')?.value;
    state.driStopBits = (stopBits !== undefined) ? Object.keys(driStopBitsOpts)[stopBits] : null;
    state.driSendInterval = ['Medidor de energia', 'Carrier ECOSPLIT'].includes(String(state.driApplication)) ? '900' : (driCfg?.driConfigs?.find((cfg) => cfg.protocol === 'interval')?.value?.toString() ?? '');
    state.modbusBaudRate = driCfg?.driConfigs?.find((cfg) => cfg.protocol === 'mdb_baud')?.value?.toString() ?? '';
    const findCapacity = driCfg?.varsList?.find((v) => v.name === 'Capacidade TC');
    const capacityTc = findCapacity?.address?.value ?? findCapacity?.value;
    state.selectedCurrentCapacity = capacityTc && currentCapacityOpts.find((opt) => opt.value === (Number(capacityTc) / 2).toString()) || null;
    const installationType = driCfg?.varsList?.find((v) => v.name === 'Tipo Instalação')?.address?.value;
    state.selectedInstallationType = installationType && state.driMeterModel && installationTypeOpts[state.driMeterModel.NAME]?.find((opt) => opt.value === installationType) || null;
    state.driSlaveId = (['Nexus II', 'EM210', 'iKRON 03', 'ET330', 'Schneider PM2100', '30HXE', '30GXE', 'MULT-K'].includes(state.driMeterModel?.NAME ?? state.driChillerCarrierModel?.name ?? '') && driCfg?.varsList.find((item) => item.address)?.address?.id?.toString()) || '';
  };

  async function loadPlaceConfig() {
    const { devInfoResp } = props;
    setState({ isLoading: true });
    fetchUnits(devInfoResp.info.CLIENT_ID);
    const { info: driInfo, dri: driCfg } = devInfoResp;
    state.devInfo = await getCachedDevInfo(driInfo.integrId, { forceFresh: true });

    state.formData.CLIENT_ID_item = (driInfo.CLIENT_ID && driInfo.UNIT_ID) ? { NAME: driInfo.CLIENT_NAME, CLIENT_ID: driInfo.CLIENT_ID } : null;
    state.formData.UNIT_ID_item = (driInfo.CLIENT_ID && driInfo.UNIT_ID) ? { UNIT_NAME: driInfo.UNIT_NAME, UNIT_ID: driInfo.UNIT_ID } : null;
    render();

    await updateGroups();
    render();
    const group = state.comboOpts.groups.find((group) => (group.unit === state.formData.UNIT_ID_item?.UNIT_ID && group.value === state.devInfo?.GROUP_ID));
    if (group) {
      // @ts-ignore
      state.formData.GROUP_ID_item = {
        value: group.value,
        name: group.label,
        unit: group.unit,
      };
    }
    render();

    await updateAssets();
    render();
    for (const asset of state.comboOpts.assets) {
      if (asset.value === state.devInfo?.ASSET_ID) {
        state.formData.ASSET_ID_item = asset;
      }
    }
    render();

    const asset = await apiCall('/clients/get-asset-info', { DEV_ID: state.devInfo?.DEV_ID });
    if (asset?.info) {
      state.persistedAssetDevice = {
        value: asset.info.ASSET_ID,
        label: asset.info.AST_DESC,
        UNIT_ID: asset.info.UNIT_ID,
        GROUP_ID: asset.info.GROUP_ID,
        DEV_ID: asset.info.DEV_ID,
        ASSET_ROLE: asset.info.AST_ROLE || 0,
        CLIENT_ID: state.formData.CLIENT_ID_item?.CLIENT_ID as number,
        DAT_INDEX: asset.info.DAT_INDEX,
        DAT_ID: asset.info.DAT_ID,
      };
    } else {
      state.persistedAssetDevice = null;
    }

    setDriCfg(driCfg);

    setState({ isLoading: false });
    render();
  }

  const loadVavConfig = (VAV_ID: string) => {
    apiCall('/dri/get-dri-vav-info', { VAV_ID })
      .then((value) => {
        state.thermManuf = value.THERM_MANUF ?? null;
        state.valveManuf = value.VALVE_MANUF ?? null;
        state.valveModel = value.VALVE_MODEL ?? '';
        state.vavBoxManuf = value.BOX_MANUF ?? null;
        state.vavBoxModel = value.BOX_MODEL ?? '';
        state.roomName = value.ROOM_NAME ?? '';
      })
      .catch((err) => {
        console.log(err);
        toast.error(t('erroBuscarInformacoesVavDri'));
      });
    state.vavLoaded = true;
  };

  const loadFancoilConfig = (FANCOIL_ID: string) => {
    apiCall('/dri/get-dri-fancoil-info', { FANCOIL_ID })
      .then((value) => {
        state.thermManuf = value.THERM_MANUF ?? null;
        state.driVavFancoilModel = value.THERM_MODEL ?? null;
        state.valveManuf = value.VALVE_MANUF ?? null;
        state.valveModel = value.VALVE_MODEL ?? '';
        state.fancoilManuf = value.FANCOIL_MANUF ?? null;
        state.fancoilModel = value.FANCOIL_MODEL ?? '';
        state.fancoilRoomName = value.ROOM_NAME && value.RTYPE_ID ? { ROOM_NAME: value.ROOM_NAME === 'Termostato Associado ao DRI' ? t('termostatoAssociadoAoDRI') : value.ROOM_NAME, RTYPE_ID: value.RTYPE_ID, DEV_ID: value.DUT_ID_FROM_ROOM } : { RTYPE_ID: -1, ROOM_NAME: t('termostatoAssociadoAoDRI') };
        state.thermTmax = value.THERM_T_MAX ?? 24;
        state.thermTmin = value.THERM_T_MIN ?? 21;
        render();
      })
      .catch((err) => {
        console.log(err);
        toast.error(t('erroBuscarInformacoesFancoilDri'));
      });
    state.fancoilLoaded = true;
  };

  const loadEcoConfig = (driInfo: ApiResps['/get-integration-info']['info'], driCfg: ApiResps['/get-integration-info']['dri']) => {
    state.formData.REL_DUT_ID_item = (driCfg?.ecoCfg.DUT_ID) ? { DUT_ID: driCfg.ecoCfg.DUT_ID, comboLabel: driInfo.roomName } : null;
    state.formData.ENABLE_ECO_item = state.comboOpts.enableEco.find((opt) => opt.value === driCfg?.ecoCfg.ENABLE_ECO) ?? null;
    state.formData.ECO_MODE_CFG_item = state.comboOpts.ecoModeCfg.find((opt) => opt.value === driCfg?.ecoCfg.ECO_CFG) ?? null;
    state.formData.ECO_INTERVAL_TIME = driCfg?.ecoCfg.ECO_INT_TIME?.toString() ?? '';
    state.formData.ECO_OFST_START = driCfg?.ecoCfg.ECO_OFST_START?.toString() ?? '';
    state.formData.ECO_OFST_END = driCfg?.ecoCfg.ECO_OFST_END?.toString() ?? '';
  };

  function loadConfig() {
    const { devInfoResp } = props;
    const { info: driInfo, dri: driCfg } = devInfoResp;

    if (driCfg?.application?.startsWith('vav') && !state.vavLoaded) {
      loadVavConfig(devInfoResp.info.integrId);
    }

    if (driCfg?.application?.startsWith('fancoil') && !state.fancoilLoaded) {
      loadFancoilConfig(devInfoResp.info.integrId);
    }

    state.formData.establishmentName = driInfo.establishmentName ?? driInfo.machineName ?? '';
    render();
    setDriApplication(driCfg);
    render();
    if (state.driApplication === 'Fancoil' || state.driApplication === 'VAV') {
      apiCall('/dut/get-duts-list', { unitId: state.formData.UNIT_ID_item?.UNIT_ID }).then(({ list }) => {
        // @ts-ignore
        list?.push({ RTYPE_ID: -1, ROOM_NAME: t('termostatoAssociadoAoDRI') });
        state.rooms = list || [{ RTYPE_ID: -1, ROOM_NAME: t('termostatoAssociadoAoDRI') }];
        render();
      });
      getRoomDut();
    }
    render();
    loadEcoConfig(driInfo, driCfg);

    render();
  }

  const getRoomDut = async () => {
    if (state.fancoilRoomName && state.fancoilRoomName?.DEV_ID && state.fancoilRoomName?.RTYPE_ID !== -1) {
      const { list } = await apiCall('/dut/get-duts-list', { unitId: state.formData.UNIT_ID_item?.UNIT_ID });
      const dutFromRoom = list.find((dut) => dut.DEV_ID === state.fancoilRoomName?.DEV_ID);
      state.roomDutInfo = dutFromRoom || null;
      state.fancoilRoomName = dutFromRoom ? { RTYPE_ID: dutFromRoom.RTYPE_ID, ROOM_NAME: dutFromRoom.ROOM_NAME, DEV_ID: dutFromRoom.DEV_ID } : null;
      render();
    }
  };

  function fancoilForEach(opt, thermManuf, fancoilValveManuf, fancoilManuf) {
    if (opt.type === 'THERM_MANUF') thermManuf.push(opt.label);
    if (opt.type === 'VALVE_MANUF') {
      if (opt.label === 'Outro (digitar)') {
        fancoilValveManuf.push(t('outroDigitar'));
      } else {
        fancoilValveManuf.push(opt.label);
      }
    }
    if (opt.type === 'FANCOIL_MANUF') {
      if (opt.label === 'Outro (digitar)') {
        fancoilManuf.push(t('outroDigitar'));
      } else {
        fancoilManuf.push(opt.label);
      }
    }
  }

  function vavsForEach(opt, thermManuf, valveManuf, boxManuf) {
    if (opt.type === 'THERM_MANUF') thermManuf.push(opt.label);
    if (opt.type === 'VALVE_MANUF') valveManuf.push(opt.label);
    if (opt.type === 'BOX_MANUF') boxManuf.push(opt.label);
  }

  function verifyComboOptsVav() {
    if (state.comboOpts.thermManuf.length > 0 && state.comboOpts.valveManuf.length > 0 && state.comboOpts.boxManuf.length > 0) {
      return true;
    }
    return false;
  }

  function verifyComboOptsFancoil() {
    if (state.comboOpts.thermManuf.length > 0 && state.comboOpts.fancoilValveManuf.length > 0 && state.comboOpts.fancoilManuf.length > 0) {
      return true;
    }
    return false;
  }

  function verifyPermissions() {
    if (!profile.manageAllClients && !profile.permissions.isInstaller && !verifyAutomPermission()) {
      return true;
    }
    return false;
  }

  function verifyOpenModalAndStates() {
    let isReturn = false;
    if (state.openModal !== 'add-unit') isReturn = true;
    if (state.comboOpts.states.length > 0) isReturn = true;
    return isReturn;
  }

  function verifyOpenModalAndCities() {
    let isReturn = false;
    if (state.openModal !== 'add-unit') isReturn = true;
    if (state.comboOpts.cities.length > 0) isReturn = true;
    return isReturn;
  }

  function verifyOpenPermissionAndClients() {
    let isReturn = false;
    if (verifyPermissions()) isReturn = true;
    if (state.comboOpts.clients.length > 0) isReturn = true;
    return isReturn;
  }

  function verifyComboLabel(item) {
    if (item.ROOM_NAME) {
      return item.ROOM_NAME;
    }
    return item.DEV_ID;
  }

  function verifyAutomPermission() {
    return profile.adminClientProg?.CLIENT_MANAGE.some((item) => item === props.devInfoResp?.info?.CLIENT_ID);
  }

  useEffect(() => {
    if (verifyPermissions()) return;
    Promise.all([
      (async () => {
        if (verifyOpenPermissionAndClients()) return;
        const { list } = await apiCall('/clients/get-clients-list', {});
        const formattedList = list.map((item) => ({ CLIENT_ID: item.CLIENT_ID, NAME: item.NAME }));
        setState({ comboOpts: { ...state.comboOpts, clients: formattedList } });
      })(),
      (async () => {
        if (verifyOpenModalAndStates()) return;
        const { list } = await apiCall('/dac/get-states-list', { full: true });
        const formattedList = list.map((item) => ({ STATE_NAME: item.name, STATE_ID: item.id }));
        setState({ comboOpts: { ...state.comboOpts, states: formattedList } });
      })(),
      (async () => {
        if (verifyOpenModalAndCities()) return;
        const { list } = await apiCall('/dac/get-cities-list', {});
        const formattedList = list.map((item) => ({ CITY_NAME: item.name, CITY_ID: item.id, STATE_ID: item.state }));
        setState({ comboOpts: { ...state.comboOpts, cities: formattedList } });
      })(),
      (async () => {
        if (state.comboOpts.duts.length > 0) return;
        const { list } = await apiCall('/dut/get-duts-list', { unitId: props.devInfoResp.info.UNIT_ID });
        const notAutomatedList = list.filter((item) => !item.automationEnabled);
        const formattedList = notAutomatedList.map((item) => ({ comboLabel: verifyComboLabel(item), DUT_ID: item.DEV_ID }));
        setState({ comboOpts: { ...state.comboOpts, duts: formattedList } });
      })(),
      (async () => {
        if (state.comboOpts.meterModels.length > 0) return;
        const { manufacturersList, modelsList } = await apiCall('/energy/get-energy-combo-opts', {});
        const dielManuf = manufacturersList.find((manuf) => manuf.NAME === 'Diel Energia');
        const driMeterModels = modelsList.filter((model) => model.MANUFACTURER_ID === dielManuf?.MANUFACTURER_ID);
        setState({ comboOpts: { ...state.comboOpts, meterModels: driMeterModels } });
      })(),
      (async () => {
        if (verifyComboOptsVav()) return;
        const { vavs } = await apiCall('/dev/dev-info-combo-options', { vavs: true });
        const thermManuf = [] as string[];
        const valveManuf = [] as string[];
        const boxManuf = [] as string[];
        vavs?.forEach((opt) => {
          vavsForEach(opt, thermManuf, valveManuf, boxManuf);
        });
        setState({
          comboOpts: {
            ...state.comboOpts, thermManuf, valveManuf, boxManuf,
          },
        });
      })(),
      (async () => {
        if (verifyComboOptsFancoil()) return;
        const { fancoils } = await apiCall('/dev/dev-info-combo-options', { fancoils: true });
        const thermManuf = [] as string[];
        const fancoilValveManuf = [] as string[];
        const fancoilManuf = [] as string[];
        fancoils?.forEach((opt) => {
          fancoilForEach(opt, thermManuf, fancoilValveManuf, fancoilManuf);
        });
        setState({
          comboOpts: {
            ...state.comboOpts, thermManuf, fancoilValveManuf, fancoilManuf,
          },
        });
      })(),
    ])
      .catch((err) => { console.log(err); toast.error(t('erro')); });
  }, [state.openModal]);

  useEffect(() => {
    if (!profile.manageAllClients && !profile.permissions.isInstaller && !verifyAutomPermission()) return;
    loadConfig();
  }, [state.comboOpts]);

  useEffect(() => {
    if (!profile.manageAllClients && !profile.permissions.isInstaller && !verifyAutomPermission()) return;
    loadPlaceConfig();
  }, []);

  const updateUnits = async () => {
    const clientId = (state.formData.CLIENT_ID_item && state.formData.CLIENT_ID_item.CLIENT_ID) || null;
    await fetchUnits(clientId);
  };

  const updateGroups = async () => {
    const { groups } = await apiCall('/dev/dev-info-combo-options', { CLIENT_ID: state.formData.CLIENT_ID_item?.CLIENT_ID, UNIT_ID: state.formData.UNIT_ID_item?.UNIT_ID, groups: true });
    const groupFiltered = groups?.filter((group) => (group.unit === state.formData.UNIT_ID_item?.UNIT_ID));
    setState({ comboOpts: { ...state.comboOpts, groups: groupFiltered || [] } });
  };

  const updateAssets = async () => {
    const machinesAssetsList = state.formData.UNIT_ID_item?.UNIT_ID ? await apiCall('/clients/get-machines-list', { unitIds: [state.formData.UNIT_ID_item.UNIT_ID] }) : [];
    const assets = [] as { value: number, label: string, name: string, UNIT_ID: number, GROUP_ID: number, DEV_ID: string|null, ASSET_ROLE: number, CLIENT_ID: number, DAT_INDEX: number|null, DAT_ID: string|null, MCHN_APPL: string|null, MCHN_BRAND: string|null }[];
    for (const item of machinesAssetsList) {
      item.assets.forEach((asset) => {
        if (item.GROUP_ID === state.formData.GROUP_ID_item?.value && asset.AST_ROLE === 4 && asset.MCHN_APPL === 'chiller') {
          assets.push({
            value: asset.ASSET_ID,
            label: asset.AST_DESC,
            name: asset.AST_DESC,
            UNIT_ID: asset.UNIT_ID,
            GROUP_ID: item.GROUP_ID,
            DEV_ID: asset.DEV_ID,
            ASSET_ROLE: asset.AST_ROLE || 0,
            CLIENT_ID: state.formData.CLIENT_ID_item?.CLIENT_ID as number,
            DAT_INDEX: asset.DAT_INDEX,
            DAT_ID: asset.DAT_ID,
            MCHN_APPL: asset.MCHN_APPL,
            MCHN_BRAND: asset.MCHN_BRAND,
          });
        }
      });
    }
    setState({ comboOpts: { ...state.comboOpts, assets } });
  };

  function getDriApplication() {
    let driApplication;
    if (state.driApplication === 'Medidor de Energia' && state.driMeterModel) {
      driApplication = driMeterApplications[state.driMeterModel.NAME];
    } else if (state.driApplication === 'VAV' && state.driVavFancoilModel) {
      driApplication = driVAVsApplications[state.driVavFancoilModel];
    } else if (state.driApplication === 'Fancoil' && state.driVavFancoilModel) {
      driApplication = driFancoilsApplications[state.driVavFancoilModel];
    } else if (state.driApplication === 'Chiller Carrier' && state.driChillerCarrierModel) {
      driApplication = driChillerCarrierApplications[state.driChillerCarrierModel.name];
    } else {
      driApplication = state.driApplication && driApplicationOpts[state.driApplication];
    }
    return driApplication;
  }

  function verifyDriProtocol(driProtocol, modbusBaudRate, parity, stopBits, sendInterval) {
    if (driProtocol && (['modbus-rtu', 'carrier-ecosplit', 'Carrier ECOSPLIT'].includes(driProtocol)) && !modbusBaudRate) {
      alert(t('alertaInformeBaudRate'));
      return true;
    }
    if (driProtocol && (['modbus-rtu', 'carrier-ecosplit', 'Carrier ECOSPLIT'].includes(driProtocol)) && !parity) {
      alert(t('alertaInformeParidade'));
      return true;
    }
    if (driProtocol && (['modbus-rtu', 'carrier-ecosplit', 'Carrier ECOSPLIT'].includes(driProtocol)) && !stopBits) {
      alert(t('alertaInformeStopBits'));
      return true;
    }
    if (driProtocol && (['modbus-rtu', 'carrier-ecosplit', 'Carrier ECOSPLIT'].includes(driProtocol)) && !sendInterval) {
      alert(t('alertaInformeIntervaloEnvio'));
      return true;
    }
    return false;
  }

  function verifyDriProtocolAndModel(driProtocol, slaveId, capacityTc, installationType, driLayer) {
    if (driProtocol && ['EM210', 'MULT-K', 'Nexus II', 'Schneider PM2100'].includes(state.driMeterModel?.NAME || '') && slaveId == null) {
      alert(t('alertaInformeEnderecoId'));
      return true;
    }
    if (driProtocol && ['EM210', 'MULT-K'].includes(state.driMeterModel?.NAME || '') && !capacityTc) {
      alert(t('alertaInformeCapacidadeCorrente'));
      return true;
    }
    if (driProtocol && ['EM210'].includes(state.driMeterModel?.NAME || '') && !installationType) {
      alert(t('alertaInformeTipoInstalacaoEletrica'));
      return true;
    }
    if (driProtocol && ['Nexus II', 'Schneider PM2100'].includes(state.driMeterModel?.NAME || '') && !driLayer) {
      alert(t('alertaInfomeCamadaFisica'));
      return true;
    }
    if (['Nexus II'].includes(state.driMeterModel?.NAME || '') && !driProtocol) {
      alert(t('alertaInformeProtocoloComunicacao'));
      return true;
    }
    return false;
  }

  async function fancoilUpdate(lastApplication, driApplication) {
    if (lastApplication && (lastApplication?.startsWith('fancoil') && !driApplication?.startsWith('fancoil'))) {
      await apiCall('/dri/update-dri-fancoil', { FANCOIL_ID: props.devInfoResp.info.integrId, remove: true });
    } else if (driApplication?.startsWith('fancoil')) {
      await apiCall('/dri/update-dri-fancoil', {
        FANCOIL_ID: props.devInfoResp.info.integrId,
        THERM_MANUF: state.thermManuf,
        THERM_MODEL: state.driVavFancoilModel,
        THERM_T_MIN: state.thermTmin,
        THERM_T_MAX: state.thermTmax,
        VALVE_MANUF: state.valveManuf === t('outroDigitar') ? state.otherValveManuf : state.valveManuf,
        VALVE_MODEL: state.valveModel,
        FANCOIL_MANUF: state.fancoilManuf === t('outroDigitar') ? state.otherFancoilManuf : state.fancoilManuf,
        FANCOIL_MODEL: state.fancoilModel,
        ROOM_NAME: (state.fancoilRoomName?.ROOM_NAME === t('termostatoAssociadoAoDRI') ? 'Termostato Associado ao DRI' : state.fancoilRoomName?.ROOM_NAME) || null,
        RTYPE_ID: state.fancoilRoomName?.RTYPE_ID || null,
        DUT_ID_FROM_ROOM: state.fancoilRoomName?.DEV_ID || null,
      });
    }
  }

  async function vavUpdate(lastApplication, driApplication) {
    if (lastApplication && (lastApplication?.startsWith('vav') && !driApplication?.startsWith('vav'))) {
      await apiCall('/dri/update-dri-vav', { VAV_ID: props.devInfoResp.info.integrId, remove: true });
    } else if (driApplication?.startsWith('vav')) {
      await apiCall('/dri/update-dri-vav', {
        VAV_ID: props.devInfoResp.info.integrId,
        THERM_MANUF: state.thermManuf,
        THERM_MODEL: state.driVavFancoilModel,
        VALVE_MANUF: state.valveManuf,
        VALVE_MODEL: state.valveModel,
        BOX_MANUF: state.vavBoxManuf,
        BOX_MODEL: state.vavBoxModel,
        ROOM_NAME: state.roomName,
      });
    }
  }

  async function energyMeterSetAndDelete(lastApplication) {
    if (state.driApplication === 'Medidor de Energia' && state.driMeterModel && state.formData.UNIT_ID_item && state.formData.CLIENT_ID_item) {
      await apiCall('/energy/set-energy-list-info', {
        UNIT_ID: state.formData.UNIT_ID_item.UNIT_ID,
        CLIENT_ID: state.formData.CLIENT_ID_item.CLIENT_ID,
        meters: [{
          MANUFACTURER, MODEL: state.driMeterModel.NAME, DRI_ID: props.devInfoResp.info.integrId, ESTABLISHMENT_NAME: state.formData.establishmentName,
        }],
      });
    }

    if (lastApplication && ['cg-et330', 'abb-nexus-ii', 'abb-ete-30', 'cg-em210', 'kron-mult-k', 'kron-ikron-03'].includes(lastApplication) && state.driApplication !== 'Medidor de Energia') {
      await apiCall('/energy/delete-energy-info', {
        MANUFACTURER, DRI_ID: props.devInfoResp.info.integrId, UNIT_ID: state.formData.UNIT_ID_item?.UNIT_ID, CLIENT_ID: state.formData.CLIENT_ID_item?.CLIENT_ID,
      });
    }
  }

  function createVariables() {
    const driProtocol = state.driProtocol && driProtocolsOpts[state.driProtocol];
    const driLayer = state.driLayer ? driLayerOpts[state.driLayer] : undefined;
    const modbusBaudRate = state.modbusBaudRate || undefined;
    const parity = state.driParity ? driParityOpts[state.driParity] : undefined;
    const stopBits = state.driStopBits ? driStopBitsOpts[state.driStopBits] : undefined;
    let intervalTmp = state.driApplication === 'Medidor de Energia' ? '900' : undefined;
    intervalTmp = !intervalTmp && state.driApplication === 'Chiller Carrier' ? '60' : intervalTmp;
    const sendInterval = state.driSendInterval ? state.driSendInterval : intervalTmp;
    const capacityTc = state.selectedCurrentCapacity?.value || undefined;
    const installationType = state.selectedInstallationType?.value || undefined;
    const slaveId = (Number(state.driSlaveId) && state.driSlaveId) || undefined;

    return {
      driProtocol,
      driLayer,
      modbusBaudRate,
      parity,
      stopBits,
      sendInterval,
      capacityTc,
      installationType,
      slaveId,
    };
  }

  const handleEditAsset = async (driApplication: string) => {
    if (!driApplication || driApplication === 'chiller-carrier-30hxe' || driApplication === 'chiller-carrier-30gxe') {
      if ((!state.formData.ASSET_ID_item && state.persistedAssetDevice) || (!state.devInfo?.GROUP_ID && state.persistedAssetDevice) || (state.persistedAssetDevice && state.formData.ASSET_ID_item && state.persistedAssetDevice.value !== state.formData.ASSET_ID_item.value)) {
        await apiCall('/clients/edit-asset', {
          ASSET_ID: state.persistedAssetDevice.value,
          DAT_ID: state.persistedAssetDevice.DAT_ID as string || undefined,
          AST_DESC: state.persistedAssetDevice.label,
          CLIENT_ID: state.persistedAssetDevice.CLIENT_ID,
          GROUP_ID: state.persistedAssetDevice.GROUP_ID,
          UNIT_ID: state.persistedAssetDevice.UNIT_ID,
          AST_ROLE: state.persistedAssetDevice.ASSET_ROLE,
          MCHN_MODEL: state.driChillerCarrierModel?.name,
          DEV_ID: null,
          OLD_DEV_ID: state.persistedAssetDevice.DEV_ID,
          DAT_INDEX: state.persistedAssetDevice.DAT_INDEX,
        });
      }

      if (state.formData.ASSET_ID_item) {
        await apiCall('/clients/edit-asset', {
          ASSET_ID: state.formData.ASSET_ID_item.value,
          DAT_ID: state.formData.ASSET_ID_item.DAT_ID as string || undefined,
          AST_DESC: state.formData.ASSET_ID_item.label,
          CLIENT_ID: state.formData.ASSET_ID_item.CLIENT_ID,
          GROUP_ID: state.formData.ASSET_ID_item.GROUP_ID,
          UNIT_ID: state.formData.ASSET_ID_item.UNIT_ID,
          AST_ROLE: state.formData.ASSET_ID_item.ASSET_ROLE,
          MCHN_MODEL: state.driChillerCarrierModel?.name,
          DEV_ID: state.devInfo?.DEV_ID,
          OLD_DEV_ID: state.formData.ASSET_ID_item.DEV_ID,
          DAT_INDEX: state.formData.ASSET_ID_item.DAT_INDEX,
        });
      }
    }
  };

  async function uploadSelectedDriConfigFile() {
    try {
      if (!state.driCfgFile && state.driApplication === 'Modbus Genérico') {
        alert(t('alertaPlanilhaConfiguracoesNaoSelecionada'));
      }

      const { dri: driCfg } = props.devInfoResp;
      const lastApplication = driCfg?.application;
      const driApplication = getDriApplication();

      const {
        driProtocol,
        driLayer,
        modbusBaudRate,
        parity,
        stopBits,
        sendInterval,
        capacityTc,
        installationType,
        slaveId,
      } = createVariables();

      if (verifyDriProtocol(driProtocol, modbusBaudRate, parity, stopBits, sendInterval)) {
        return;
      }

      if (verifyDriProtocolAndModel(driProtocol, slaveId, capacityTc, installationType, driLayer)) {
        return;
      }

      await Promise.all([
        (async () => {
          if (verifyPermissions()) return;
          await apiCall('/dri/set-dri-info', {
            DRI_ID: props.devInfoResp.info.integrId,
            SYSTEM_NAME: state.formData.establishmentName,
            UNIT_ID: state.formData.UNIT_ID_item?.UNIT_ID ?? null,
            CLIENT_ID: state.formData.CLIENT_ID_item?.CLIENT_ID ?? null,
          });
        })(),
        (async () => {
          await handleEditAsset(driApplication);
        })(),

        apiCallFormData('/upload-dri-varscfg', {
          driId: props.devInfoResp.info.integrId,
          application: driApplication,
          protocol: driProtocol,
          serialMode: driLayer,
          modbusBaudRate,
          parity,
          stopBits,
          telemetryInterval: sendInterval,
          capacityTc,
          installationType,
          slaveId,
          worksheetName: state.driCfgFile?.name,
        }, {
          file: state.driCfgFile,
        }),
      ]);

      await energyMeterSetAndDelete(lastApplication);

      await vavUpdate(lastApplication, driApplication);

      await fancoilUpdate(lastApplication, driApplication);

      toast.success(t('sucessoSalvarConfiguracoes'));
      toast.success(t('sucessoReiniciarDispositivo'));
      history.push(history.location.pathname.replace('editar', history.location.pathname.includes('ativos') ? 'informacoes' : 'perfil'));
    } catch (err) {
      const error = err as AxiosError;
      console.log(err);
      if (error.response?.status !== 500) {
        toast.error(`${error.response?.data}`);
      } else {
        toast.error(t('erro'));
      }
    }
  }

  async function saveAutomationInfo() {
    try {
      if (profile.manageAllClients || verifyAutomPermission()) {
        await apiCall('/dri/set-dri-info', {
          DRI_ID: props.devInfoResp.info.integrId,
          DUT_ID: state.formData.REL_DUT_ID_item && state.formData.REL_DUT_ID_item.DUT_ID,
          ENABLE_ECO: state.formData.ENABLE_ECO_item && state.formData.ENABLE_ECO_item.value,
          ECO_CFG: state.formData.ECO_MODE_CFG_item && state.formData.ECO_MODE_CFG_item.value,
          ECO_INT_TIME: parseDecimalNumber(state.formData.ECO_INTERVAL_TIME) || 5,
          ECO_OFST_START: parseDecimalNumber(state.formData.ECO_OFST_START || '0') || null,
          ECO_OFST_END: parseDecimalNumber(state.formData.ECO_OFST_END || '0') || null,
        });

        toast.success(t('sucessoSalvarConfiguracoes'));
        history.push(history.location.pathname.replace('editar', 'perfil'));
      }
    } catch (err) {
      console.log(err);
      toast.error(t('erro'));
    }
  }

  async function fetchUnits(CLIENT_ID: number | null) {
    state.comboOpts.units = [];
    if (!CLIENT_ID) { render(); return; }

    const { units } = await apiCall('/dev/dev-info-combo-options', { CLIENT_ID, units: true });

    if (units) {
      state.comboOpts.units = units.map((unit) => ({ UNIT_ID: unit.value, UNIT_NAME: unit.label }));
    }
    render();
  }

  async function onSelectClient(item) {
    try {
      setState({ isLoading: true });
      state.formData.CLIENT_ID_item = { NAME: item.name, CLIENT_ID: item.value };
      state.formData.UNIT_ID_item = null;
      await fetchUnits(item.value);
      setState({ isLoading: false });
      render();
    } catch (err) {
      console.log(err);
      toast.error(t('erro'));
    }
  }

  async function afterCreateUnit({ item: response }) {
    try {
      const clientId = (state.formData.CLIENT_ID_item && state.formData.CLIENT_ID_item.CLIENT_ID) || null;
      await fetchUnits(clientId);
      state.formData.UNIT_ID_item = state.comboOpts.units.find((item) => item.UNIT_ID === response.UNIT_ID) || null;
      toast.success(t('sucessoUnidadeAdicionada'));
      setState({ openModal: null });
    } catch (err) {
      console.log(err);
      toast.error(t('erro'));
    }
  }

  async function save() {
    try {
      const cardsCfg: CardsCfg = {
        cards: props.cards,
        subcards: props.subcards,
        relevances: props.relevances,
        varscards: {},
      };
      for (const rowVar of props.varsList) {
        cardsCfg.varscards[rowVar.varId] = {
          card: rowVar.card,
          subcard: rowVar.subcard,
          relevance: rowVar.relevance || 1,
          valUnit: rowVar.valUnit ?? undefined,
        };
      }
      await Promise.all([
        (async () => {
          if (!profile.manageAllClients && !profile.permissions.isInstaller && !verifyAutomPermission()) return;
          await apiCall('/dri/set-dri-info', {
            DRI_ID: props.devInfoResp.info.integrId,
            UNIT_ID: state.formData.UNIT_ID_item && state.formData.UNIT_ID_item.UNIT_ID,
            CLIENT_ID: state.formData.CLIENT_ID_item && state.formData.CLIENT_ID_item.CLIENT_ID,
          });
        })(),
        (async () => {
          await apiCall('/save-integration-info', { supplier: integrType, integrId: props.devInfoResp.info.integrId, cardsCfg: JSON.stringify(cardsCfg) });
        })(),
      ]);
      history.push(history.location.pathname.replace('/editar', '/perfil'));
    } catch (err) {
      console.log(err);
      toast.error(t('erro'));
    }
  }

  function resetCfgFields() {
    if (state.driApplication !== 'Chiller Carrier') {
      state.formData.GROUP_ID_item = null;
      state.formData.ASSET_ID_item = null;
    }
    state.driProtocol = null;
    state.driMeterModel = null;
    state.driVavFancoilModel = null;
    state.driChillerCarrierModel = null;
    state.modbusBaudRate = '';
    state.driParity = null;
    state.driStopBits = null;
    state.driLayer = null;
    state.driCfgFile = null;
    state.selectedCurrentCapacity = null;
    state.selectedInstallationType = null;
    state.driSlaveId = '';
    const intervalTmp = state.driSendInterval.length === 0 && state.driApplication === 'Medidor de Energia' ? '900' : '';
    state.driSendInterval = !intervalTmp && state.driSendInterval.length === 0 && state.driApplication === 'Chiller Carrier' ? '60' : intervalTmp;
    render();
  }

  function returnExistOrValue1(item, item2) {
    if (item !== null) return item;
    return item2;
  }

  const queryPars = queryString.parse(history.location.search);
  const linkBase = history.location.pathname;
  const allTabs = [
    {
      title: t('equipamento'),
      link: `${linkBase}?${queryString.stringify({ ...queryPars, aba: 'equipamento' })}`,
      isActive: (queryPars.aba === 'equipamento') || (!queryPars.aba),
      visible: true,
      ref: useRef(null),
    },
    {
      title: t('Automação'),
      link: `${linkBase}?${queryString.stringify({ ...queryPars, aba: 'automacao' })}`,
      isActive: (queryPars.aba === 'automacao'),
      visible: (['carrier-ecosplit'].includes(props.devInfoResp.dri?.application || '')),
      ref: useRef(null),
    },
  ];

  function onSelectUnit(item) {
    state.formData.UNIT_ID_item = { UNIT_NAME: item.name, UNIT_ID: item.value };
  }

  const tabs = allTabs.filter((x) => x.visible);

  function decideWidth(value1, value2) {
    if (state.driApplication === 'Fancoil' && (state.valveManuf === t('outroDigitar') || state.fancoilManuf === t('outroDigitar'))) return value1;
    return value2;
  }

  function verifyDisabled() {
    return !(['Modbus RTU', 'Carrier ECOSPLIT'].includes(state.driProtocol!)) || state.driApplication === 'Carrier ECOSPLIT' || state.driApplication === 'VAV' || state.driApplication === 'Fancoil' || (state.driApplication === 'Medidor de Energia' && !['Nexus II', 'EM210', 'MULT-K', 'iKRON 03', 'ET330', 'Schneider PM2100'].includes(state.driMeterModel?.NAME || ''));
  }

  function DivSeparation() {
    if (state.driApplication === 'Fancoil' && (state.valveManuf === t('outroDigitar') || state.fancoilManuf === t('outroDigitar')) && state.valveManuf !== t('outroDigitar')) return <div style={{ width: '250px' }} />;
    return <></>;
  }

  function DivSeparationWithMargin() {
    if (state.driApplication === 'Fancoil' && (state.valveManuf === t('outroDigitar') || state.fancoilManuf === t('outroDigitar'))) return <div style={{ width: '250px', marginBottom: '20px' }} />;
    return <></>;
  }

  function Fabricante() {
    return (
      <>
        {(state.driApplication === 'Fancoil' || state.driApplication === 'VAV') && (
          <>
            <Select
              style={{ width: '250px', marginBottom: '20px' }}
              label={state.driApplication === 'VAV' ? t('fabricanteAtuadorVav') : t('fabricanteAtuador')}
              placeholder={t('selecionar')}
              options={state.driApplication === 'VAV' ? state.comboOpts.valveManuf : state.comboOpts.fancoilValveManuf}
              value={state.valveManuf}
              onSelect={(item) => setState({ valveManuf: item })}
            />
            <Input
              style={{ width: '250px' }}
              label={state.driApplication === 'VAV' ? t('modeloAtuadorVav') : t('modeloAtuador')}
              placeholder={t('digitarModelo')}
              value={state.valveModel}
              onChange={(event) => setState({ valveModel: event.target.value })}
            />
            <Input
              style={{ width: '250px' }}
              label={t('fabricanteAtuador')}
              placeholder={t('digitarFabricante')}
              value={state.otherValveManuf}
              onChange={(event) => setState({ otherValveManuf: event.target.value })}
            />
          </>
        )}
      </>
    );
  }

  function disableCamadaFisica() {
    return state.driApplication === 'Carrier ECOSPLIT' || state.driApplication === 'VAV' || state.driApplication === 'Fancoil' || (state.driApplication === 'Medidor de Energia' && !['Nexus II', 'iKRON 03', 'ET330', 'Schneider PM2100'].includes(state.driMeterModel?.NAME || ''));
  }

  function verifyExist(item) {
    if (item) return item;
    return '-';
  }

  const getUnitsOptions = () => (
    state.comboOpts.units.length > 0 ? state.comboOpts.units.map((item) => ({ value: item.UNIT_ID, name: item.UNIT_NAME })) : [{ value: state.formData.UNIT_ID_item ? state.formData.UNIT_ID_item.UNIT_ID : '', name: state.formData.UNIT_ID_item ? state.formData.UNIT_ID_item.UNIT_NAME : '' }]
  );

  return (
    <div>
      {(tabs.length > 0) && <Headers2 links={tabs} />}
      <br />
      <br />

      <div style={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'flex-start',
        width: '60%',
      }}
      >
        <div>
          <div style={{ fontWeight: 'bold', fontSize: '1.25em' }}>{t('editar')}</div>
          <div style={{ fontSize: '1.1em', fontWeight: 400, color: 'darkgray' }}>{props.devInfoResp.info.integrId}</div>
        </div>
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <div style={{ marginRight: '9px', fontWeight: 'bold' }}>Status:</div>
          <StatusBox style={{ margin: '0px' }} status={props.devInfoResp.info.status || 'OFFLINE'}>{props.devInfoResp.info.status || 'OFFLINE'}</StatusBox>
        </div>
      </div>

      <div style={{ display: 'flex', flexDirection: 'column', width: '80%' }}>
        <br />
        <br />

        {allTabs[0].isActive && (
          <div>
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              {
                state.isLoading && (
                  <OverLay>
                    <Loader />
                  </OverLay>
                )
              }
              <div style={{ display: 'flex', flexWrap: 'wrap', gap: 10 }}>
                <Flex flexDirection="column">
                  <SearchInput
                    style={{
                      width: 'auto',
                      margin: 0,
                      marginBottom: 10,
                      border: '1px solid #818181',
                    }}
                  >
                    <div style={{ width: '100%', paddingTop: 3, paddingBottom: 3 }}>
                      <Label>{t('cliente')}</Label>
                      <SelectSearch
                        style={{ width: '200px', marginBottom: '20px', marginRight: '20px' }}
                        label={t('cliente')}
                        placeholder={t('selecionar')}
                        search
                        filterOptions={fuzzySearch}
                        options={state.comboOpts.clients.map((item) => ({ value: item.CLIENT_ID, name: item.NAME }))}
                        value={state.formData.CLIENT_ID_item?.CLIENT_ID.toString()}
                        onChange={(item, value) => { onSelectClient(value); updateUnits(); }}
                      />
                    </div>
                  </SearchInput>
                  {state.formData.CLIENT_ID_item?.CLIENT_ID && (
                    <div style={{ marginBottom: '20px' }}>
                      <ClearSelect
                        onClickClear={() => {
                          state.formData.CLIENT_ID_item = null;
                          state.formData.UNIT_ID_item = null;
                          state.formData.GROUP_ID_item = null;
                          state.formData.ASSET_ID_item = null;
                          render();
                        }}
                        value={state.formData.CLIENT_ID_item?.NAME}
                      />
                    </div>
                  )}
                </Flex>

                <Flex flexDirection="column">
                  <SearchInput
                    style={{
                      width: 'auto',
                      margin: 0,
                      marginBottom: 10,
                      border: '1px solid #818181',
                    }}
                  >
                    <div style={{ width: '100%', paddingTop: 3, paddingBottom: 3 }}>
                      <Label>{t('unidade')}</Label>
                      <SelectSearch
                        style={{ width: '250px', marginBottom: '10px', marginRight: '20px' }}
                        label={t('unidade')}
                        placeholder={t('selecionar')}
                        search
                        filterOptions={fuzzySearch}
                        options={getUnitsOptions()}
                        value={state.formData.UNIT_ID_item?.UNIT_ID.toString()}
                        onChange={(item, name) => { onSelectUnit(name); updateGroups(); render(); }}
                        disabled={!state.formData.CLIENT_ID_item?.CLIENT_ID}
                      />
                    </div>
                  </SearchInput>
                  {state.formData.UNIT_ID_item?.UNIT_ID && (
                    <div style={{ marginBottom: '20px' }}>
                      <ClearSelect
                        onClickClear={() => {
                          state.formData.UNIT_ID_item = null;
                          state.formData.GROUP_ID_item = null;
                          state.formData.ASSET_ID_item = null;
                          render();
                        }}
                        value={state.formData.UNIT_ID_item?.UNIT_NAME}
                      />
                    </div>
                  )}
                </Flex>
              </div>
              {state.driApplication === 'Chiller Carrier'
              && (
                <Flex flexDirection="column" width="400px">
                  <Flex flexDirection="column">
                    <SearchInput
                      style={{
                        width: 'auto',
                        margin: 0,
                        marginBottom: 10,
                        border: '1px solid #818181',
                      }}
                    >
                      <div style={{ width: '100%', paddingTop: 3, paddingBottom: 3 }}>
                        <Label>{t('conjunto')}</Label>
                        <SelectSearch
                          options={state.comboOpts.groups.map((item) => ({
                            value: item.value,
                            name: item.label,
                            unit: item.unit,
                          }))}
                          value={state.formData.GROUP_ID_item?.value.toString()}
                          placeholder={t('conjunto')}
                          search
                          filterOptions={fuzzySearch}
                          onChange={(value, name) => {
                            // @ts-ignore
                            state.formData.GROUP_ID_item = name;

                            render();
                            updateAssets();
                            render();
                          }}
                          disabled={!state.formData.UNIT_ID_item?.UNIT_ID}
                        />
                      </div>
                    </SearchInput>
                    {state.formData.GROUP_ID_item?.value && (
                      <div style={{ marginBottom: '20px' }}>
                        <ClearSelect
                          onClickClear={() => {
                            state.formData.GROUP_ID_item = null;
                            state.formData.ASSET_ID_item = null;
                            render();
                          }}
                          value={state.formData.GROUP_ID_item.name || state.formData.GROUP_ID_item.label}
                        />
                      </div>
                    )}
                  </Flex>
                  <Flex flexDirection="column">
                    <SearchInput
                      style={{
                        width: 'auto',
                        margin: 0,
                        marginBottom: 10,
                        border: '1px solid #818181',
                      }}
                    >
                      <div style={{ width: '100%', paddingTop: 3, paddingBottom: 3 }}>
                        <Label>{t('ativo')}</Label>
                        <SelectSearch
                          options={state.comboOpts.assets}
                          value={state.formData.ASSET_ID_item?.value.toString()}
                          placeholder={t('ativo')}
                          search
                          filterOptions={fuzzySearch}
                          onChange={(value, name) => {
                            // @ts-ignore
                            state.formData.ASSET_ID_item = name;
                            render();
                          }}
                          disabled={!state.formData.GROUP_ID_item?.value}
                        />
                      </div>
                    </SearchInput>
                    {state.formData.ASSET_ID_item?.value && (
                      <div style={{ marginBottom: '20px' }}>
                        <ClearSelect
                          onClickClear={() => {
                            state.formData.ASSET_ID_item = null;
                            render();
                          }}
                          value={state.formData.ASSET_ID_item?.label}
                        />
                      </div>
                    )}
                  </Flex>
                </Flex>
              )}
              <div style={{ borderBottom: '1px solid #D9DADB', marginBottom: '40px', marginTop: '20px' }} />

              <div style={{ display: 'flex', flexFlow: 'row wrap' }}>
                <Select
                  style={{ width: '200px', marginBottom: '20px', marginRight: '20px' }}
                  label={t('aplicacao')}
                  placeholder={t('selecionar')}
                  options={Object.keys(driApplicationOpts)}
                  value={state.driApplication}
                  onSelect={(item) => {
                    state.driApplication = item;
                    if (item === 'Carrier ECOSPLIT') {
                      state.driProtocol = item;
                      state.modbusBaudRate = '9600';
                      state.driParity = 'Desabilitado';
                      state.driLayer = 'RS-485';
                      state.driStopBits = '1 Stop Bit';
                    } else { resetCfgFields(); }
                    render();
                  }}
                />
                {state.driApplication === 'Chiller Carrier' && (
                  <>
                    <Select
                      style={{ width: '200px', marginBottom: '20px' }}
                      label={t('modelo')}
                      placeholder={t('selecionar')}
                      options={[{ name: '30HXE', id: '30HXE' }, { name: '30GXE', id: '30GXE' }]}
                      propLabel="name"
                      value={state.driChillerCarrierModel}
                      onSelect={(item) => {
                        state.driChillerCarrierModel = item;
                        state.driSlaveId = '10';
                        const defaultConfig = driApplicationCfgs[item.name];
                        if (defaultConfig) {
                          state.driProtocol = getKeyByValue(driProtocolsOpts, defaultConfig.protocol) ?? null;
                          state.modbusBaudRate = defaultConfig.modbusBaudRate;
                          state.driParity = getKeyByValue(driParityOpts, defaultConfig.parity) ?? null;
                          state.driLayer = getKeyByValue(driLayerOpts, defaultConfig.serialMode) ?? null;
                          state.driStopBits = getKeyByValue(driStopBitsOpts, defaultConfig.stopBits) ?? null;
                          state.driSendInterval = '60';
                        } else { resetCfgFields(); }
                        render();
                      }}
                    />
                    <div style={{ width: '22px' }} />
                  </>
                )}
                {state.driApplication === 'Medidor de Energia' && (
                  <>
                    <Select
                      style={{ width: '200px', marginBottom: '20px' }}
                      label={t('modelo')}
                      placeholder={t('selecionar')}
                      options={state.comboOpts.meterModels}
                      propLabel="NAME"
                      value={state.driMeterModel}
                      onSelect={(item) => {
                        state.driMeterModel = item;
                        state.selectedCurrentCapacity = null;
                        state.selectedInstallationType = null;
                        state.driSlaveId = '1';
                        const defaultConfig = driMetersCfgs[item.NAME];
                        if (defaultConfig) {
                          state.driProtocol = getKeyByValue(driProtocolsOpts, defaultConfig.protocol) || null;
                          state.modbusBaudRate = defaultConfig.modbusBaudRate;
                          state.driParity = getKeyByValue(driParityOpts, defaultConfig.parity) || null;
                          state.driLayer = getKeyByValue(driLayerOpts, defaultConfig.serialMode) || null;
                          state.driStopBits = getKeyByValue(driStopBitsOpts, defaultConfig.stopBits) || null;
                        } else { resetCfgFields(); }
                        render();
                      }}
                    />
                    <div style={{ width: '22px' }} />
                    <Input
                      style={{ width: '220px', height: '42px' }}
                      label={t('quadroEletricoRelativo')}
                      placeholder={t('nome')}
                      value={state.formData.establishmentName}
                      onChange={(event) => { state.formData.establishmentName = event.target.value; render(); }}
                    />

                  </>
                )}

                <Flex width={decideWidth('95%', '82%')} mb={20}>
                  <Flex flexWrap="wrap" width={decideWidth('800px', '520px')} justifyContent="space-between">
                    {(['Fancoil', 'VAV'].includes(state.driApplication ?? '')) && (
                      <>
                        <Select
                          style={{ width: '250px', marginBottom: '20px' }}
                          label={t('fabricanteTermostato')}
                          placeholder={t('selecionar')}
                          options={state.comboOpts.thermManuf}
                          value={state.thermManuf}
                          onSelect={(item) => setState({ thermManuf: item })}
                        />
                        <Select
                          style={{ width: '250px', marginBottom: '20px' }}
                          label={t('modeloTermostato')}
                          placeholder={t('selecionar')}
                          options={Object.keys(driVAVsApplications)}
                          value={state.driVavFancoilModel}
                          onSelect={(item) => {
                            state.driVavFancoilModel = item;
                            const defaultConfig = driApplicationCfgs[item];
                            if (state.driApplication === 'Fancoil' && defaultConfig.application) {
                              const application = defaultConfig.application.split('-');
                              application[0] = 'fancoil';
                              defaultConfig.application = application.join('-');
                            }
                            if (defaultConfig) {
                              state.driProtocol = getKeyByValue(driProtocolsOpts, defaultConfig.protocol) || null;
                              state.modbusBaudRate = defaultConfig.modbusBaudRate;
                              state.driParity = getKeyByValue(driParityOpts, defaultConfig.parity) || null;
                              state.driLayer = getKeyByValue(driLayerOpts, defaultConfig.serialMode) || null;
                              state.driStopBits = getKeyByValue(driStopBitsOpts, defaultConfig.stopBits) || null;
                            } else { resetCfgFields(); }
                            render();
                          }}
                        />
                      </>
                    )}

                    <DivSeparationWithMargin />
                    <Fabricante />
                    <DivSeparation />

                    {state.driApplication === 'VAV' && (
                      <>
                        <Select
                          style={{ width: '250px', marginBottom: '20px' }}
                          label={t('fabricanteCaixaVav')}
                          placeholder={t('selecionar')}
                          options={state.comboOpts.boxManuf}
                          value={state.vavBoxManuf}
                          onSelect={(item) => setState({ vavBoxManuf: item })}
                        />
                        <Input
                          style={{ width: '250px' }}
                          label={t('medidorCaixaVav')}
                          placeholder={t('digitarModelo')}
                          value={state.vavBoxModel}
                          onChange={(event) => setState({ vavBoxModel: event.target.value })}
                        />
                      </>
                    )}

                    {state.driApplication === 'Fancoil' && (
                      <Select
                        style={{ width: '250px' }}
                        label={t('fabricanteFancoil')}
                        placeholder={t('selecionar')}
                        options={state.comboOpts.fancoilManuf}
                        value={state.fancoilManuf}
                        onSelect={(item) => setState({ fancoilManuf: item })}
                      />
                    )}

                    {(state.driApplication === 'Fancoil' && state.fancoilManuf === t('outroDigitar')) && (
                    <Input
                      style={{ width: '250px' }}
                      label={t('fabricanteFancoil')}
                      placeholder={t('digitarFabricante')}
                      value={state.otherFancoilManuf}
                      onChange={(event) => setState({ otherFancoilManuf: event.target.value })}
                    />
                    )}
                    {state.driApplication === 'Fancoil' && (
                      <Input
                        style={{ width: '250px' }}
                        label={t('modeloFancoil')}
                        placeholder={t('digitarModelo')}
                        value={state.fancoilModel}
                        onChange={(event) => setState({ fancoilModel: event.target.value })}
                      />
                    )}

                    {state.driApplication === 'Fancoil' && (state.valveManuf === t('outroDigitar') || state.fancoilManuf === t('outroDigitar')) && state.fancoilManuf !== t('outroDigitar') && <div style={{ width: '250px' }} />}

                    {state.driApplication === 'VAV' && (
                      <Input
                        style={{ width: '250px' }}
                        label={t('ambienteMonitorado')}
                        placeholder={t('digitar')}
                        value={state.roomName}
                        onChange={(event) => setState({ roomName: event.target.value })}
                      />
                    )}
                    {state.driApplication === 'Fancoil' && (
                    <Flex flexDirection="row" flexWrap="wrap" width="520px" justifyContent="space-between" mt="20px" alignItems="flex-start" style={{ gap: '20px' }}>
                      <Select
                        style={{ width: '250px' }}
                        label={t('ambienteMonitorado')}
                        placeholder={t('selecionar')}
                        propLabel="ROOM_NAME"
                        options={state.rooms}
                        value={state.fancoilRoomName}
                        disabled
                        onSelect={(item) => { setState({ fancoilRoomName: item }); render(); getRoomDut(); }}
                      />

                      {state.driApplication === 'Fancoil' && state.fancoilRoomName && state.fancoilRoomName.ROOM_NAME === t('termostatoAssociadoAoDRI') && (
                      <Flex flexDirection="column">
                        <div style={{
                          fontWeight: '700', fontSize: '12px', color: colors.Blue700, marginBottom: '4px',
                        }}
                        >
                          Limites de Temperatura do Termostato
                        </div>
                        <Flex justifyContent="space-between" flexDirection="row" style={{ margin: '0px', width: '250px' }}>
                          <ControlTemperatureButton
                            style={{ width: '120px', height: '50px', justifyContent: 'flex-start' }}
                          >
                            <div style={{ width: '98px', marginLeft: '12px', marginRight: '20px' }}>
                              <div style={{ fontWeight: '700', fontSize: '12px', color: colors.Blue700 }}>T-min</div>
                              <div style={{ fontWeight: '400', fontSize: '14px' }}>{`${verifyExist(state.thermTmin)} °C`}</div>
                            </div>
                            <div style={{
                              width: '57px',
                              display: 'flex',
                              flexDirection: 'column',
                              height: '100%',
                            }}
                            >
                              <SetpointButton up onClick={() => { state.thermTmin += 1; render(); }}>
                                <ArrowUpIconV2 width="9px" heigth="8px" />
                              </SetpointButton>
                              <SetpointButton down onClick={() => { state.thermTmin -= 1; render(); }}>
                                <ArrowDownIconV2 width="10px" heigth="11px" />
                              </SetpointButton>
                            </div>

                          </ControlTemperatureButton>
                          <ControlTemperatureButton
                            style={{ width: '120px', height: '50px', justifyContent: 'flex-start' }}
                          >
                            <div style={{ width: '98px', marginLeft: '12px', marginRight: '20px' }}>
                              <div style={{ fontWeight: '700', fontSize: '12px', color: colors.Blue700 }}>T-max</div>
                              <div style={{ fontWeight: '400', fontSize: '14px' }}>{`${verifyExist(state.thermTmax)} °C`}</div>
                            </div>
                            <div style={{
                              width: '57px',
                              display: 'flex',
                              flexDirection: 'column',
                              height: '100%',
                            }}
                            >
                              <SetpointButton up onClick={() => { state.thermTmax += 1; render(); }}>
                                <ArrowUpIconV2 width="9px" heigth="8px" />
                              </SetpointButton>
                              <SetpointButton down onClick={() => { state.thermTmax -= 1; render(); }}>
                                <ArrowDownIconV2 width="10px" heigth="11px" />
                              </SetpointButton>
                            </div>

                          </ControlTemperatureButton>
                        </Flex>
                      </Flex>
                      )}
                      {state.driApplication === 'Fancoil' && state.fancoilRoomName && state.fancoilRoomName.ROOM_NAME !== t('termostatoAssociadoAoDRI')
                    && (
                    <Flex width="250px" flexDirection="row" fontSize="12px" flexWrap="wrap">
                      <Box width="50%">
                        <span style={{ fontWeight: 'bold', display: 'block' }}>
                          Dispositivo
                        </span>
                        <Link to={`/analise/dispositivo/${state.roomDutInfo?.DEV_ID}/informacoes`} style={{ color: colors.Blue300, textDecoration: 'underline' }}>
                          {verifyExist(state.roomDutInfo?.DEV_ID)}
                        </Link>
                      </Box>
                      <Box width="50%">
                        <span style={{ fontWeight: 'bold', display: 'block' }}>
                          Tipo de Ambiente
                        </span>
                        <span>
                          {verifyExist(state.roomDutInfo?.RTYPE_NAME)}
                        </span>
                      </Box>
                      <Box mt="20px" width="auto">
                        <span style={{ fontWeight: 'bold', display: 'block' }}>
                          Limites de Temperatura
                        </span>
                        <Flex justifyContent="space-between" alignItems="center">
                          <Box>
                            <span>min.&nbsp;</span>
                            <span style={{ fontWeight: 'bold' }}>{verifyExist(state.roomDutInfo?.TUSEMIN)}</span>
                            <span style={{ fontSize: '10px', color: colors.Grey200 }}>°C</span>
                          </Box>
                          <Box>
                            <span>max.&nbsp;</span>
                            <span style={{ fontWeight: 'bold' }}>{verifyExist(state.roomDutInfo?.TUSEMAX)}</span>
                            <span style={{ fontSize: '10px', color: colors.Grey200 }}>°C</span>
                          </Box>
                        </Flex>
                      </Box>
                    </Flex>
                    )}
                    </Flex>
                    )}

                  </Flex>
                </Flex>
                <Select
                  label={t('protocoloComunicacao')}
                  style={{ width: '300px', marginBottom: '20px', marginRight: '20px' }}
                  options={Object.keys(driProtocolsOpts)}
                  value={state.driProtocol}
                  placeholder={t('selecioneTipo')}
                  disabled={state.driApplication === 'Carrier ECOSPLIT' || state.driApplication === 'VAV' || state.driApplication === 'Fancoil' || (state.driApplication === 'Medidor de Energia' && state.driMeterModel?.NAME !== 'Nexus II' && state.driMeterModel?.NAME !== 'MULT-K' && state.driMeterModel?.NAME !== 'ET330') && state.driMeterModel?.NAME !== 'Schneider PM2100'}
                  onSelect={(item) => { state.driProtocol = item; render(); }}
                />
                <Select
                  label="Baud-rate"
                  style={{ width: '200px', marginBottom: '20px', marginRight: '20px' }}
                  options={['4800', '9600', '19200', '115200', '921600']}
                  value={state.modbusBaudRate}
                  placeholder={t('selecionar')}
                  disabled={verifyDisabled()}
                  onSelect={(item) => { state.modbusBaudRate = item; render(); }}
                />
                <Select
                  style={{ width: '200px', marginBottom: '20px', marginRight: '20px' }}
                  label={t('paridade')}
                  options={Object.keys(driParityOpts)}
                  value={state.driParity}
                  placeholder={t('selecionar')}
                  disabled={verifyDisabled()}
                  onSelect={(item) => { state.driParity = item; render(); }}
                />
                <Select
                  style={{ width: '200px', marginBottom: '20px', marginRight: '20px' }}
                  label={t('camadaFisica')}
                  options={Object.keys(driLayerOpts)}
                  value={state.driLayer}
                  placeholder={t('selecionar')}
                  disabled={disableCamadaFisica()}
                  onSelect={(item) => { state.driLayer = item; render(); }}
                />
                {state.driApplication === 'Modbus Genérico' && (
                  <div style={{ width: '300px', marginBottom: '20px' }}>
                    <div style={{ display: 'flex' }}>
                      <div>
                        <Input
                          type="text"
                          label={t('planilha')}
                          value={verifyExist(state.driCfgFile?.name)}
                          placeholder={t('fazerUpload')}
                          onChange={(event) => { }}
                          style={{ width: '246px' }}
                        />
                      </div>

                      <div style={{ display: 'flex' }}>
                        <FileInput
                          onChange={(e: any) => { setState({ driCfgFile: e.target.files[0] }); }}
                          style={{
                            backgroundColor: colors.Blue300,
                            borderColor: colors.Blue300,
                            padding: '10px',
                          }}
                        >
                          <UploadFileIcon color="white" />
                          <input type="file" hidden />
                        </FileInput>
                      </div>
                    </div>
                  </div>
                )}
                <Select
                  style={{ width: '200px', marginBottom: '20px', marginRight: '20px' }}
                  label="Stop Bits"
                  options={Object.keys(driStopBitsOpts)}
                  value={state.driStopBits}
                  placeholder={t('selecionar')}
                  disabled={verifyDisabled()}
                  onSelect={(item) => { state.driStopBits = item; render(); }}
                />
                {(['Nexus II', 'EM210', 'MULT-K', 'iKRON 03', 'ET330', 'Schneider PM2100'].includes(state.driMeterModel?.NAME || '') || state.driApplication === 'Chiller Carrier') && (
                  <Input
                    style={{ width: '200px', marginLeft: '10px', marginRight: '20px' }}
                    label={t('enderecoID')}
                    placeholder={t('digitar')}
                    value={state.driSlaveId}
                    onChange={(event) => {
                      if (Number(event.target.value) || event.target.value === '') {
                        setState({ driSlaveId: event.target.value });
                      }
                    }}
                  />
                )}
                <Input
                  style={{ width: '200px', marginLeft: '10px' }}
                  label={t('intervaloEnvio')}
                  placeholder={t('digitar')}
                  suffix={t('segundos')}
                  value={state.driSendInterval}
                  disabled={!(['Modbus RTU', 'Carrier ECOSPLIT'].includes(state.driProtocol!))}
                  onChange={(event) => setState({ driSendInterval: event.target.value })}
                />
                {state.driMeterModel?.NAME && !['ET330', 'EM210', 'MULT-K'].includes(state.driMeterModel.NAME) && (
                  <div style={{ marginRight: 'auto' }} />
                )}
                {state.driMeterModel?.NAME && ['ET330', 'EM210', 'MULT-K'].includes(state.driMeterModel.NAME) && (
                  <>
                    <CustomInput style={{ width: '195px', marginLeft: '15px', height: '51px' }}>
                      <div style={{ width: '100%', paddingTop: 3 }}>
                        <Label>{t('capacidadeCorrente')}</Label>
                        <SelectSearch
                          options={currentCapacityOpts}
                          value={state.selectedCurrentCapacity?.value.toString() || ''}
                          printOptions="on-focus"
                          search
                          filterOptions={fuzzySearch}
                          placeholder={t('selecionarCapacidade')}
                          onChange={(value) => { setState({ selectedCurrentCapacity: { name: `${value}A`, value: value.toString() } }); render(); }}
                          closeOnSelect={false}
                        />
                      </div>
                    </CustomInput>

                    <Select
                      style={{ width: '220px', marginLeft: '10px', marginRight: 'auto' }}
                      options={installationTypeOpts[state.driMeterModel.NAME]}
                      value={state.selectedInstallationType}
                      label={t('tipoInstalacaoEletrica')}
                      placeholder={t('selecionarTipo')}
                      propLabel="name"
                      disabled={['MULT-K'].includes(state.driMeterModel.NAME)}
                      onSelect={(value) => setState({ selectedInstallationType: value })}
                    />
                  </>
                )}
              </div>

              <div style={{
                width: '300px',
                display: 'flex',
                alignItems: 'center',
                marginTop: '30px',
              }}
              >
                <Button style={{ width: '100px' }} onClick={() => uploadSelectedDriConfigFile()} variant="primary">
                  {t('botaoSalvar')}
                </Button>
                <BtnClean onClick={() => history.push(history.location.pathname.replace('/editar', history.location.pathname.includes('ativos') ? '/informacoes' : '/perfil'))}>{t('botaoCancelar')}</BtnClean>
              </div>
            </div>

            {state.driApplication === 'Modbus Genérico' && (
              <div>
                <br />
                <br />
                <CardsConfig
                  integrType={integrType}
                  varsList={props.varsList}
                  cards={props.cards}
                  subcards={props.subcards}
                  relevances={props.relevances}
                />
                <br />
                <br />
                <div>
                  <Button
                    style={{ maxWidth: '100px' }}
                    onClick={() => save()}
                    variant="primary"
                  >
                    {t('botaoSalvar')}
                  </Button>
                  <Button
                    style={{ maxWidth: '120px', marginLeft: '22px' }}
                    onClick={() => history.push(history.location.pathname.replace('/editar', '/perfil'))}
                    variant="secondary"
                  >
                    {t('botaoCancelar')}
                  </Button>
                </div>
              </div>
            )}
          </div>
        )}

        {allTabs[1].isActive && (
          <div style={{ width: '60%' }}>
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              <Select
                options={state.comboOpts.duts}
                value={state.formData.REL_DUT_ID_item}
                label={t('ambienteMonitorado')}
                placeholder={t('selecionar')}
                onSelect={(item) => { state.formData.REL_DUT_ID_item = (item !== '') ? item : null; render(); }}
                disabled={!state.formData.UNIT_ID_item}
                propLabel="comboLabel"
              />

              <div style={{ display: 'flex', justifyContent: 'space-between', margin: '40px 0px 10px 0px' }}>
                <Select
                  style={{ width: '200px', height: 'fit-content' }}
                  options={state.comboOpts.enableEco}
                  value={state.formData.ENABLE_ECO_item}
                  label={t('modoEco')}
                  placeholder={t('selecionar')}
                  onSelect={(item) => { state.formData.ENABLE_ECO_item = (item !== '') ? item : null; render(); }}
                  disabled={!state.formData.REL_DUT_ID_item}
                  propLabel="name"
                />
                <Select
                  style={{ width: '200px', height: 'fit-content' }}
                  options={state.comboOpts.ecoModeCfg}
                  value={state.formData.ECO_MODE_CFG_item}
                  label={t('configModoEco')}
                  placeholder={t('selecionar')}
                  onSelect={(item) => { state.formData.ECO_MODE_CFG_item = (item !== '') ? item : null; render(); }}
                  disabled={!state.formData.REL_DUT_ID_item}
                  propLabel="name"
                />
                <div>
                  <Input
                    label={t('intervaloParaComando')}
                    value={state.formData.ECO_INTERVAL_TIME}
                    style={{ width: '250px' }}
                    onChange={(e) => {
                      if (
                        (parseInt(e.target.value, 10) >= 1 && parseInt(e.target.value, 10) <= 15)
                        || (e.target.value === '')
                      ) {
                        state.formData.ECO_INTERVAL_TIME = e.target.value;
                        render();
                      }
                    }}
                    disabled={!state.formData.REL_DUT_ID_item}
                  />
                  <p style={{ color: 'darkgray' }}>{t('limites1-15min')}</p>
                </div>
              </div>

              <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <div style={{ width: '45%' }}>
                  <Input
                    label={t('offsetEntradaC')}
                    value={state.formData.ECO_OFST_START}
                    style={{ width: 'inherit' }}
                    onChange={(e) => { state.formData.ECO_OFST_START = e.target.value; render(); }}
                    disabled={!state.formData.REL_DUT_ID_item}
                  />
                  <p style={{ color: 'darkgray' }}>{t('seTemperaturaMinimaAmbienteOffset', { value1: (returnExistOrValue1(state.formData.ECO_OFST_START, 1)), value2: (20 + parseFloat(returnExistOrValue1(state.formData.ECO_OFST_START, '1'))) })}</p>
                </div>

                <div style={{ width: '45%' }}>
                  <Input
                    label={t('offsetSaidaC')}
                    value={state.formData.ECO_OFST_END}
                    style={{ width: 'inherit' }}
                    onChange={(e) => { state.formData.ECO_OFST_END = e.target.value; render(); }}
                    disabled={!state.formData.REL_DUT_ID_item}
                  />
                  <p style={{ color: 'darkgray' }}>{t('seTemperaturaAtivacaoModoEcoOffset', { value1: (returnExistOrValue1(state.formData.ECO_OFST_END, 1)), value2: (7 + (parseFloat(returnExistOrValue1(state.formData.ECO_OFST_END, 1)))) })}</p>
                </div>
              </div>

              <div style={{
                width: '300px',
                display: 'flex',
                alignItems: 'center',
                marginTop: '30px',
              }}
              >
                <Button style={{ width: '100px' }} onClick={() => saveAutomationInfo()} variant="primary">
                  {t('botaoSalvar')}
                </Button>
                <BtnClean onClick={() => history.push(history.location.pathname.replace('/editar', '/perfil'))}>{t('botaoCancelar')}</BtnClean>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export const EditDevInfoDRI = (props: {
  formData: { varsConfigInput: string }
  onDataUpdate: (data: { varsConfigInput: string }) => void
}): JSX.Element => {
  const { t } = useTranslation();
  const { formData, onDataUpdate } = props;
  return (
    <div>
      <textarea
        style={{ width: '100%', height: '300px' }}
        placeholder={t('variaveis')}
        onChange={(event) => { onDataUpdate({ varsConfigInput: event.target.value }); }}
      >
        {formData.varsConfigInput}

      </textarea>
      <div>
        {t('umItemPorLinha')}
        <br />
        {'{"name":"Psuc máquina 1","address":{"protocol":"read-modbus-tcp","machine":0,"ip":"192.168.137.1","id":1,"function":4,"address":21}}'}
      </div>
    </div>
  );
};
