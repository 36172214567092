import { Button } from '../Button';
import { Card } from '../Card';
import {
  CardContent,
  CardHeader,
  CardHeaderContent,
  FilterHeader,
  FilterHeaderButton,
  LoaderOverlay,
} from './styles';
import { useEffect, useState } from 'react';
import * as echarts from 'echarts/core';
import { TooltipComponent, GridComponent } from 'echarts/components';
import { BarChart } from 'echarts/charts';
import { CanvasRenderer } from 'echarts/renderers';
import { Box } from 'reflexbox';
import { t } from 'i18next';
import { ICard, useCard } from '~/contexts/CardContext';
import { CardManager } from '../CardManager';
import { SelectOptionDropdown } from '../SelectOptionDropdown';
import { EnergyCardFooter } from './EnergyCardFooter';
import { EnergyHistory } from './EnergyHistory';
import { ApiResps, apiCall } from '~/providers';
import moment from 'moment';
import i18n from '~/i18n';
import { Loader } from '../Loader';
import { toast } from 'react-toastify';
import { ArrowLeftIcon, ArrowRightIcon } from '~/icons';
import { capitalizeFirstLetter } from '~/helpers/capitalizeFirstLetter';
import { useStateVar } from '~/helpers/useStateVar';
import { handleGetDatesParams } from '~/helpers/getRangeParamsChart';
import { TabOptionCard } from '../ItemCardTabOption/TabOption';

echarts.use([TooltipComponent, GridComponent, BarChart, CanvasRenderer]);

export interface EnergyCardProps {
  energyCardFilters: {
    startObject?: boolean;
    unitIds?: number[];
    stateIds?: number[];
    cityIds?: string[];
  };
}

interface FilterDatesProps {
  yearOptions: {
    label: string;
    value: string | number;
  }[];
  monthOptions: {
    label: string;
    value: string | number;
  }[];
}

export const EnergyCard: React.FC<EnergyCardProps> = ({
  energyCardFilters,
}) => {
  moment.locale(i18n.language === 'pt' ? 'pt-BR' : 'en');
  const [state, render] = useStateVar({
    isLoadingFilters: true,
    isLoadingChart: true,
    chartMode: 'yearMode' as 'yearMode' | 'monthMode',
    isOpenFilterHeader: false,
    monthSelected: moment().format('MMMM'),
    yearSelected: moment().format('YYYY'),
    isComparingChart: false,
    monthChartSelected: moment().format('MMMM'),
    yearChartSelected: moment().format('YYYY'),
  });

  const [historyChartData, setHistoryChartData] = useState<
    ApiResps['/energy/get-energy-analysis-hist']
  >({} as ApiResps['/energy/get-energy-analysis-hist']);

  const [filterDatesCard, setFilterDatesCard] = useState<FilterDatesProps>(
    {
      yearOptions: [],
      monthOptions: [],
    } as FilterDatesProps,
  );

  const { cards } = useCard();

  const energyCard = cards.find((card) => card.title === 'Energia');

  const [cardContentControl, setCardContentControl] = useState('history');

  const cardContentFactory = {
    history: (
      <EnergyHistory
        chartData={historyChartData}
        chartMode={state.chartMode}
        cardYear={state.yearSelected}
        cardMonth={state.monthSelected}
        filterDatesChart={filterDatesCard}
        isLoading={state.isLoadingFilters || state.isLoadingChart}
        handleClickBarChart={({ name }) => {
          if (state.chartMode === 'monthMode') return;

          const barClicked = moment(name, 'MMM YYYY');

          state.yearSelected = barClicked.format('YYYY');
          state.monthSelected = barClicked.format('MMMM');
          state.monthChartSelected = state.monthSelected;
          state.chartMode = 'monthMode';

          const datesParams = handleGetDatesParams(`${name} ${state.yearSelected}`, 'MMM YYYY', 'monthMode', state.isComparingChart);

          handleGetChartData({
            ...datesParams,
            filterType: 'month',
          });

          render();
        }}
        handleChangeComparingChart={(isComparing, yearSelectedComparing, monthSelectedComparing) => {
          state.monthChartSelected = monthSelectedComparing;
          state.yearChartSelected = yearSelectedComparing;
          state.isComparingChart = isComparing;

          const datesParams = handleGetDatesParams(`${state.monthSelected} ${state.yearSelected}`, 'MMMM YYYY', state.chartMode, state.isComparingChart);
          const {
            startDate: startDateToCompare,
            endDate: endDateToCompare,
          } = handleGetDatesParams(`${monthSelectedComparing} ${yearSelectedComparing}`, 'MMMM YYYY', state.chartMode, state.isComparingChart, state.yearSelected);

          handleGetChartData({
            ...datesParams,
            ...(isComparing && { startDateToCompare, endDateToCompare }),
          });

          render();
        }}
      />
    ),
  };

  const formatDate = (date, format) => moment.utc(date).format(format);

  const handleGetYearIndex = (yearLabel) => filterDatesCard.yearOptions.findIndex((year) => yearLabel === formatDate(year.value, 'YYYY'));

  const handleGetMonthIndex = (monthLabel, yearLabel, format = 'MMM YYYY') => filterDatesCard.monthOptions.findIndex((month) => capitalizeFirstLetter(`${monthLabel} ${yearLabel}`) === capitalizeFirstLetter(formatDate(month.value, format)));

  const handleCheckCanClick = (direction) => {
    try {
      const { chartMode, monthSelected, yearSelected } = state;
      const arrayLabel = chartMode === 'yearMode' ? 'yearOptions' : 'monthOptions';
      const currentDate = moment(`${monthSelected} ${yearSelected}`, 'MMMM YYYY');

      const [firstDate, endDate] = [filterDatesCard[arrayLabel][0], filterDatesCard[arrayLabel].slice(-1)[0]];

      if (chartMode === 'yearMode') {
        const compareYear = direction > 0 ? endDate.label : firstDate.label;
        return currentDate.format('YYYY') === compareYear;
      }

      const compareDate = direction > 0 ? formatDate(endDate.value, 'MMMM YYYY') : formatDate(firstDate.value, 'MMMM YYYY');
      return currentDate.format('MMMM YYYY') === compareDate;
    } catch (error) {
      return true;
    }
  };

  const handleDateChange = (direction) => {
    if (handleCheckCanClick(direction)) return;

    const { chartMode, monthSelected, yearSelected } = state;
    const dateIndex = chartMode === 'yearMode'
      ? handleGetYearIndex(yearSelected)
      : handleGetMonthIndex(monthSelected, yearSelected, 'MMMM YYYY');

    const newDate = moment.utc(
      filterDatesCard[chartMode === 'yearMode' ? 'yearOptions' : 'monthOptions'][dateIndex + direction].value,
    );

    state.yearSelected = newDate.format('YYYY');
    state.monthSelected = newDate.format('MMMM');

    const datesParams = handleGetDatesParams(`${state.yearSelected}-${state.monthSelected}`, 'YYYY-MMMM', state.chartMode, state.isComparingChart);

    handleGetChartData({ ...datesParams });

    render();
  };

  const handleGetChartData = async (energyHistParams) => {
    try {
      state.isLoadingChart = true;
      render();

      const chartModeParam = state.chartMode === 'monthMode' ? 'month' : 'year';

      const datesParams = handleGetDatesParams(`${state.monthSelected} ${state.yearSelected}`, 'MMMM YYYY', state.chartMode, state.isComparingChart);

      const {
        startDate: startDateToCompare,
        endDate: endDateToCompare,
      } = handleGetDatesParams(`${state.monthChartSelected} ${state.yearChartSelected}`, 'MMMM YYYY', state.chartMode, state.isComparingChart, state.yearSelected);

      const data = await apiCall('/energy/get-energy-analysis-hist', {
        ...datesParams,
        ...(state.isComparingChart && { startDateToCompare, endDateToCompare }),
        filterType: chartModeParam,
        ...energyHistParams,
        ...energyCardFilters,
      });
      setHistoryChartData(data);
    } catch (e) {
      toast.error(t('naoFoiPossivelBuscarInformacoesGrafico'));
    } finally {
      state.isLoadingChart = false;
      render();
    }
  };

  const handleGetDatesFilter = async () => {
    try {
      state.isLoadingFilters = true;
      render();

      const { years, months } = await apiCall('/energy/get-energy-analysis-hist-filter', energyCardFilters);

      const yearOptions = years.map((year) => ({
        label: moment.utc(year.time).format('YYYY'),
        value: year.time,
      }));

      const monthOptions = months.map((month) => ({
        label: moment.utc(month.time).format('MMMM'),
        value: month.time,
      }));

      setFilterDatesCard({ yearOptions, monthOptions });
    } catch (e) {
      toast.error(t('naoFoiPossivelBuscarInformacoesFiltros'));
    } finally {
      state.isLoadingFilters = false;
      render();
    }
  };

  useEffect(() => {
    if (energyCardFilters.startObject) return;

    handleGetDatesFilter();

    handleGetChartData({
      startDate: moment().subtract(1, 'years').startOf('month').utcOffset(0, true),
      endDate: moment().endOf('month').utcOffset(0, true),
    });
  }, [energyCardFilters]);

  return (
    <Box
      width={energyCard?.isExpanded ? 1 : [1, 1, 1, 1, 25 / 51, 25 / 51]}
      mb={40}
      ml={0}
      mr={0}
      style={
        energyCard?.isExpanded
          ? { maxWidth: 'none', height: 'auto' }
          : { maxWidth: 'none', minHeight: 550 }
      }
    >
      <Card
        noPadding
        wrapperStyle={{
          minHeight: energyCard?.isExpanded ? 'auto' : 550,
        }}
      >
        <CardHeader>
          <CardHeaderContent>
            <h2>{t('energia')}</h2>
            {state.chartMode === 'monthMode' && (
              <Button
                variant="borderblue"
                style={{
                  width: 'fit-content',
                  padding: '2px 15px',
                  fontSize: '12px',
                }}
                onClick={() => {
                  state.chartMode = 'yearMode';

                  const datesParams = handleGetDatesParams(`${state.monthSelected} ${state.yearSelected}`, 'MMMM YYYY', state.chartMode, state.isComparingChart);

                  const comparingParams = `${state.monthSelected} ${state.yearChartSelected}`;

                  const {
                    startDate: startDateToCompare,
                    endDate: endDateToCompare,
                  } = handleGetDatesParams(comparingParams, 'MMMM YYYY', state.chartMode, state.isComparingChart, state.yearSelected);

                  handleGetChartData({
                    ...datesParams,
                    ...(state.isComparingChart && { startDateToCompare, endDateToCompare }),
                    filterType: 'year',
                  });
                  render();
                }}
              >
                {t('voltar')}
              </Button>
            )}
          </CardHeaderContent>
          <CardHeaderContent>
            <SelectOptionDropdown
              mode={state.chartMode}
              open={state.isOpenFilterHeader}
              optionSelected={state.yearSelected}
              isLoading={state.isLoadingFilters || state.isLoadingChart}
              handleClickOutside={() => {
                state.isOpenFilterHeader = false;
                render();
              }}
              yearOptions={filterDatesCard.yearOptions}
              monthOptions={filterDatesCard.monthOptions}
              yearSelected={state.yearSelected}
              monthSelected={state.monthSelected}
              handleSelectMonth={(value) => {
                state.monthSelected = value;

                const datesParams = handleGetDatesParams(`${state.yearSelected}-${value}`, 'YYYY-MMMM', state.chartMode, state.isComparingChart);

                handleGetChartData({ ...datesParams });

                render();
              }}
              handleSelectYear={(value) => {
                state.yearSelected = value;

                const datesParams = handleGetDatesParams(`${value}-${state.monthSelected}`, 'YYYY-MMMM', state.chartMode, state.isComparingChart);

                handleGetChartData({ ...datesParams });

                render();
              }}
              handleChangeDate={(value) => {
                const newDate = moment(value, 'MMM YYYY');

                state.yearSelected = newDate.format('YYYY');
                state.monthSelected = newDate.format('MMMM');

                const datesParams = handleGetDatesParams(value, 'MMM YYYY', state.chartMode, state.isComparingChart);

                handleGetChartData({ ...datesParams });

                render();
              }}
            >
              <FilterHeader>
                <FilterHeaderButton
                  disabled={handleCheckCanClick(-1)}
                  onClick={() => !(state.isLoadingFilters || state.isLoadingChart) && handleDateChange(-1)}
                >
                  <ArrowLeftIcon />
                </FilterHeaderButton>
                <span
                  onClick={() => {
                    if (state.isLoadingFilters || state.isLoadingChart) return;
                    state.isOpenFilterHeader = !state.isOpenFilterHeader;
                    render();
                  }}
                >
                  {state.chartMode === 'monthMode' && capitalizeFirstLetter(state.monthSelected)}
                  {' '}
                  {state.yearSelected}
                </span>
                <FilterHeaderButton
                  disabled={handleCheckCanClick(1)}
                  onClick={() => !(state.isLoadingFilters || state.isLoadingChart) && handleDateChange(1)}
                >
                  <ArrowRightIcon />
                </FilterHeaderButton>
              </FilterHeader>
            </SelectOptionDropdown>
            <CardManager card={energyCard as ICard} />
          </CardHeaderContent>
        </CardHeader>
        <TabOptionCard
          arrayItems={[{
            selected: !(cardContentControl === 'history'),
            name: t('historico'),
            onClickFunc: () => setCardContentControl('history'),
          }]}
        />
        <CardContent>
          {cardContentFactory[cardContentControl]}
          {(state.isLoadingFilters || state.isLoadingChart) && (
            <LoaderOverlay>
              <Loader variant="primary" size="large" />
            </LoaderOverlay>
          )}
          <EnergyCardFooter
            energyCardFilters={energyCardFilters}
            chartMode={state.chartMode}
            cardYear={state.yearSelected}
            cardMonth={state.monthSelected}
            isComparing={state.isComparingChart}
          />
        </CardContent>
      </Card>
    </Box>
  );
};
