type DocumentIconProps = {
  color?: string;
}

export const DocumentIcon = ({ color = '#000', ...props }: DocumentIconProps): JSX.Element => (
  <svg
    width="19"
    height="19"
    viewBox="0 0 19 19"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M8.55556 1.94444H5.53333C3.94652 1.94444 3.15311 1.94444 2.54703 2.25326C2.0139 2.5249 1.58046 2.95835 1.30881 3.49147C1 4.09755 1 4.89096 1 6.47778V13.4667C1 15.0535 1 15.8469 1.30881 16.453C1.58046 16.9861 2.0139 17.4195 2.54703 17.6912C3.15311 18 3.94652 18 5.53333 18H12.5222C14.109 18 14.9024 18 15.5085 17.6912C16.0417 17.4195 16.4751 16.9861 16.7467 16.453C17.0556 15.8469 17.0556 15.0535 17.0556 13.4667V10.4444M10.4444 14.2222H4.77778M12.3333 10.4444H4.77778M17.1701 1.82986C18.2766 2.93635 18.2766 4.73032 17.1701 5.8368C16.0637 6.94329 14.2697 6.94329 13.1632 5.8368C12.0567 4.73032 12.0567 2.93635 13.1632 1.82986C14.2697 0.723379 16.0637 0.723379 17.1701 1.82986Z"
      stroke={color}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
