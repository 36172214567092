import { useState } from 'react';
import ReactTooltip from 'react-tooltip';
import { formatHealthIcon } from '~/components/HealthIcon';
import { getUserProfile } from '~/helpers/userProfile';
import { t } from 'i18next';
import { Trans } from 'react-i18next';
import {
  DacItem,
  DatItem,
  DutItem,
  formatRssiIcon,
  rssiDesc,
} from '../../../../..';
import { TooltipContainer } from '../../../../styles';
import {
  DataContainer,
  Title,
  Subtitle,
  HealthContainer,
  DuoTempContainer,
  UsageContainer,
  StatusContainer,
  Icon,
  StatusBox,
  TransparentLink,
  IconWiFiRealTime,
} from './styles';

type Props = {
  dac?: DacItem;
  dut?: DutItem;
  dat?: DatItem;
  expanded?: boolean;
};

export const MachineInformation = ({
  dac,
  dut,
  dat,
  expanded = false,
}: Props): React.ReactElement => {
  const [profile] = useState(getUserProfile);

  function isDacOnline() {
    if (dac) {
      return typeof dac.Lcmp === 'number' && dac.status === 'ONLINE';
    }

    return false;
  }

  return (
    <>
      {dac && (
        <DataContainer expanded={expanded}>
          <div>
            <TransparentLink to={`/analise/maquina/${dac.GROUP_ID}/ativos/${dac.DAC_ID}/informacoes`}>
              {dac.DAC_NAME != null && (
                <Title data-tip data-for={`tip-${dac.DAC_ID}`}>
                  {dac.DAC_NAME.length < 20
                    ? dac.DAC_NAME
                    : `${dac.DAC_NAME.substring(0, 20)}...`}
                </Title>
              )}
            </TransparentLink>
            <TransparentLink to={`/analise/dispositivo/${dac.DAC_ID}/informacoes`}>
              {(profile.manageAllClients || profile.permissions.isInstaller) && (
                <>
                  <Subtitle>{dac.DAC_ID}</Subtitle>
                </>
              )}
            </TransparentLink>
            {dat && (
              <TransparentLink to={`/analise/maquina/${dat.GROUP_ID}/ativos/${dat.DEV_ID || dat.DAT_ID}/informacoes`}>
                {(profile.manageAllClients || profile.permissions.isInstaller) && (
                  <>
                    <Subtitle>{dat.DAT_ID}</Subtitle>
                  </>
                )}
              </TransparentLink>
            )}
          </div>

          <HealthContainer expanded={expanded}>
            <div>
              <Icon health={dac.H_INDEX}>{formatHealthIcon(dac.H_INDEX)}</Icon>
            </div>
            {dac.insufDut && dac.insufDut.Temperature && (
              <div>
                <strong style={{ marginBottom: '10px' }}>{t('insuflamento')}</strong>
                <span>{`${dac.insufDut.Temperature}°C`}</span>
              </div>
            )}
          </HealthContainer>
          <UsageContainer>
            <strong>{`${t('usoMedio')}: `}</strong>
            <span>
              {dac.MEAN_USE || '00:00'}
              h/dia
            </span>
          </UsageContainer>
          <StatusContainer>
            {isDacOnline() && (
              <StatusBox isPrimary={false} status={dac.Lcmp}>
                {[t('desligadoMinusculo'), t('ligadoMinusculo')][dac.Lcmp]}
              </StatusBox>
            )}
            <IconWiFiRealTime>
              {formatRssiIcon(rssiDesc(dac.RSSI, dac.status))}
            </IconWiFiRealTime>
          </StatusContainer>
        </DataContainer>
      )}
      {dut && (
        <DataContainer expanded={expanded}>
          <div>
            <TransparentLink to={`/analise/dispositivo/${dat ? dat.DAT_ID : dut.DEV_ID}/informacoes`}>
              {dat?.AST_DESC != null ? (
                <Title data-tip data-for={dat?.AST_DESC}>
                  {dat?.AST_DESC.length < 20
                    ? dat?.AST_DESC
                    : `${dat?.AST_DESC.substring(0, 20)}...`}
                </Title>
              ) : (
                <Title>
                  {dut.DEV_ID}
                </Title>
              )}
            </TransparentLink>
            <TransparentLink to={`/analise/dispositivo/${dut.DEV_ID}/informacoes`}>
              {(profile.manageAllClients || profile.permissions.isInstaller) && (
                <>
                  <Subtitle>{dut.DEV_ID}</Subtitle>
                </>
              )}
            </TransparentLink>
          </div>
          <DuoTempContainer expanded={expanded}>
            <div>
              <strong>{t('tempRetorno')}</strong>
              <span>
                {dut.Temperature}
                <span style={{ fontWeight: 'normal' }}>°C</span>
              </span>
            </div>
            <div>
              <strong>{t('tempInsuflamento')}</strong>
              <span>
                {dut.Temperature_1}
                <span style={{ fontWeight: 'normal' }}>°C</span>
              </span>
            </div>
          </DuoTempContainer>
          {/* <HealthContainer expanded={expanded}>
            <div>
              <strong style={{ marginBottom: '10px' }}>Temp. de Retorno</strong>
              <Icon health={0}>{formatHealthIcon(0)}</Icon>
            </div>
          </HealthContainer> */}
          {/* <div style={{ paddingTop: '22px' }} /> */}
          <StatusContainer>
            <IconWiFiRealTime>
              {formatRssiIcon(rssiDesc(dut.RSSI, dut.status))}
            </IconWiFiRealTime>
          </StatusContainer>
        </DataContainer>
      )}
      {(dat && !dac && !dut) && (
        <DataContainer
          expanded={expanded}
          style={{
            display: 'flex',
            flexDirection: 'column',
            flex: 1,
            justifyContent: 'space-between',
          }}
        >
          <div>
            <TransparentLink to={`/analise/dispositivo/${dat.DAT_ID}/informacoes`}>
              {dat.AST_DESC != null && (
                <Title data-tip data-for={dat.AST_DESC}>
                  {dat.AST_DESC && dat.AST_DESC.length > 25
                    ? `${dat.AST_DESC.substring(0, 25)}...`
                    : dat.AST_DESC}
                </Title>
              )}
              {(profile.manageAllClients || profile.permissions.isInstaller) && (
                <>
                  <Subtitle />
                  <Subtitle>{dat.DAT_ID}</Subtitle>
                </>
              )}
            </TransparentLink>
          </div>
          <div style={{
            opacity: 0.4,
            fontSize: '10px',
          }}
          >
            <Trans i18nKey="ativoNaoMonitorado">
              Este
              {' '}
              <strong>Ativo</strong>
              {' '}
              não é monitorado remotamente pela Diel Energia.
            </Trans>
          </div>
        </DataContainer>
      )}
      <ReactTooltip
        id={dac ? `tip-${dac?.DAC_ID}` : dat && dat?.AST_DESC}
        place="top"
        border
        textColor="#000000"
        backgroundColor="rgba(255, 255, 255, 0.97)"
        borderColor="#202370"
      >
        <TooltipContainer>
          <strong>{dac ? dac?.DAC_NAME : dat && dat?.AST_DESC}</strong>
        </TooltipContainer>
      </ReactTooltip>
    </>
  );
};
